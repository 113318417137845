<div id="title" class="col-12 my-2">
  <div class="d-none d-md-flex">
    <ng-container *ngFor="let href of hrefList">
      <ng-container *ngIf="href.url !== undefined; else lastItem">
        <h1 style="cursor: pointer; color: #d62027" [routerLink]="href.url">
          {{ href.title | translate }}
        </h1>
        <div class="d-flex justify-content-center align-items-center">
          <mat-icon class="navigate-next-icon mx-2">arrow_forward</mat-icon>
        </div>
      </ng-container>
      <ng-template #lastItem>
        <h1>{{ href.title | translate }}</h1>
      </ng-template>
    </ng-container>
  </div>
  <span class="spacer"></span>
  <ng-content></ng-content>
</div>
