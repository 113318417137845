<div class="d-flex justify-content-between">
  <h1 mat-dialog-title>Jumper</h1>
  <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
</div>
<div mat-dialog-content>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.LINE1' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.line1Name }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.LINE1_NAME' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.line1 }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.LINE2' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.line2Name }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.LINE2_NAME' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.line2 }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.POSITION1' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.position1 | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.POSITION2' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.position2 | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MAX_VOLTAGE' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.maxVoltage!! | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MIN_VOLTAGE' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.minVoltage!! | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MAX_CURRENT' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.maxCurrent!! | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MAX_1_MIN_CURRENT' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.max1MinRMSCurrent!! | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MAX_1_HR_RMS_CURRENT' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.max1HrRMSCurrent!! | trunc }}
    </mat-card>
  </div>
  <div class="d-flex flex-row row">
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold">{{ 'JUMPERS_PAGE.MAX_RMS_CURRENT' | translate }}</strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ jumperElement.maxRMSCurrent!! | trunc }}
    </mat-card>
  </div>
</div>
