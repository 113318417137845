<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ feederSummaryData.scenerio }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.PF_MAX_RMS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.pfMaxRMSCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.PF_MAX_RMS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.pfMaxRMSCurrent?.name!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.PF_MAX_REVERSE_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.pfMaxReverseCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.PF_MAX_REVERSE_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.pfMaxReverseCurrent?.name!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.NF_MAX_RMS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.nfMaxRMSCurrent?.current!! | trunc }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.NF_MAX_RMS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.nfMaxRMSCurrent?.name!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.NF_MAX_REVERSE_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.nfMaxReverseCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.NF_MAX_REVERSE_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.nfMaxReverseCurrent?.name!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">{{ feederSummaryData?.maxBusCurrent?.current!! | trunc }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">{{ feederSummaryData?.maxBusCurrent?.name!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_RMS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.maxBusRMSCurrent?.current!! | trunc }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_RMS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.maxBusRMSCurrent?.name!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ feederSummaryData?.maxNegativeBusCurrent?.current!! | trunc }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxNegativeBusCurrent?.name!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_RMS_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxNegativeBusRMSCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_RMS_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxNegativeBusRMSCurrent?.name!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_REVERSE_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxBusReverseCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_BUS_REVERSE_CURRENT_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxBusReverseCurrent?.name!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_REVERSE_CURRENT' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxNegativeBusReverseCurrent?.current!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'FEEDER_SUMMARY_PAGE.MAX_NEGATIVE_BUS_REVERSE_FEEDER' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ feederSummaryData?.maxNegativeBusReverseCurrent?.name!! }}
      </mat-card>
    </div>
  </div>
</div>
