import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { RoleService } from 'src/app/services/role.service';
import { AddRoleAction, AddRoleActionError, LoadRoleDatas, RoleActionTypes } from '../actions/role.action';

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private readonly _roleService: RoleService,
    private readonly _store: Store<AppState>
  ) {}

  loadRoles$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadRoleDatas>(RoleActionTypes.LOAD_ROLE),
      withLatestFrom(this._store.select(state => state.roleState.roles)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize))),
      switchMap(action => {
        return this._roleService.getRoles(true, action[0].pageIndex, action[0].pageSize).pipe(
          map(
            response =>
              new AddRoleAction(
                response.data!!,
                {
                  totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                } as HttpCommonHeaders,
                action[0].pageSize,
                action[0].pageIndex,
                null
              )
          ),
          catchError(errorMessage => of(new AddRoleActionError(null, errorMessage)))
        );
      })
    )
  );
}
