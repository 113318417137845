<div #item class="sub-tooltip-box" cdkDrag [style.visibility]="'hidden'">
  <div class="col-12 d-flex flex-row justify-content-end align-items-center p-0 m-0 border" style="cursor: grab" cdkDragHandle>
    <button (click)="makeSubTooltipInvisible()" matTooltip="Close" matTooltipPosition="after" class="p-0" mat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="subTooltipForm" class="m-2">
      <div>
        <button [class.selected]="focusedItem?.isBold" (click)="subTooltipMakeBold()" matTooltip="Bold text" matTooltipPosition="above" class="p-0 border mx-1" mat-button>
          <mat-icon>format_bold</mat-icon>
        </button>
        <button [class.selected]="focusedItem?.isItalic" (click)="subTooltipMakeItalic()" matTooltip="Italic text" matTooltipPosition="above" class="p-0 border mx-1" mat-button>
          <mat-icon>format_italic</mat-icon>
        </button>
        <mat-form-field>
          <input matTooltip="Color picker" matTooltipPosition="above" formControlName="color" type="color" matInput class="form-control form-control-color" formControlName="color" value="#000000" />
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" color="accent" class="col-12 p-0 m-0">
        <mat-label>Text</mat-label>
        <textarea matInput formControlName="text" rows="10"></textarea>
      </mat-form-field>
    </form>
    <mat-divider class="my-2"></mat-divider>
    <div class="d-flex justify-content-end align-items-center p-1">
      <button class="mr-1" mat-stroked-button color="accent" (click)="makeSubTooltipInvisible()">Cancel</button>
      <button class="ml-1" mat-stroked-button (click)="saveSubTooltip()">Ok</button>
    </div>
  </div>
</div>
