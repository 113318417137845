import { StatisticState } from 'src/app/models/state/appState';
import { StatisticAction, StatisticActionTypes } from '../actions/statistic.actions';

const initialStatisticState: StatisticState = {
  isStatisticsLoaded: false,
};

export function StatisticReducer(state: StatisticState = initialStatisticState, action: StatisticAction | any): any {
  switch (action.type) {
    case StatisticActionTypes.ADD_GENERAL_STATISTIC:
      return {
        ...state,
        statistics: action.payload,
        statisticError: null,
      };
    case StatisticActionTypes.ADD_GENERAL_STATISTIC_ERROR:
      return {
        ...state,
        statistics: null,
        statisticError: action.error,
      };
    case StatisticActionTypes.LOAD_GENERAL_STATISTIC:
      return {
        ...state,
        isStatisticsLoaded: true,
        statisticError: null,
      };
    default:
      return state;
  }
}
