import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertRatio',
})
export class ConvertRatioPipe implements PipeTransform {
  transform(value: number): string {
    return (value * 100).toFixed(2);
  }
}
