import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState } from 'src/app/models/state/appState';
import { LocationService } from 'src/app/services/location.service';
import { AddLocationAction, AddLocationActionError, LocationActionTypes } from '../actions/location.action';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private readonly _locationService: LocationService,
    private readonly _store: Store<AppState>
  ) {}

  loadLocations$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(LocationActionTypes.LOAD_LOCATION),
      withLatestFrom(this._store.select(state => state.locationState.locations)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._locationService.getLocations().pipe(
          map(response => new AddLocationAction(response.data!!, null)),
          catchError(errorMessage => of(new AddLocationActionError(null, errorMessage)))
        );
      })
    )
  );
}
