<div class="column-settings-menu">
  <mat-form-field (click)="$event.stopPropagation()" appearance="fill" color="accent">
    <mat-label>{{ 'FILTER' | translate }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup)="filterColumn($event)" [placeholder]="'FILTER' | translate" />
  </mat-form-field>
  <ng-container *ngFor="let column of columnShowHideList">
    <mat-slide-toggle class="column-settings-menu-item" *ngIf="!column.filter" [(ngModel)]="column.isActive" (change)="toggleColumn(column)" (click)="$event.stopPropagation()">
      {{ column.name | titlecase }}</mat-slide-toggle
    >
  </ng-container>
</div>
<div class="px-2">
  <button color="accent" class="w-100" (click)="saveColumns(); $event.stopPropagation()" mat-flat-button>Save</button>
</div>
