import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SimulationReducer } from './store/reducers/simulation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SimulationEffects } from './store/effects/simulation.effect';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StatisticReducer } from './store/reducers/statistic.reducer';
import { StatisticEffects } from './store/effects/statistic.effect';
import { LocationEffects } from './store/effects/location.effect';
import { LocationReducer } from './store/reducers/location.reducer';
import { CompanyReducer } from './store/reducers/company.reducer';
import { CompanyEffects } from './store/effects/company.effect';
import { RailEffects } from './store/effects/rail.effect';
import { CableEffects as CableEffects } from './store/effects/cable.effect';
import { VehicleEffects } from './store/effects/vehicle.effect';
import { RailReducer } from './store/reducers/rail.reducer';
import { CableReducer as CableReducer } from './store/reducers/cable.reducer';
import { VehicleReducer } from './store/reducers/vehicle.reducer';
import { UserEffects } from './store/effects/user.effect';
import { UserReducer } from './store/reducers/user.reducer';
import { RoleEffects } from './store/effects/role.effect';
import { RoleReducer } from './store/reducers/role.reducer';
import { SimulationReportReducer } from './store/reducers/report.reducer';
import { SimulationReportEffects } from './store/effects/report.effect';
import { DataLogReducer } from './store/reducers/data-log.reducer';
import { DataLogEffects } from './store/effects/data-log.effect';
import { SimulationResultReducer } from './store/reducers/simulation-result.reducer';
import { SimulationResultEffects } from './store/effects/simulation-result.effect';
import { SimulationReportTemplateEffects } from './store/effects/report-template.effect';
import { SimulationReportTemplateReducer } from './store/reducers/report-template.reducer';
import { environment } from 'src/environments/environment';
import { ConfigService } from './services/config.service';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      simulationState: SimulationReducer,
      simulationResultState: SimulationResultReducer,
      statisticState: StatisticReducer,
      locationState: LocationReducer,
      companyState: CompanyReducer,
      railState: RailReducer,
      cableState: CableReducer,
      vehicleState: VehicleReducer,
      userState: UserReducer,
      roleState: RoleReducer,
      simulationReportState: SimulationReportReducer,
      simulationReportTemplateState: SimulationReportTemplateReducer,
      dataLogState: DataLogReducer,
    }),
    EffectsModule.forRoot([
      SimulationEffects,
      SimulationResultEffects,
      StatisticEffects,
      LocationEffects,
      CompanyEffects,
      RailEffects,
      CableEffects,
      VehicleEffects,
      UserEffects,
      RoleEffects,
      SimulationReportEffects,
      SimulationReportTemplateEffects,
      DataLogEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
