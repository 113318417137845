import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetSimulationRequestDto } from '../dtos/simulation-request/getSimulationRequestDto';
import { AddSimulationRequestDto } from '../dtos/simulation-request/addSimulationRequestDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class SimulationRequestService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getSimulationRequests(pagination = false, pageIndex = 0, pageSize = 25) {
    return this._httpClient
      .get<ResponseModel<GetSimulationRequestDto[]>>(`${this._configService.getBaseUrl()}/simulation-requests`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationRequestDto[]>
        )
      );
  }

  addSimulationRequests(runSimulationDtos: AddSimulationRequestDto[]): Observable<ResponseModel<GetSimulationRequestDto>> {
    return this._httpClient.post<ResponseModel<GetSimulationRequestDto>>(`${this._configService.getBaseUrl()}/simulation-requests`, runSimulationDtos);
  }

  cancelBySimulationRequestId(simulationRequestId: number): Observable<ResponseModel<GetSimulationRequestDto>> {
    return this._httpClient.post<ResponseModel<GetSimulationRequestDto>>(`${this._configService.getBaseUrl()}/simulation-requests/${simulationRequestId}/cancel`, null);
  }

  cancelBySimulationRequestIds(simulationRequestIds: number[]): Observable<ResponseModel<GetSimulationRequestDto[]>> {
    return this._httpClient.post<ResponseModel<GetSimulationRequestDto[]>>(`${this._configService.getBaseUrl()}/simulation-requests/cancel`, {
      params: { 'simulation-request-id': simulationRequestIds },
    });
  }

  deleteSimulationRequest(simulationRequestId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/simulation-requests/${simulationRequestId}`);
  }

  deleteSimulationRequests(simulationRequestIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/simulation-requests`, { params: { 'simulation-request-id': simulationRequestIds } });
  }

  filterSimulationRequests(query?: string): Observable<GetSimulationRequestDto[]> {
    return this._httpClient.get<GetSimulationRequestDto[]>(`${this._configService.getBaseUrl()}/simulation-requests/filter?${query}`);
  }
}
