import { VehicleState } from 'src/app/models/state/appState';
import { VehicleAction, VehicleActionTypes } from '../actions/vehicle.action';

const initialVehicleState: VehicleState = {
  isVehiclesLoaded: false,
};

export function VehicleReducer(state: VehicleState = initialVehicleState, action: VehicleAction | any): any {
  switch (action.type) {
    case VehicleActionTypes.ADD_VEHICLE:
      return {
        ...state,
        vehicles: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        vehicleError: null,
      };
    case VehicleActionTypes.ADD_VEHICLE_ERROR:
      return {
        ...state,
        vehicles: null,
        vehicleError: action.error,
      };
    case VehicleActionTypes.LOAD_VEHICLE:
      return {
        ...state,
        isVehiclesLoaded: true,
        vehicleError: null,
      };
    case VehicleActionTypes.CLEAR_VEHICLE:
      return {
        ...initialVehicleState,
      };
    default:
      return state;
  }
}
