import { UnitType } from './types';

type UnitConstantType = {
  name: string;
  values: UnitType[];
};

export const Units: UnitConstantType[] = [
  {
    name: 'Acceleration',
    values: ['m/s^2', 'km/h^2', 'ft/s2', 'yd/s^2', 'mi/s^2'],
  },
  {
    name: 'Auxilary Current',
    values: ['mA', 'A', 'kA'],
  },
  {
    name: 'Braking Current Limit Mode',
    values: [],
  },
  {
    name: 'Braking Distance to SpeedLimit',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Braking Distance to SpeedLimit2',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Current',
    values: ['mA', 'A', 'kA'],
  },
  {
    name: 'Current Limit with Aux',
    values: ['mA', 'A', 'kA'],
  },
  {
    name: 'EB Coasting Distance',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'EB Coasting Velocity Increase',
    values: ['m/s', 'km/h', 'ft/s', 'yd/s', 'mph'],
  },
  {
    name: 'EB Speed',
    values: ['m/s', 'km/h', 'ft/s', 'yd/s', 'mph'],
  },
  {
    name: 'Electrical Energy',
    values: ['J', 'Wh', 'kWh', 'MJ', 'BTU', 'ft lbf'],
  },
  {
    name: 'Energy Burn Mode',
    values: ['J', 'Wh', 'kWh', 'MJ', 'BTU', 'ft lbf'],
  },
  {
    name: 'Energy Burnt',
    values: ['J', 'Wh', 'kWh', 'MJ', 'BTU', 'ft lbf'],
  },
  {
    name: 'Line Current',
    values: ['mA', 'A', 'kA'],
  },
  {
    name: 'Location',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Max. Brake Effort',
    values: ['N', 'kN', 'MN', 'lbf', 'kp'],
  },
  {
    name: 'Max. Electric Tractive Effort',
    values: ['N', 'kN', 'MN', 'lbf', 'kp'],
  },
  {
    name: 'Power Available for Regeneration',
    values: ['W', 'kW', 'MW', 'lbf/h', 'BTU/h', 'HP'],
  },
  {
    name: 'Power Unused for Regeneration',
    values: ['W', 'kW', 'MW', 'lbf/h', 'BTU/h', 'HP'],
  },
  {
    name: 'Rail Voltage',
    values: ['mV', 'V', 'kV'],
  },
  {
    name: 'Receptivity Rate',
    values: ['%', '%%'],
  },
  {
    name: 'Recuperation Ratio',
    values: ['%', '%%'],
  },
  {
    name: 'Regenerated Power Used by Auxilary',
    values: ['W', 'kW', 'MW', 'lbf/h', 'BTU/h', 'HP'],
  },
  {
    name: 'Regenerated Power Used by System',
    values: ['W', 'kW', 'MW', 'lbf/h', 'BTU/h', 'HP'],
  },
  {
    name: 'Regeneration Mode',
    values: ['%', '%%'],
  },
  {
    name: 'Runaway Distance',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Runaway Velocity Increase',
    values: ['m/s', 'km/h', 'ft/s', 'yd/s', 'mph'],
  },
  {
    name: 'Safe Braking Distance to SpeedLimit',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Safe Braking Distance to SpeedLimit2',
    values: ['mm', 'cm', 'm', 'km', 'inch', 'ft', 'yd', 'mile'],
  },
  {
    name: 'Speed Limits',
    values: ['m/s', 'km/h', 'ft/s', 'yd/s', 'mph'],
  },
  {
    name: 'Target Effort',
    values: ['N', 'kN', 'MN', 'lbf', 'kp'],
  },
  {
    name: 'Total Current (IT)',
    values: ['mA', 'A', 'kA'],
  },
  {
    name: 'Total Enegy Regenerated',
    values: ['J', 'Wh', 'kWh', 'MJ', 'BTU', 'ft lbf'],
  },
  {
    name: 'Train Power',
    values: ['W', 'kW', 'MW', 'lbf/h', 'BTU/h', 'HP'],
  },
  {
    name: 'Train Voltage',
    values: ['mV', 'V', 'kV'],
  },
  {
    name: 'Used Energy Regenerated',
    values: ['J', 'Wh', 'kWh', 'MJ', 'BTU', 'ft lbf'],
  },
  {
    name: 'Velocity',
    values: ['m/s', 'km/h', 'ft/s', 'yd/s', 'mph'],
  },
  {
    name: 'Time',
    values: ['sec', 'min', 'hour', 'HH:MM:SS', 'HH:MM'],
  },
];
