<div class="d-flex flex-column">
  <ng-container *ngFor="let menuItem of sideMenu">
    <button
      [class.selected]="menuItem.isSelected()"
      [matTooltip]="menuItem.tooltip"
      matTooltipPosition="after"
      (mouseover)="menuItem.mouseover()"
      (mouseout)="menuItem.mouseout()"
      class="my-1 p-0"
      mat-button
      (click)="menuItem.clickEvent()">
      <mat-icon [ngClass]="[menuItem.iconStyle ? menuItem.iconStyle : '']" class="ml-4 material-icons-outlined">{{ menuItem.icon }}</mat-icon>
      <mat-icon class="small-icon" matTooltipPosition="after" [style.visibility]="menuItem.smallIconVisibility()" [matMenuTriggerFor]="mouseMoveOptionsMenu">chevron_right</mat-icon>
      <mat-menu #mouseMoveOptionsMenu>
        <button *ngFor="let subMenuItem of menuItem.subMenu" [matTooltip]="subMenuItem.tooltip" matTooltipPosition="after" (click)="subMenuItem.clickEvent()" mat-menu-item>
          <mat-icon class="material-icons-outlined" [ngClass]="[subMenuItem.iconStyle ? subMenuItem.iconStyle : '']">{{ subMenuItem.icon }}</mat-icon>
          <span>{{ subMenuItem.title }}</span>
        </button>
      </mat-menu>
    </button>
    <mat-divider *ngIf="menuItem.icon === 'straighten' || menuItem.icon === 'zoom_out' || menuItem.icon === 'gestures'" class="mx-3"> </mat-divider>
  </ng-container>
</div>
