import { Action } from '@ngrx/store';
import { GetGeneralStatisticDto } from 'src/app/dtos/statistic/generalStatisticDto';

export enum StatisticActionTypes {
  ADD_GENERAL_STATISTIC = '[Statistic] Add General Statistic',
  LOAD_GENERAL_STATISTIC = '[Statistic] Load General Statistic',
  ADD_GENERAL_STATISTIC_ERROR = '[Statistic] Add General Statistic Error',
}

export class AddStatisticAction implements Action {
  readonly type = StatisticActionTypes.ADD_GENERAL_STATISTIC;

  constructor(
    public payload: GetGeneralStatisticDto,
    public error: null
  ) {}
}

export class AddStatisticActionError implements Action {
  readonly type = StatisticActionTypes.ADD_GENERAL_STATISTIC_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadStatisticAction implements Action {
  readonly type = StatisticActionTypes.LOAD_GENERAL_STATISTIC;

  constructor() {}
}

export type StatisticAction = AddStatisticAction | AddStatisticActionError | LoadStatisticAction;
