import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-player-info-snack-bar',
  templateUrl: './player-info-snack-bar.component.html',
  styleUrls: ['./player-info-snack-bar.component.css'],
})
export class PlayerInfoSnackBarComponent implements OnInit {
  station!: any;
  trackTransformer!: any;
  feederCable!: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<PlayerInfoSnackBarComponent>
  ) {
    this.station = data.station;
    this.trackTransformer = data.trackTransformer;
    this.feederCable = data.feederCable;
  }

  ngOnInit(): void {}

  closeSnackBar() {
    this.snackRef.dismiss();
  }
}
