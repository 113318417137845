import { UserState } from 'src/app/models/state/appState';
import { UserAction, UserActionTypes } from '../actions/user.action';

const initialUserState: UserState = {
  isUsersLoaded: false,
};

export function UserReducer(state: UserState = initialUserState, action: UserAction | any): any {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return {
        ...state,
        users: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        userError: null,
      };
    case UserActionTypes.ADD_USER_ERROR:
      return {
        ...state,
        users: null,
        userError: action.error,
      };
    case UserActionTypes.LOAD_USER:
      return {
        ...state,
        isUsersLoaded: true,
        userError: null,
      };
    case UserActionTypes.CLEAR_USER:
      return {
        ...initialUserState,
      };
    default:
      return state;
  }
}
