import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetRailDto } from '../dtos/rail/getRailDto';
import { AddRailDto } from '../dtos/rail/addRailDto';
import { UpdateRailDto } from '../dtos/rail/updateRailDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class RailService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getRail(id: number): Observable<ResponseModel<GetRailDto>> {
    return this._httpClient.get<ResponseModel<GetRailDto>>(`${this._configService.getBaseUrl()}/rails/${id}`);
  }

  getRails(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetRailDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetRailDto[]>>(`${this._configService.getBaseUrl()}/rails`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetRailDto[]>
        )
      );
  }

  addRail(cable: AddRailDto): Observable<ResponseModel<GetRailDto>> {
    return this._httpClient.post<ResponseModel<GetRailDto>>(`${this._configService.getBaseUrl()}/rails`, cable);
  }

  updateRail(id: number, cable: UpdateRailDto): Observable<ResponseModel<GetRailDto>> {
    return this._httpClient.put<ResponseModel<GetRailDto>>(`${this._configService.getBaseUrl()}/rails/${id}`, cable);
  }

  deleteRail(railId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/rails/${railId}`);
  }

  deleteRails(railIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/rails`, { params: { 'rail-id': railIds } });
  }

  filterRails(query?: string): Observable<GetRailDto[]> {
    return this._httpClient.get<GetRailDto[]>(`${this._configService.getBaseUrl()}/rails/filter?${query}`);
  }
}
