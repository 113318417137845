<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ logRequest?.simulationResult?.simulation?.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.SIMULATION_ID' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.simulationResult?.simulation?.id }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.SIMULATION_NAME' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.simulationResult?.simulation?.title }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{
          'LOG_REQUESTS_PAGE.SIMULATION_RESULT_UUID' | translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.simulationResultUUId }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.REQUESTING_USER' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ logRequest?.requestingUserId }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.HANDLING_HOST_ID' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.handlingHostId }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.REQUEST_TIME' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.requestTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.HANDLING_START_TIME'
          | translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.handlingStartTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.HANDLING_END_TIME' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.handlingEndTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'LOG_REQUESTS_PAGE.STATUS' | translate
          }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ getLogRequestStatusByIndex(logRequest.status) }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{
          'LOG_REQUESTS_PAGE.REQUESTING_CLIENT_LICENSE' | translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ logRequest?.requestingClientLicense }}</mat-card>
    </div>
  </div>
</div>