import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import decode from 'jwt-decode';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private readonly _titleService: Title) {}

  saveSimulationIds(simulationIds: number[]): void {
    localStorage.setItem('simulationIds', JSON.stringify({ simulationIds }));
  }

  getSimulationIds(): number[] {
    return JSON.parse(localStorage.getItem('simulationIds')!)?.simulationIds;
  }

  isSimulationIdsValid(): boolean {
    return this.getSimulationIds().length > 0;
  }

  removeAllSimulationIds(): void {
    localStorage.removeItem('simulationIds');
  }

  saveSimulationResultIds(simulationResultIds: number[]): void {
    localStorage.setItem('simulationResultIds', JSON.stringify({ simulationResultIds }));
  }

  getSimulationResultIds(): number[] {
    return JSON.parse(localStorage.getItem('simulationResultIds')!)?.simulationResultIds;
  }

  isSimulationResultIdsValid(): boolean {
    return this.getSimulationResultIds()?.length > 0;
  }

  removeAllSimulationResultIds(): void {
    localStorage.removeItem('simulationResultIds');
  }

  saveUserToken(token: string): void {
    localStorage.setItem('tk', token);
  }

  getUserToken(): string {
    return localStorage.getItem('tk')!;
  }

  removeUserToken(): void {
    localStorage.removeItem('tk');
  }

  getPageTitle(): string {
    return this._titleService.getTitle();
  }

  setPageTitle(title: string): void {
    this._titleService.setTitle(title);
  }

  getTableDisplaySettings(): any[] {
    return JSON.parse(localStorage.getItem('tableDisplaySettings')!);
  }

  getTableDisplaySettingsByName(tableName: string) {
    return this.getTableDisplaySettings()?.find(element => element.name == tableName);
  }

  setTableDisplayColumns(tableType: string, columns: string[]) {
    let currentSettings = this.getTableDisplaySettings();
    let isTableTypeSettingValid = this.getTableDisplaySettingsByName(tableType);
    if (isTableTypeSettingValid.length > 0) currentSettings = currentSettings.map(element => (element.name == tableType ? { ...element, columns } : element));
    else currentSettings.push({ name: tableType, columns });
    localStorage.setItem('tableDisplaySettings', JSON.stringify(currentSettings));
  }

  getSiteSunDarkMode() {
    return localStorage.getItem('mode') ?? 'light';
  }

  setSiteSunDarkMode(mode: string): void {
    localStorage.setItem('mode', mode);
  }

  getClaims(): Observable<string[]> {
    let token = this.getUserToken();
    let tokenPayload: any = decode(token);
    return of(tokenPayload.authorities);
  }

  getUserInformations(): string {
    let token = this.getUserToken();
    let tokenPayload: any = decode(token);
    return tokenPayload;
  }

  getLicense() {
    let token = this.getUserToken();
    let tokenPayload: any = decode(token);
    return tokenPayload.licenses.find((license: string) => license.includes('HiSimuX'));
  }

  createLinkAndDownloadFile(data: any, fileName: string, outputExtension: string) {
    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${fileName}.${outputExtension}`;
    link.click();
  }
}
