<div class="col-12" style="height: 80vh">
  <div *ngIf="isLoading && !isSystemError">
    <mat-spinner class="mx-auto my-4" color="primary"> </mat-spinner>
  </div>
  <app-system-error *ngIf="isSystemError && !isLoading" errorMessage="Some problems occur. Please try again.">
    <button mat-raised-button color="accent" class="my-2" (click)="tryAgain()">
      <mat-icon class="material-icons-round">refresh</mat-icon>
      Try Again
    </button>
  </app-system-error>
  <ng-container *ngIf="!isSystemError && !isLoading">
    <div class="d-flex justify-content-between">
      <h1 mat-dialog-title>Chart Information</h1>
      <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
    </div>
    <form [formGroup]="graphPropForm" mat-dialog-content>
      <div class="d-flex flex-column justify-content-center col-12 p-0">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Simulation</mat-label>
          <mat-select required formControlName="simulationId">
            <mat-option *ngFor="let simulationResult of simulationResults" [value]="simulationResult.simulationId">{{ simulationResult.simulation?.title }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Item Type</mat-label>
          <mat-select required formControlName="itemType" (selectionChange)="onItemTypeSelected($event.value)">
            <mat-option *ngFor="let item of itemTypes" [value]="item">{{ item }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <mat-select required formControlName="logObjectUUId" (selectionChange)="onLogObjectSelected($event.value)">
            <mat-option *ngFor="let scope of scopes$ | async" [value]="scope.logObjectUUID">
              {{ scope.name }}<ng-container *ngIf="scope.time">({{ scope.time }})</ng-container></mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Probe</mat-label>
          <mat-select required formControlName="logDataUUId" (selectionChange)="onLogDataSelected($event.value)">
            <mat-option *ngFor="let probe of probes$ | async" [value]="probe.logDataUUID"> {{ probe.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" />
        </mat-form-field>
        <div class="col-12 d-flex flex-row p-0">
          <div class="col-12 col-md-6 p-0">
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Label Y Left</mat-label>
              <input matInput formControlName="labelYLeft" />
            </mat-form-field>
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Units (Left)</mat-label>
              <mat-select formControlName="leftUnit">
                <mat-option *ngFor="let leftUnit of leftUnits" [value]="leftUnit">{{ leftUnit }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="col-12 d-flex flex-row justify-content-between align-items-center p-0">
              <mat-form-field class="col-6 p-0" appearance="outline" color="accent">
                <mat-label>Precision (Left)</mat-label>
                <input formControlName="leftPrecision" matInput />
              </mat-form-field>
              <div class="col-6">
                <mat-checkbox formControlName="customLeftPrecision">Custom Precision (Left)</mat-checkbox>
              </div>
            </div>
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Use Vertical Axis</mat-label>
              <mat-select formControlName="useVerticalAxis">
                <mat-option value="none">None</mat-option>
                <mat-option value="two">Second option</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 p-0">
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Label X Bottom</mat-label>
              <input matInput formControlName="labelXBottom" />
            </mat-form-field>
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Units (Bottom)</mat-label>
              <mat-select formControlName="bottomUnit">
                <mat-option *ngFor="let bottomUnit of bottomUnits" [value]="bottomUnit">{{ bottomUnit }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="col-12 d-flex flex-row justify-content-center align-items-center p-0">
              <mat-form-field class="col-6 p-0" appearance="outline" color="accent">
                <mat-label>Precision (Bottom)</mat-label>
                <input formControlName="bottomPrecision" matInput />
              </mat-form-field>
              <div class="col-6">
                <mat-checkbox formControlName="customBottomPrecision">Custom Precision (Bottom)</mat-checkbox>
              </div>
            </div>
            <mat-form-field class="col-12 p-0" appearance="outline" color="accent">
              <mat-label>Use Horizontal Axis</mat-label>
              <mat-select formControlName="useHorizontalAxis">
                <mat-option value="none">None</mat-option>
                <mat-option value="two">Second option</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-row justify-content-between align-items-center p-0">
        <mat-form-field class="col-12 col-md-4 p-0">
          <mat-label>Color picker</mat-label>
          <input type="color" matInput class="form-control form-control-color" formControlName="color" />
        </mat-form-field>
        <mat-form-field class="col-12 col-md-4 margin-removed-mat-form-field p-0" appearance="outline" color="accent">
          <mat-label>Thickness</mat-label>
          <mat-select formControlName="thickness">
            <mat-option *ngFor="let thickness of thicknesses" [value]="thickness">
              <div class="bg-dark" [ngStyle]="{ 'height.px': thickness }"></div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-4 margin-removed-mat-form-field p-0" appearance="outline" color="accent">
          <mat-label>Dash</mat-label>
          <mat-select formControlName="dash">
            <mat-option *ngFor="let dash of dashes" [value]="dash.value">
              <div [style]="dash.style"></div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div mat-dialog-actions>
      <button mat-raised-button mat-dialog-close>
        {{ 'CANCEL' | translate }}
      </button>
      <button mat-raised-button color="accent" (click)="getSimulationXMLDatas()">
        {{ 'CONTINUE' | translate }}
      </button>
    </div>
  </ng-container>
</div>
