import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/state/appState';
import { Operation } from 'src/app/models/response-models/operation';

@Component({
  selector: 'app-operation-preview-dialog',
  templateUrl: './operation-preview-dialog.component.html',
  styleUrls: ['./operation-preview-dialog.component.css'],
})
export class OperationPreviewDialogComponent implements OnInit {
  operationData!: Operation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { simulationResultId: number },
    private store: Store<AppState>
  ) {
    this.store
      .select(store => store.simulationResultState.operation)
      .subscribe(simulationResults => {
        this.operationData = simulationResults!.find(sim => sim.id === data.simulationResultId)!;
      });
  }

  ngOnInit(): void {}
}
