<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ operationData.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">
          {{ 'OPERATION_PAGE.TITLE' | translate }}
        </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ operationData.title }}
      </mat-card>
    </div>
    <ng-container *ngFor="let operationVal of operationData?.operationValues!!; let i = index">
      <div class="d-flex flex-row row">
        <mat-card class="my-1 col-12 col-md-6">
          <strong class="font-weight-bold"> Avg. Time T{{ i + 1 }} (min) </strong>
        </mat-card>
        <mat-card class="my-1 col-12 col-md-6">
          {{ operationData?.operationValues!![i]?.avgTime!! | convertDoubleToDate }}
        </mat-card>
      </div>
      <div class="d-flex flex-row row">
        <mat-card class="my-1 col-12 col-md-6">
          <strong class="font-weight-bold"> Avg. Speed T{{ i + 1 }} (km/h) </strong>
        </mat-card>
        <mat-card class="my-1 col-12 col-md-6">
          {{ operationData?.operationValues!![i]?.avgSpeed!! | getWithTwoFloatingPoints }}
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
