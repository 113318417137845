<div class="table-top border rounded bg-white">
  <button mat-flat-button color="accent" [matMenuTriggerFor]="menuExport" class="mr-2">Export</button>
  <mat-menu #menuExport="matMenu">
    <button (click)="exportEvent(OutputEnum.Excel, OutputExtensionEnum.Excel)" mat-menu-item>
      <img src="/assets/images/excel.svg" height="20" width="20" />
      <span class="ml-2">Export as Excel</span>
    </button>
    <button (click)="exportEvent(OutputEnum.Pdf, OutputExtensionEnum.Pdf)" mat-menu-item>
      <img src="/assets/images/pdf.svg" height="20" width="20" />
      <span class="ml-2">Export as Pdf</span>
    </button>
    <button (click)="exportEvent(OutputEnum.Word, OutputExtensionEnum.Word)" mat-menu-item>
      <img src="/assets/images/word.svg" height="20" width="20" />
      <span class="ml-2">Export as Word</span>
    </button>
  </mat-menu>
  <ng-content></ng-content>
  <span class="spacer"></span>
  <mat-form-field class="col-9 col-md-4 p-0" appearance="fill" color="accent">
    <mat-label>{{ 'FILTER' | translate }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup)="applyFilterEvent($event)" [placeholder]="'FILTER' | translate" #filterInput />
  </mat-form-field>
</div>
