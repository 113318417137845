import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/state/appState';
import { GetSimulationResultDto } from 'src/app/dtos/simulation-result/getSimulationResultDto';

@Component({
  selector: 'app-select-results-preview-dialog',
  templateUrl: './select-results-preview-dialog.component.html',
  styleUrls: ['./select-results-preview-dialog.component.css'],
})
export class SelectResultsPreviewDialogComponent implements OnInit {
  simulationResult!: GetSimulationResultDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly _data: { simulationResultId: number },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.simulationResultState.preview)
      .subscribe(simulationResults => {
        this.simulationResult = simulationResults!.find(sim => sim.id === _data.simulationResultId)!;
      });
  }

  ngOnInit(): void {}
}
