import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDoubleToDate',
})
export class ConvertDoubleToDatePipe implements PipeTransform {
  transform(value: number): string {
    let minult = (value / 60).toFixed(2);
    let second = value - parseInt(minult) * 60;
    return `${Math.round(parseInt(minult))}:${Math.round(second)}`;
  }
}
