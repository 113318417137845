<div style="width: 100%" class="d-flex justify-content-center align-items-center p-4">
  <mat-card style="height: 60vh" class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
    <div style="background-color: #f9f9f9; border-radius: 50px; width: 100px; height: 100px">
      <mat-icon color="accent" style="font-size: 100px"> rocket_launch </mat-icon>
    </div>
    <mat-list>
      <mat-list-item>
        <mat-icon color="accent" mat-list-icon>done</mat-icon>
        Conducting extensive simultaneous simulation processes
      </mat-list-item>
      <mat-list-item>
        <mat-icon color="accent" mat-list-icon>done</mat-icon>
        Creating customized company report templates to enhance reusability
      </mat-list-item>
      <mat-list-item>
        <mat-icon color="accent" mat-list-icon>done</mat-icon>
        Performing financial analyses
      </mat-list-item>
    </mat-list>
    <button class="my-2" mat-raised-button color="accent" (click)="upgradePlan()">Upgrade Your Plan</button>
  </mat-card>
</div>
