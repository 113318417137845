import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { AppState } from 'src/app/models/state/appState';
import { StatisticService } from 'src/app/services/statistic.service';
import { StatisticActionTypes, AddStatisticAction, AddStatisticActionError } from '../actions/statistic.actions';

@Injectable()
export class StatisticEffects {
  constructor(
    private actions$: Actions,
    private readonly _statisticService: StatisticService,
    private readonly _store: Store<AppState>
  ) {}

  loadSimulationsStatistics$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(StatisticActionTypes.LOAD_GENERAL_STATISTIC),
      withLatestFrom(this._store.select(state => state.statisticState.statistics)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._statisticService.getGeneralStatistic().pipe(
          map(response => new AddStatisticAction(response.data!!, null)),
          catchError(errorMessage => of(new AddStatisticActionError(null, errorMessage)))
        );
      })
    )
  );
}
