<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ train?.trainName!! }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.TRAIN' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train?.trainName!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.STATION' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.stationName }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.MIN_TRAIN_VOLTAGE_V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.minTrainVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.POSITION' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.minTrainVoltagePosition!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.LINE' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.minTrainVoltageLine!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.TIME' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train?.minTrainVoltageTime!! | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.MAX_RAIL_VOLTAGE_V' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.maxRailVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.POSITION' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.maxRailVoltagePosition!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.LINE' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.maxRailVoltageLine!! }}
      </mat-card>
    </div>

    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.TIME' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.maxRailVoltageTime!! | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.ARRIVAL_TIME' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.arrivalTime | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.DEPARTURE_TIME' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.departureTime | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.TACOMETER_READING' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.tacometerReading | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.DEPARTURE_TIME_FRACTION' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.departureTimeFraction }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train?.meanUsefulVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MAX_TRAIN_VOLTAGE' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.maxTrainVoltage | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRAINS_PAGE.TRAVEL_TIME' | translate }} </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train?.travelTime!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.CONSUMED_ENERGY' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.consumedEnergy | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.ENERGY_REGENERATED_TO_TRACK' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train?.energyRegeneratedToTrack!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE_IN_ZONE_1' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.uMeanUsefulInPowerZones[0] | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE_IN_ZONE_2' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.uMeanUsefulInPowerZones[1] | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE_IN_ZONE_3' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.uMeanUsefulInPowerZones[2] | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE_IN_ZONE_4' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.uMeanUsefulInPowerZones[3] | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">
          {{ 'TRAINS_PAGE.MEAN_USEFUL_VOLTAGE_IN_ZONE_5' | translate }}
        </strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ train.uMeanUsefulInPowerZones[4] | trunc }}
      </mat-card>
    </div>
  </div>
</div>
