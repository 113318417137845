import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Jumper } from 'src/app/models/response-models/jumper';
import { AppState } from 'src/app/models/state/appState';

@Component({
  selector: 'app-jumpers-preview-dialog',
  templateUrl: './jumpers-preview-dialog.component.html',
  styleUrls: ['./jumpers-preview-dialog.component.css'],
})
export class JumpersPreviewDialogComponent implements OnInit {
  jumper!: Jumper;
  jumperElement!: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: { simulationResultId: number; index: number },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.simulationResultState.jumpers)
      .subscribe(simulationResults => {
        this.jumper = simulationResults!.find(sim => sim.id === _data.simulationResultId)!;
      });

    this.jumperElement = {
      id: this.jumper.id,
      line1: this.jumper.line1,
      line2: this.jumper.line2,
      line1Name: this.jumper.line1Name,
      line2Name: this.jumper.line2Name,
      position1: this.jumper.positions1![_data.index],
      position2: this.jumper.positions2![_data.index],
      maxVoltage: this.jumper.maxVoltages![_data.index],
      minVoltage: this.jumper.minVoltages![_data.index],
      maxCurrent: this.jumper.maxCurrents![_data.index],
      max1MinRMSCurrent: this.jumper.max1MinRMSCurrents![_data.index],
      max1HrRMSCurrent: this.jumper.max1HrRMSCurrents![_data.index],
      maxRMSCurrent: this.jumper.maxRMSCurrents![_data.index],
    };
  }
  ngOnInit(): void {}
}
