import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GetSimulationRequestDto } from 'src/app/dtos/simulation-request/getSimulationRequestDto';
import { AppState } from 'src/app/models/state/appState';

@Component({
  selector: 'app-simulation-request-preview-dialog',
  templateUrl: './simulation-request-preview-dialog.component.html',
  styleUrls: ['./simulation-request-preview-dialog.component.css'],
})
export class SimulationRequestPreviewDialogComponent implements OnInit {
  simulationRequest!: GetSimulationRequestDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: { simulationRequestId: number },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.simulationRequestState.simulationRequests)
      .subscribe(simulationRequests => {
        this.simulationRequest = simulationRequests?.data!.find(simReq => simReq.id === _data.simulationRequestId)!;
      });
  }

  ngOnInit(): void {}
}
