import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetSimulationRequestDto } from 'src/app/dtos/simulation-request/getSimulationRequestDto';
import { SimulationStatusEnum } from 'src/app/enums/simulationStatusEnum';
import { SimulationRequestService } from 'src/app/services/simulation-request.service';

@Component({
  selector: 'app-simulation-request-preview-dialog',
  templateUrl: './simulation-request-preview-dialog.component.html',
  styleUrls: ['./simulation-request-preview-dialog.component.css'],
})
export class SimulationRequestPreviewDialogComponent implements OnInit {
  simulationRequest!: GetSimulationRequestDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: { simulationRequest: GetSimulationRequestDto },
    public readonly _simulationRequestService: SimulationRequestService
  ) {
    this.simulationRequest = _data.simulationRequest;
  }

  getSimulationStatusByIndex(index: number): string | undefined {
    const enumValues = Object.values(SimulationStatusEnum);
    return enumValues[index];
  }

  ngOnInit(): void {}
}
