import { Action } from '@ngrx/store';
import { GetSimulationRequestDto } from 'src/app/dtos/simulation-request/getSimulationRequestDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum SimulationRequestActionTypes {
  ADD_SIMULATION_REQUEST = '[SimulationRequest] Add SimulationRequest',
  ADD_SIMULATION_REQUEST_ERROR = '[SimulationRequest] Add SimulationRequest Error',
  LOAD_SIMULATION_REQUEST = '[SimulationRequest] Load SimulationRequest',
  LOAD_ODATA_SIMULATION_REQUEST = '[SimulationRequest] OData Load SimulationRequest',
  CLEAR_SIMULATION_REQUEST = '[SimulationRequest] Clear SimulationRequest',
}

export class AddSimulationRequestAction implements Action {
  readonly type = SimulationRequestActionTypes.ADD_SIMULATION_REQUEST;

  constructor(
    public payload: GetSimulationRequestDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddSimulationRequestActionError implements Action {
  readonly type = SimulationRequestActionTypes.ADD_SIMULATION_REQUEST_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationRequestDatas implements Action {
  readonly type = SimulationRequestActionTypes.LOAD_SIMULATION_REQUEST;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearSimulationRequestDatas implements Action {
  readonly type = SimulationRequestActionTypes.CLEAR_SIMULATION_REQUEST;

  constructor() {}
}

export type SimulationRequestAction = LoadSimulationRequestDatas | AddSimulationRequestAction | AddSimulationRequestActionError | ClearSimulationRequestDatas;
