import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddClaimDto } from '../dtos/claim/addClaimDto';
import { GetClaimDto } from '../dtos/claim/getClaimDto';
import { AddRoleDto } from '../dtos/role/addRoleDto';
import { GetRoleDto } from '../dtos/role/getRoleDto';
import { UpdateRoleDto } from '../dtos/role/updateRoleDto';
import { ResponseModel } from '../dtos/http/responseModel';
import { map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getRole(roleId: string): Observable<ResponseModel<GetRoleDto>> {
    return this._httpClient.get<ResponseModel<GetRoleDto>>(`${this._configService.getBaseIdentityUrl()}/roles/${roleId}`);
  }

  getRoles(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetRoleDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetRoleDto[]>>(`${this._configService.getBaseIdentityUrl()}/roles`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetRoleDto[]>
        )
      );
  }

  addRole(addRoleCommand: AddRoleDto): Observable<ResponseModel<GetRoleDto>> {
    return this._httpClient.post<ResponseModel<GetRoleDto>>(`${this._configService.getBaseIdentityUrl()}/roles`, addRoleCommand);
  }

  addClaimToRole(roleId: string, addClaimToRoleCommand: AddClaimDto): Observable<ResponseModel<GetClaimDto>> {
    return this._httpClient.post<ResponseModel<GetClaimDto>>(`${this._configService.getBaseIdentityUrl()}/roles/${roleId}/claims`, addClaimToRoleCommand);
  }

  updateRole(roleId: string, updateRoleCommand: UpdateRoleDto) {
    return this._httpClient.put<ResponseModel<GetRoleDto>>(`${this._configService.getBaseIdentityUrl()}/roles/${roleId}`, updateRoleCommand);
  }

  deleteRole(roleId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/roles/${roleId}/`);
  }

  deleteRoles(roleIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/roles/`, { params: { 'role-id': roleIds } });
  }
}
