import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-selection-action-bar',
  templateUrl: './table-selection-action-bar.component.html',
  styleUrls: ['./table-selection-action-bar.component.css'],
})
export class TableSelectionActionBarComponent implements OnInit {
  @Input() selectionLength!: number;

  constructor() {}

  ngOnInit(): void {}
}
