import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simulation-report-preview',
  templateUrl: './simulation-report-preview.component.html',
  styleUrls: ['./simulation-report-preview.component.css'],
})
export class SimulationReportPreviewComponent implements OnInit {
  reportUrl!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { reportUrl: string; reportId: number }
  ) {
    this.reportUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=' + data.reportUrl;
  }

  ngOnInit(): void {}
}
