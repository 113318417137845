import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PlayerDatas } from 'src/app/models/response-models/playerDatas';
import { AppState } from 'src/app/models/state/appState';
import { SharedService } from 'src/app/services/shared.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { SimulationActionTypes, AddPlayerAction, AddPlayerActionError } from '../actions/simulation.actions';

@Injectable()
export class SimulationEffects {
  constructor(
    private actions$: Actions,
    private readonly _simulationService: SimulationService,
    private readonly _sharedService: SharedService,
    private readonly _store: Store<AppState>
  ) {}

  loadSimulationPlayer$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationActionTypes.LOAD_SIMULATION_PLAYER_DATAS),
      withLatestFrom(this._store.select(state => state.simulationState.player)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._simulationService.getSimulationPlayerDatas(this._sharedService.getSimulationIds()).pipe(
          map(response => new AddPlayerAction(response.data as PlayerDatas[], null)),
          catchError(errorMessage => of(new AddPlayerActionError(null, errorMessage)))
        );
      })
    )
  );
}
