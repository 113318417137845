import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { UserService } from 'src/app/services/user.service';
import { AddUserAction, AddUserActionError, LoadUserDatas, UserActionTypes } from '../actions/user.action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private readonly _userService: UserService,
    private readonly _store: Store<AppState>
  ) {}

  loadUsers$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadUserDatas>(UserActionTypes.LOAD_USER),
      withLatestFrom(this._store.select(state => state.userState.users)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize))),
      switchMap(action => {
        return this._userService.getUsers(true, action[0].pageIndex, action[0].pageSize).pipe(
          map(
            response =>
              new AddUserAction(
                response.data!!,
                {
                  totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                } as HttpCommonHeaders,
                action[0].pageSize,
                action[0].pageIndex,
                null
              )
          ),
          catchError(errorMessage => of(new AddUserActionError(null, errorMessage)))
        );
      })
    )
  );
}
