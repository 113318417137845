import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.css'],
})
export class SystemErrorComponent implements OnInit {
  @Input()
  errorMessage: string = 'Some problems occur. Please try again.';

  constructor() {}

  ngOnInit(): void {}
}
