import { SelectionModel } from '@angular/cdk/collections';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { GetSimulationSetDto } from 'src/app/dtos/simulation-set/getSimulationSetDto';
import { SimComboSelectionTreeItemFlatNode, SimComboSelectionTreeItemNode } from 'src/app/models/component-models/simComboSelectionTree';

@Component({
  selector: 'app-sim-combo-selection-tree',
  templateUrl: './sim-combo-selection-tree.component.html',
  styleUrls: ['./sim-combo-selection-tree.component.css'],
})
export class SimComboSelectionTreeComponent implements OnInit {
  @Input()
  simulationSets!: GetSimulationSetDto[];

  @Output()
  simulationSetIdsOnChange = new EventEmitter<number[]>();

  @Output()
  simulationIdsOnChange = new EventEmitter<number[]>();

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  flatNodeMap = new Map<SimComboSelectionTreeItemFlatNode, SimComboSelectionTreeItemNode>();
  nestedNodeMap = new Map<SimComboSelectionTreeItemNode, SimComboSelectionTreeItemFlatNode>();
  treeControl: FlatTreeControl<SimComboSelectionTreeItemFlatNode>;
  treeFlattener: MatTreeFlattener<SimComboSelectionTreeItemNode, SimComboSelectionTreeItemFlatNode>;
  dataSource: MatTreeFlatDataSource<SimComboSelectionTreeItemNode, SimComboSelectionTreeItemFlatNode>;
  checklistSelection = new SelectionModel<SimComboSelectionTreeItemFlatNode>(true);

  constructor() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<SimComboSelectionTreeItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.checklistSelection.changed.subscribe(selectionChange => {
      let indexOfSimulation = 1;
      let indexOfSimulationSet = 1;
      selectionChange.source.selected.forEach(element => {
        if (!element.item.isSimulation) {
          element.index = indexOfSimulationSet;
          indexOfSimulationSet += 1;
        } else {
          element.index = indexOfSimulation;
          indexOfSimulation += 1;
        }
      });
      this.simulationSetIdsOnChange.emit(selectionChange.source.selected.filter(e => !e.item.isSimulation).map(val => val.item.id));
      this.simulationIdsOnChange.emit(selectionChange.source.selected.filter(e => e.item.isSimulation).map(val => val.item.id));
    });
  }

  ngOnInit(): void {
    this.dataSource.data = this.buildFileTree(this.simulationSets);
  }

  buildFileTree(obj: any): SimComboSelectionTreeItemNode[] {
    let items: SimComboSelectionTreeItemNode[] = [];
    for (let item of obj) {
      
      let node: SimComboSelectionTreeItemNode = {
        id: item.id,
        name: item.name,
        children: [],
        isSimulation: false,
      };

      if (item.simulations?.length > 0) {
        node.children = (item.simulations as any[]).map(val => ({
          id: val.id,
          name: val.title,
          children: [],
          isSimulation: true,
        }));
      }
      
      if (item.childSimulationSets?.length > 0) {
        node.children = (item.childSimulationSets as any[]).map(val => ({
          id: val.id,
          name: val.name,
          children: [],
          isSimulation: false,
        }));
        this.buildFileTree(item.childSimulationSets);
      }
      items.push(node);
    }
    return items;
  }

  getLevel = (node: SimComboSelectionTreeItemFlatNode) => node.level;

  isExpandable = (node: SimComboSelectionTreeItemFlatNode) => node.expandable;

  getChildren = (node: SimComboSelectionTreeItemNode): SimComboSelectionTreeItemNode[] => node.children;

  hasChild = (_: number, _nodeData: SimComboSelectionTreeItemFlatNode) => _nodeData.expandable;

  transformer = (node: SimComboSelectionTreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode: SimComboSelectionTreeItemFlatNode =
      existingNode && existingNode.item.id === node.id
        ? existingNode
        : {
            item: node,
            level: level,
            expandable: !!node.children?.length,
          };
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  remove(node: SimComboSelectionTreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  itemSelectionToggle(node: SimComboSelectionTreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  leafItemSelectionToggle(node: SimComboSelectionTreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
  }
}
