import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToKw',
})
export class ConvertToKwPipe implements PipeTransform {
  transform(WPower: number): number {
    return WPower / 1000;
  }
}
