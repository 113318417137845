import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trunc',
})
export class TruncPipe implements PipeTransform {
  transform(number: number): number {
    return Math.round(number);
  }
}
