import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findMax',
})
export class FindMaxPipe implements PipeTransform {
  transform(value: any): number {
    if (value) {
      return Math.max(...value);
    }
    return 0;
  }
}
