import { SimulationReportTemplateAction, SimulationReportTemplateActionTypes } from '../actions/report-template.action';
import { SimulationReportTemplateState } from 'src/app/models/state/appState';

const initialSimulationReportTemplateState: SimulationReportTemplateState = {
  isSimulationReportTemplatesLoaded: false,
};

export function SimulationReportTemplateReducer(state: SimulationReportTemplateState = initialSimulationReportTemplateState, action: SimulationReportTemplateAction | any): any {
  switch (action.type) {
    case SimulationReportTemplateActionTypes.ADD_SIMULATION_REPORT_TEMPLATE:
      return {
        ...state,
        simulationReportTemplates: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        simulationReportTemplateError: null,
      };
    case SimulationReportTemplateActionTypes.ADD_SIMULATION_REPORT_TEMPLATE_ERROR:
      return {
        ...state,
        simulationReportTemplates: null,
        simulationReportTemplateError: action.error,
      };
    case SimulationReportTemplateActionTypes.LOAD_SIMULATION_REPORT_TEMPLATE:
      return {
        ...state,
        isSimulationReportTemplatesLoaded: true,
        simulationReportTemplateError: null,
      };
    case SimulationReportTemplateActionTypes.CLEAR_SIMULATION_REPORT_TEMPLATE:
      return {
        ...state,
        simulationReportTemplates: null,
        isSimulationReportTemplatesLoaded: false,
        simulationReportTemplateError: null,
      };
    default:
      return state;
  }
}
