import { SimulationResultState } from 'src/app/models/state/appState';
import { SimulationResultAction, SimulationResultActionTypes } from '../actions/simulation-result.action';

const initialSimulationResultState: SimulationResultState = {
  isPreviewLoaded: false,
  isGeneralLoaded: false,
  isEnergyLoaded: false,
  isPssSummaryLoaded: false,
  isTransformersLoaded: false,
  isJumpersLoaded: false,
  isTrainsLoaded: false,
  isFeedersLoaded: false,
  isFeedersSummaryLoaded: false,
  isOperationLoaded: false,
};

export function SimulationResultReducer(state: SimulationResultState = initialSimulationResultState, action: SimulationResultAction | any): any {
  switch (action.type) {
    //Start of the preview reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_PREVIEW_DATAS:
      return {
        ...state,
        preview: action.payload,
        previewError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_PREVIEW_DATAS_ERROR:
      return {
        ...state,
        preview: null,
        previewError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PREVIEW_DATAS:
      return {
        ...state,
        isPreviewLoaded: true,
        previewError: null,
      };
    //End of the preview reducers

    //Start of the general reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_GENERAL_DATAS:
      return {
        ...state,
        general: action.payload,
        generalError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_GENERAL_DATAS_ERROR:
      return {
        ...state,
        general: null,
        generalError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_GENERAL_DATAS:
      return {
        ...state,
        isGeneralLoaded: true,
        generalError: null,
      };
    //End of the general reducers

    //Start of the energy reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_ENERGY_DATAS:
      return {
        ...state,
        energy: action.payload,
        energyError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_ENERGY_DATAS_ERROR:
      return {
        ...state,
        energy: null,
        energyError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_ENERGY_DATAS:
      return {
        ...state,
        isEnergyLoaded: true,
        energyError: null,
      };
    //End of the energy reducers

    //Start of the pss summary reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS:
      return {
        ...state,
        pssSummary: action.payload,
        pssSummaryError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS_ERROR:
      return {
        ...state,
        pssSummary: null,
        pssSummaryError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PSS_SUMMARY_DATAS:
      return {
        ...state,
        isPssSummaryLoaded: true,
        pssSummaryError: null,
      };
    //End of the pss summary reducers

    //Start of the transformer reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS:
      return {
        ...state,
        transformers: action.payload,
        transformersError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS_ERROR:
      return {
        ...state,
        transformers: null,
        transformersError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRANSFORMERS_DATAS:
      return {
        ...state,
        isTransformersLoaded: true,
        transformersError: null,
      };
    //End of the transformer reducers

    //Start of the feeders reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_DATAS:
      return {
        ...state,
        feeders: action.payload,
        feedersError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_DATAS_ERROR:
      return {
        ...state,
        feeders: null,
        feedersError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_DATAS:
      return {
        ...state,
        isFeedersLoaded: true,
        feedersError: null,
      };
    //End of the feeders reducers

    //Start of the feeder summary reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS:
      return {
        ...state,
        feedersSummary: action.payload,
        feederSummaryError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS_ERROR:
      return {
        ...state,
        feedersSummary: null,
        feederSummaryError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS:
      return {
        ...state,
        isFeedersSummaryLoaded: true,
        feederSummaryError: null,
      };
    //End of the feeder summary reducers

    //Start of the operation reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_OPERATION_DATAS:
      return {
        ...state,
        operation: action.payload,
        operationError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_OPERATION_DATAS_ERROR:
      return {
        ...state,
        operation: null,
        operationError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_OPERATION_DATAS:
      return {
        ...state,
        isOperationsLoaded: true,
        operationError: null,
      };
    //End of the operation reducers

    //Start of the jumper reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_JUMPER:
      return {
        ...state,
        jumpers: action.payload,
        jumpersError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_JUMPER_ERROR:
      return {
        ...state,
        jumpers: null,
        jumpersError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_JUMPERS_DATAS:
      return {
        ...state,
        isJumpersLoaded: true,
        jumpersError: null,
      };
    //End of the jumper reducers

    //Start of the train reducers
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRAIN:
      return {
        ...state,
        trains: action.payload,
        trainsError: null,
      };
    case SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRAIN_ERROR:
      return {
        ...state,
        trains: null,
        trainsError: action.error,
      };
    case SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRAINS_DATAS:
      return {
        ...state,
        isTrainsLoaded: true,
        trainsError: null,
      };
    //End of the train reducers

    case SimulationResultActionTypes.REMOVE_ALL_SIMULATION_RESULT_DATAS:
      return {
        ...initialSimulationResultState,
      };
    default:
      return state;
  }
}
