<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ simulationResult?.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.SIMULATION_UUID' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">{{ simulationResult?.simulationUUId!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.STATUS' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.status!! }} </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.SIMULATION_TIME' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">{{ simulationResult?.simulationTime | convertDate | cropDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.TITLE' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.title!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.DESCRIPTION' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.description!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.NUM_TRAIN_TYPES' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.trainTypeNames?.length!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.NUM_TRANSFORMERS' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.transformerShortNames?.length!! }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"><strong class="font-weight-bold">{{ 'SELECT_RESULTS_PREVIEW.NUM_LINES' |
          translate }}</strong></mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.lineNames?.length!! }}
      </mat-card>
    </div>
  </div>
</div>