import { RoleState } from 'src/app/models/state/appState';
import { RoleAction, RoleActionTypes } from '../actions/role.action';

const initialRoleState: RoleState = {
  isRolesLoaded: false,
};

export function RoleReducer(state: RoleState = initialRoleState, action: RoleAction | any): any {
  switch (action.type) {
    case RoleActionTypes.ADD_ROLE:
      return {
        ...state,
        roles: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        roleError: null,
      };
    case RoleActionTypes.ADD_ROLE_ERROR:
      return {
        ...state,
        roles: null,
        roleError: action.error,
      };
    case RoleActionTypes.LOAD_ROLE:
      return {
        ...state,
        isRolesLoaded: true,
        roleError: null,
      };
    case RoleActionTypes.CLEAR_ROLE:
      return {
        ...initialRoleState,
      };
    default:
      return state;
  }
}
