import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMimeType, MimeTypes } from 'src/app/enums/mimeTypes';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent implements OnInit {
  public MimeTypes: IMimeType[] = MimeTypes; // Note: use the = operator

  @Input()
  inputTitle!: string;

  @Input()
  fileName!: string;

  @Input()
  file!: any;

  @Input()
  acceptFile: any;

  selectedMimeType!: IMimeType;

  @Output() onFileChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onFileChange(event: any) {
    this.selectedMimeType = MimeTypes.find(x => x.type == event.target.files[0].type)!;
    this.fileName = event.target.files[0].name;
    this.file = event.target.files[0];
    this.onFileChanged.emit(event);
  }

  deleteFile() {
    this.file = null;
  }
}
