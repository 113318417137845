import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetVehicleDto } from '../dtos/vehicle/getVehicleDto';
import { UpdateVehicleDto } from '../dtos/vehicle/updateVehicleDto';
import { AddVehicleDto } from '../dtos/vehicle/addVehicleDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getVehicle(id: number): Observable<ResponseModel<GetVehicleDto>> {
    return this._httpClient.get<ResponseModel<GetVehicleDto>>(`${this._configService.getBaseUrl()}/vehicles/${id}`);
  }

  getVehicles(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetVehicleDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetVehicleDto[]>>(`${this._configService.getBaseUrl()}/vehicles`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetVehicleDto[]>
        )
      );
  }

  searchByName(vehicleName: string): Observable<ResponseModel<GetVehicleDto[]>> {
    return this._httpClient.get<ResponseModel<GetVehicleDto[]>>(`${this._configService.getBaseUrl()}/vehicles/search`, { params: { query: vehicleName }, observe: 'response' }).pipe(
      map(
        response =>
          ({
            ...response.body,
            headers: response.headers,
          }) as ResponseModel<GetVehicleDto[]>
      )
    );
  }

  addVehicle(cable: AddVehicleDto): Observable<ResponseModel<GetVehicleDto>> {
    return this._httpClient.post<ResponseModel<GetVehicleDto>>(`${this._configService.getBaseUrl()}/vehicles`, cable);
  }

  updateVehicle(id: number, cable: UpdateVehicleDto): Observable<ResponseModel<GetVehicleDto>> {
    return this._httpClient.put<ResponseModel<GetVehicleDto>>(`${this._configService.getBaseUrl()}/vehicles/${id}`, cable);
  }

  deleteVehicle(vehicleId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/vehicles/${vehicleId}`);
  }

  deleteVehicles(vehicleIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/vehicles`, { params: { 'vehicle-id': vehicleIds } });
  }

  filterVehicles(query?: string): Observable<GetVehicleDto[]> {
    return this._httpClient.get<GetVehicleDto[]>(`${this._configService.getBaseUrl()}/vehicles/filter?${query}`);
  }
}
