import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Probe } from 'src/app/models/response-models/probe';
import { ScopePreview } from 'src/app/models/response-models/scopePreview';
import { AppState } from 'src/app/models/state/appState';
import { DataLogService } from 'src/app/services/data-log.service';
import { SharedService } from 'src/app/services/shared.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { LoadSimulationResultPreviewDatasAction } from 'src/app/store/actions/simulation-result.action';
import { Units } from '../scope-tab/chart/constants';
import { UnitType } from '../scope-tab/chart/types';
import { SharedUIService } from 'src/app/services/shared-ui.service';
import { LogRequestService } from 'src/app/services/log-request.service';
import { GetSimulationResultDto } from 'src/app/dtos/simulation-result/getSimulationResultDto';

@Component({
  selector: 'app-scope-selection-dialog',
  templateUrl: './scope-selection-dialog.component.html',
  styleUrls: ['./scope-selection-dialog.component.css'],
})
export class ScopeSelectionDialogComponent implements OnInit {
  bottomUnits!: UnitType[];
  leftUnits!: UnitType[];

  itemTypes: string[] = ['Train', 'Transformer', 'Line', 'SI', 'Jumper', 'Track', 'Short Circuit', 'RPCD', 'Power Zone', 'Bonding', 'Line Side Resistor Bank'];

  thicknesses: number[] = [1, 2, 3, 4];
  dashes = [
    {
      style: 'margin: 5px 0; height: 2px; background: repeating-linear-gradient( to right, transparent, transparent 5px, black 5px, black 10px )',
      value: [5, 5],
    },
    {
      style: 'margin: 5px 0; height: 2px; background: repeating-linear-gradient( to right, transparent, transparent 10px, black 10px, black 20px )',
      value: [10, 10],
    },
    {
      style: 'margin: 5px 0; height: 2px; background: repeating-linear-gradient( to right, transparent, transparent 15px, black 15px, black 30px )',
      value: [15, 15],
    },
    {
      style: 'margin: 5px 0; height: 2px; background: repeating-linear-gradient( to right, transparent, transparent 20px, black 20px, black 40px )',
      value: [20, 20],
    },
  ];

  graphPropForm!: FormGroup;

  scopes$!: Observable<ScopePreview[]>;
  probes$!: Observable<Probe[]>;

  isSimulationIdsValid!: boolean;
  isLoading: boolean = true;
  isSystemError: boolean = false;

  simulationResults!: GetSimulationResultDto[];
  simulationGenerals!: GetSimulationResultDto[]; //this is used to get simulationResultUUIds
  simulationResultUUId!: string;

  constructor(
    private readonly _dialogRef: MatDialogRef<ScopeSelectionDialogComponent>,
    private readonly _sharedService: SharedService,
    private readonly _sharedUIService: SharedUIService,
    private readonly _simulationService: SimulationService,
    private readonly _dataLogService: DataLogService,
    private readonly _logService: LogRequestService,
    private readonly _store: Store<AppState>
  ) {
    this.graphPropForm = new FormGroup({
      title: new FormControl('', []),
      labelYLeft: new FormControl('', []),
      leftUnit: new FormControl('', []),
      leftPrecision: new FormControl({ value: -1, disabled: true }, []),
      customLeftPrecision: new FormControl(false, []),
      labelXBottom: new FormControl('', []),
      bottomUnit: new FormControl('', []),
      bottomPrecision: new FormControl({ value: -1, disabled: true }, []),
      customBottomPrecision: new FormControl(false, []),
      useHorizontalAxis: new FormControl('none', []),
      useVerticalAxis: new FormControl('none', []),
      color: new FormControl(this.getRandomColor(), []),
      thickness: new FormControl(this.thicknesses[0], []),
      dash: new FormControl(this.dashes[0], []),
      simulationId: new FormControl(null, [Validators.required]),
      logDataUUId: new FormControl(null, [Validators.required]),
      logObjectUUId: new FormControl(null, [Validators.required]),
      itemType: new FormControl(null, [Validators.required]),
    });

    this._store
      .select(state => state.simulationResultState.previewError)
      .subscribe(e => {
        if (!e) return;

        this.isSystemError = true;
        this.isLoading = false;
      });

    this.graphPropForm.get('customBottomPrecision')?.valueChanges.subscribe(val => {
      if (val) this.graphPropForm.get('bottomPrecision')?.enable();
      else this.graphPropForm.get('bottomPrecision')?.disable();
    });

    this.graphPropForm.get('customLeftPrecision')?.valueChanges.subscribe(val => {
      if (val) this.graphPropForm.get('leftPrecision')?.enable();
      else this.graphPropForm.get('leftPrecision')?.disable();
    });
  }

  ngOnInit(): void {
    if (this._sharedService.isSimulationResultIdsValid()) {
      this._store.dispatch(new LoadSimulationResultPreviewDatasAction());
      this.isSimulationIdsValid = true;
      this._store
        .select(store => store.simulationResultState.simulationResults)
        .subscribe(data => {
          if (!data) return;

          this.isLoading = false;
          this.simulationResults = data;
        });

      this._store
        .select(store => store.simulationResultState.simulationResults)
        .subscribe(data => {
          if (!data) return;

          this.simulationGenerals = data;
        });
    } else {
      this.isLoading = false;
      this.isSimulationIdsValid = false;
    }
  }

  onItemTypeSelected(itemType: string) {
    let simulationId = this.graphPropForm.get('simulationId')?.value;
    this.simulationResultUUId = this.simulationGenerals.find(s => s.simulationId === simulationId)?.simulationResultUUId!;
    this.scopes$ = this._simulationService.getSimulationScopePreviewDatasBySimulationId(simulationId, itemType).pipe(map(response => response.data!));
  }

  onLogObjectSelected(logObjectUUID: string) {
    this.probes$ = this.scopes$.pipe(
      map(src => src.find(s => s.logObjectUUID == logObjectUUID)),
      select(src => src?.probes!)
    );
  }

  onLogDataSelected(logDataUUId: string) {
    this.probes$.subscribe(probes => {
      let selectedProbe = probes.find(prob => prob.logDataUUID === logDataUUId)!;
      this.graphPropForm.patchValue({
        labelYLeft: selectedProbe.name,
        labelXBottom: 'Time',
        title: `${selectedProbe.name} - Time`,
      });

      this.leftUnits = Units.find(val => val.name === selectedProbe.name)?.values!;
      this.bottomUnits = Units.find(val => val.name === 'Time')?.values!;
    });
  }

  tryAgain() {
    this.isLoading = true;
    this.isSystemError = false;
    this._store.dispatch(new LoadSimulationResultPreviewDatasAction());
  }

  getSimulationXMLDatas() {
    this.isLoading = true;
    let logDataUUId = this.graphPropForm.get('logDataUUId')?.value;
    let logObjectUUId = this.graphPropForm.get('logObjectUUId')?.value;
    //simulationResultUUId, logObjectUUId, logName, logObjectName, logObjectType;
    this._dataLogService.getDataLog(this.simulationResultUUId, logObjectUUId, logDataUUId).subscribe(
      response => {
        this.isLoading = false;

        if (!response.success) this._sharedUIService.showErrorSnackBar();

        let responseData = response.data;

        //If there is no data and the response is success, it means that the data log is requested.
        if (!responseData) {
          this._logService
            .addLogRequest({
              simulationResultUUId: this.simulationResultUUId,
              logObjectUUId: logObjectUUId,
              logName: logDataUUId,
              logObjectName: this.graphPropForm.get('labelYLeft')?.value,
              logObjectType: this.graphPropForm.get('itemType')?.value,
            })
            .subscribe(response => {
              if (!response.success) this._sharedUIService.showErrorSnackBar();

              this._sharedUIService.showSuccessSnackBar('There was no data. Therefore, data log is requested.');
            });
          return;
        }

        this._dialogRef.close({
          id: responseData.id,
          data: responseData.data,
          title: this.graphPropForm.get('title')?.value,
          color: this.graphPropForm.get('color')?.value,
          thickness: this.graphPropForm.get('thickness')?.value,
          dash: this.graphPropForm.get('dash')?.value,
          labelYLeft: this.graphPropForm.get('labelYLeft')?.value,
          leftUnit: this.graphPropForm.get('leftUnit')?.value,
          labelXBottom: this.graphPropForm.get('labelXBottom')?.value,
          bottomUnit: this.graphPropForm.get('bottomUnit')?.value,
          samplingTime: responseData.samplingTime,
        });
      },
      error => {
        this._sharedUIService.showErrorSnackBar();
        this.isLoading = false;
      }
    );
  }

  getRandomColor(opacity = 1): string {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacity.toFixed(1) + ')';
  }
}
