import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateThreshold',
})
export class CalculateThresholdPipe implements PipeTransform {
  transform(railVoltageThresholds: any, maxTimeOverThresholds: any, threshold: any): any {
    var index = railVoltageThresholds.indexOf(threshold);
    return index < 0 ? index : maxTimeOverThresholds[index];
  }
}
