import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/state/appState';
import { Feeder } from 'src/app/models/response-models/feeder';

@Component({
  selector: 'app-feeder-preview-dialog',
  templateUrl: './feeder-preview-dialog.component.html',
  styleUrls: ['./feeder-preview-dialog.component.css'],
})
export class FeederPreviewDialogComponent implements OnInit {
  feederData!: Feeder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly _data: { transformerName: string },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.simulationResultState.feeders)
      .subscribe(simulationResults => {
        this.feederData = simulationResults?.flatMap(s => s.feeders).find(fed => fed?.transformerName === _data.transformerName)!;
      });
  }

  ngOnInit(): void {}
}
