import { Action } from '@ngrx/store';
import { GetUserDto } from 'src/app/dtos/users/getUserDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum UserActionTypes {
  ADD_USER = '[User] Add User',
  ADD_USER_ERROR = '[User] Add User Error',
  LOAD_USER = '[User] Load User',
  CLEAR_USER = '[User] Clear User',
}

export class AddUserAction implements Action {
  readonly type = UserActionTypes.ADD_USER;

  constructor(
    public payload: GetUserDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddUserActionError implements Action {
  readonly type = UserActionTypes.ADD_USER_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadUserDatas implements Action {
  readonly type = UserActionTypes.LOAD_USER;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearUserDatas implements Action {
  readonly type = UserActionTypes.CLEAR_USER;

  constructor() {}
}

export type UserAction = LoadUserDatas | AddUserAction | AddUserActionError | ClearUserDatas;
