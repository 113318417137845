import { Component, Input, OnInit } from '@angular/core';
import { ScopeMenuItem } from 'src/app/models/component-models/scopeModel';
import { Plugins, VisibilityModes, MouseModes, ZoomModes, ExportModes, ZoomRatios } from '../scope-tab/chart/enums';
import HISIMChart from '../scope-tab/chart/hisimChart';
import { BrushModes, BrushModeType } from '../scope-tab/chart/plugins/brushPlugin';
import { RulerModes, RulerModeType } from '../scope-tab/chart/plugins/rulerPlugin';
import { TextModes, TextModeType } from '../scope-tab/chart/plugins/textPlugin';
import { DrawingItem, ExportType, MouseModeType, PluginType, VisibilityModeType, ZoomModeType } from '../scope-tab/chart/types';

@Component({
  selector: 'app-scope-side-menu',
  templateUrl: './scope-side-menu.component.html',
  styleUrls: ['./scope-side-menu.component.css'],
})
export class ScopeSideMenuComponent implements OnInit {
  showMouseMoveOptionsIcon: boolean = false;
  showZoomOptionsIcon: boolean = false;
  showBrushOptionsIcon: boolean = false;
  showTextOptionsIcon: boolean = false;
  showRulerOptionsIcon: boolean = false;
  showExportOptionsIcon: boolean = false;

  @Input()
  chart!: HISIMChart;

  @Input()
  currentPlugin!: PluginType;

  @Input()
  chartOptions!: any;

  @Input()
  drawingItems!: DrawingItem[];

  @Input()
  mouseMode!: MouseModeType;

  @Input()
  brushMode!: BrushModeType;

  @Input()
  rulerMode!: RulerModeType;

  @Input()
  visibilityMode!: VisibilityModeType;

  @Input()
  textMode!: TextModeType;

  @Input()
  expandMode!: ZoomModeType;

  sideMenu: ScopeMenuItem[] = [
    {
      pluginId: Plugins.Corsair,
      icon: 'point_scan',
      iconStyle: 'material-symbols-outlined',
      tooltip: 'Cursor',
      clickEvent: () => {
        this.changeCursorMode(this.mouseMode);
      },
      mouseover: () => (this.showMouseMoveOptionsIcon = true),
      mouseout: () => (this.showMouseMoveOptionsIcon = false),
      smallIconVisibility: () => (this.showMouseMoveOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return this.currentPlugin == Plugins.Corsair;
      },
      subMenu: [
        {
          icon: 'point_scan',
          iconStyle: 'material-symbols-outlined',
          title: 'Crosshair',
          tooltip: 'Crosshair cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Crosshair);
            this.sideMenu[0].icon = 'point_scan';
          },
        },
        {
          icon: 'open_with',
          title: 'Cross',
          tooltip: 'Cross cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Move);
            this.sideMenu[0].icon = 'open_with';
          },
        },
        {
          icon: 'open_with',
          title: 'Cell',
          tooltip: 'Cell cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Cell);
            this.sideMenu[0].icon = 'open_with';
          },
        },
        {
          icon: 'fiber_manual_record',
          title: 'Dot',
          tooltip: 'Dot cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Move);
            this.sideMenu[0].icon = 'fiber_manual_record';
          },
        },
        {
          icon: 'back_hand',
          title: 'Pointer',
          tooltip: 'Pointer cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Pointer);
            this.sideMenu[0].icon = 'back_hand';
          },
        },
        {
          icon: 'arrow_selector_tool',
          iconStyle: 'material-symbols-outlined',
          title: 'Arrow',
          tooltip: 'Arrow cursor',
          clickEvent: () => {
            this.changeCursorMode(MouseModes.Default);
            this.sideMenu[0].icon = 'arrow_selector_tool';
          },
        },
      ],
    },
    {
      pluginId: Plugins.Brush,
      icon: 'brush',
      tooltip: 'Brush',
      clickEvent: () => {
        this.changeBrushMode(this.brushMode);
      },
      mouseover: () => (this.showBrushOptionsIcon = true),
      mouseout: () => (this.showBrushOptionsIcon = false),
      smallIconVisibility: () => (this.showBrushOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return this.currentPlugin == Plugins.Brush;
      },
      subMenu: [
        {
          icon: 'brush',
          title: 'Brush',
          tooltip: 'Brush',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.Brush);
            this.sideMenu[1].icon = 'brush';
          },
        },
        {
          icon: 'square',
          title: 'Square',
          tooltip: 'Square',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.Rectangle);
            this.sideMenu[1].icon = 'square';
          },
        },
        {
          icon: 'circle',
          title: 'Circle',
          tooltip: 'Circle',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.Circle);
            this.sideMenu[1].icon = 'circle';
          },
        },
        {
          icon: 'timeline',
          title: 'Path',
          tooltip: 'Path',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.Path);
            this.sideMenu[1].icon = 'timeline';
          },
        },
        {
          icon: 'hexagon',
          title: 'Ellipse',
          tooltip: 'Ellipse',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.Ellipse);
            this.sideMenu[1].icon = 'hexagon';
          },
        },
        {
          icon: 'arrow_back',
          title: 'Arrow Mark Left',
          tooltip: 'Arrow Mark Left',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.ArrowLeft);
            this.sideMenu[1].icon = 'arrow_back';
          },
        },
        {
          icon: 'arrow_forward',
          title: 'Arrow Mark Right',
          tooltip: 'Arrow Mark Right',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.ArrowRight);
            this.sideMenu[1].icon = 'arrow_forward';
          },
        },
        {
          icon: 'arrow_upward',
          title: 'Arrow Mark Up',
          tooltip: 'Arrow Mark Up',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.ArrowUpward);
            this.sideMenu[1].icon = 'arrow_upward';
          },
        },
        {
          icon: 'arrow_downward',
          title: 'Arrow Mark Down',
          tooltip: 'Arrow Mark Down',
          clickEvent: () => {
            this.changeBrushMode(BrushModes.ArrowDownward);
            this.sideMenu[1].icon = 'arrow_downward';
          },
        },
      ],
    },
    {
      pluginId: Plugins.Text,
      icon: 'text_fields',
      tooltip: 'Text',
      clickEvent: () => {
        this.changeTextMode(this.textMode);
      },
      mouseover: () => (this.showTextOptionsIcon = true),
      mouseout: () => (this.showTextOptionsIcon = false),
      smallIconVisibility: () => (this.showTextOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return this.currentPlugin == Plugins.Text;
      },
      subMenu: [
        {
          icon: 'text_fields',
          title: 'Text',
          tooltip: 'Text',
          clickEvent: () => {
            this.changeTextMode(TextModes.Text);
            this.sideMenu[2].icon = 'text_fields';
          },
        },
        {
          icon: 'arrow_back',
          title: 'Arrow Mark Left',
          tooltip: 'Arrow Mark Left',
          clickEvent: () => {
            this.changeTextMode(TextModes.ArrowMarkLeft);
            this.sideMenu[2].icon = 'arrow_back';
          },
        },
        {
          icon: 'arrow_forward',
          title: 'Arrow Mark Right',
          tooltip: 'Arrow Mark Right',
          clickEvent: () => {
            this.changeTextMode(TextModes.ArrowMarkRight);
            this.sideMenu[2].icon = 'arrow_forward';
          },
        },
        {
          icon: 'arrow_upward',
          title: 'Arrow Mark Up',
          tooltip: 'Arrow Mark Up',
          clickEvent: () => {
            this.changeTextMode(TextModes.ArrowMarkUpward);
            this.sideMenu[2].icon = 'arrow_upward';
          },
        },
        {
          icon: 'arrow_downward',
          title: 'Arrow Mark Down',
          tooltip: 'Arrow Mark Down',
          clickEvent: () => {
            this.changeTextMode(TextModes.ArrowMarkDownward);
            this.sideMenu[2].icon = 'arrow_downward';
          },
        },
      ],
    },
    {
      pluginId: Plugins.Expand,
      icon: 'expand',
      iconStyle: 'counter-clockwise-half-rotated-icon',
      tooltip: 'Expand',
      clickEvent: () => {
        this.changeExpandMode(this.expandMode);
      },
      mouseover: () => (this.showZoomOptionsIcon = true),
      mouseout: () => (this.showZoomOptionsIcon = false),
      smallIconVisibility: () => (this.showZoomOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return this.currentPlugin == Plugins.Expand;
      },
      subMenu: [
        {
          icon: 'expand',
          iconStyle: 'counter-clockwise-half-rotated-icon',
          title: 'Expand in x',
          tooltip: 'Expand in x axis',
          clickEvent: () => {
            this.changeExpandMode(ZoomModes.X);
            this.sideMenu[3].icon = 'expand';
            this.sideMenu[3].iconStyle = 'counter-clockwise-half-rotated-icon';
          },
        },
        {
          icon: 'expand',
          title: 'Expand in y',
          tooltip: 'Expand in y axis',
          clickEvent: () => {
            this.changeExpandMode(ZoomModes.Y);
            this.sideMenu[3].icon = 'expand';
            this.sideMenu[3].iconStyle = '';
          },
        },
        {
          icon: 'expand',
          iconStyle: 'clockwise-quarter-rotated-icon',
          title: 'Expand in xy',
          tooltip: 'Expand in xy axis',
          clickEvent: () => {
            this.changeExpandMode(ZoomModes.Both);
            this.sideMenu[3].icon = 'expand';
            this.sideMenu[3].iconStyle = 'clockwise-quarter-rotated-icon';
          },
        },
      ],
    },
    {
      pluginId: Plugins.Ruler,
      icon: 'straighten',
      tooltip: 'Ruler',
      clickEvent: () => {
        this.changeRulerMode(this.rulerMode);
      },
      mouseover: () => (this.showRulerOptionsIcon = true),
      mouseout: () => (this.showRulerOptionsIcon = false),
      smallIconVisibility: () => (this.showRulerOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return this.currentPlugin == Plugins.Ruler;
      },
      subMenu: [
        {
          icon: 'straighten',
          title: 'Ruler in x',
          tooltip: 'Ruler in x axis',
          clickEvent: () => {
            this.changeRulerMode(RulerModes.X);
            this.sideMenu[4].icon = 'straighten';
            this.sideMenu[4].iconStyle = '';
          },
        },
        {
          icon: 'straighten',
          iconStyle: 'counter-clockwise-half-rotated-icon',
          title: 'Ruler in y',
          tooltip: 'Ruler in y axis',
          clickEvent: () => {
            this.changeRulerMode(RulerModes.Y);
            this.sideMenu[4].icon = 'straighten';
            this.sideMenu[4].iconStyle = 'counter-clockwise-half-rotated-icon';
          },
        },
        {
          icon: 'straighten',
          iconStyle: 'clockwise-quarter-rotated-icon',
          title: 'Ruler in xy',
          tooltip: 'Ruler in xy axis',
          clickEvent: () => {
            this.changeRulerMode(RulerModes.XY);
            this.sideMenu[4].icon = 'straighten';
            this.sideMenu[4].iconStyle = 'clockwise-quarter-rotated-icon';
          },
        },
      ],
    },
    {
      pluginId: Plugins.ZoomIn,
      icon: 'zoom_in',
      tooltip: 'Zoom in',
      clickEvent: () => {
        this.zoomIn();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.currentPlugin == Plugins.ZoomIn;
      },
    },
    {
      pluginId: Plugins.ZoomOut,
      icon: 'zoom_out',
      tooltip: 'Zoom out',
      clickEvent: () => {
        this.zoomOut();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.currentPlugin == Plugins.ZoomOut;
      },
    },
    {
      pluginId: Plugins.Examine,
      icon: 'right_click',
      iconStyle: 'material-symbols-outlined',
      tooltip: 'Examine points',
      clickEvent: () => {
        this.examine();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.currentPlugin == Plugins.Examine;
      },
    },
    {
      pluginId: Plugins.Gestures,
      icon: 'gestures',
      tooltip: 'Enable gestures',
      clickEvent: () => {
        this.enableGestures();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.currentPlugin == Plugins.Gestures;
      },
    },
    {
      icon: 'download',
      iconStyle: 'material-symbols-outlined',
      tooltip: 'Export drawings',
      clickEvent: () => {
        this.showExportOptionsIcon = true;
      },
      mouseover: () => {
        this.showExportOptionsIcon = true;
      },
      mouseout: () => {
        this.showExportOptionsIcon = false;
      },
      smallIconVisibility: () => (this.showExportOptionsIcon ? VisibilityModes.Visible : VisibilityModes.Hidden),
      isSelected: () => {
        return false;
      },
      subMenu: [
        {
          icon: 'photo',
          title: 'Export as jpeg',
          tooltip: 'Export drawings as jpeg',
          clickEvent: () => {
            this.exportDrawing(ExportModes.JPEG);
          },
        },
        {
          icon: 'photo',
          title: 'Export as png',
          tooltip: 'Export drawings as png',
          clickEvent: () => {
            this.exportDrawing(ExportModes.PNG);
          },
        },
      ],
    },
    {
      pluginId: Plugins.LockPanel,
      icon: 'lock',
      tooltip: 'Lock all drawing tools',
      clickEvent: () => {
        this.lockPanel();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.currentPlugin == Plugins.LockPanel;
      },
    },
    {
      pluginId: Plugins.Hide,
      icon: 'visibility',
      tooltip: 'Hide all drawings',
      clickEvent: () => {
        this.hideDrawings();
        this.sideMenu[11].icon = this.visibilityMode == VisibilityModes.Hidden ? 'visibility_off' : 'visibility';
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return this.visibilityMode == VisibilityModes.Hidden;
      },
    },
    {
      icon: 'delete',
      tooltip: 'Delete all drawings',
      clickEvent: () => {
        this.clearWork();
      },
      mouseover: () => {},
      mouseout: () => {},
      smallIconVisibility: () => 'hidden',
      isSelected: () => {
        return false;
      },
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  exportDrawing(exportOption: ExportType) {
    let data = this.chart.toBase64Image(`image/${exportOption}`, 1);
    var a = document.createElement('a'); //Create <a>
    a.href = data; //Image Base64 Goes here
    a.download = `scope.${exportOption}`; //File name Here
    a.click();
  }

  changeExpandMode(expandMode: ZoomModeType) {
    this.currentPlugin = Plugins.Expand;
    this.chart.togglePlugin(this.currentPlugin);
    this.mouseMode =
      expandMode === ZoomModes.X ? MouseModes.ColResize : expandMode === ZoomModes.Y ? MouseModes.RowResize : expandMode === ZoomModes.Both ? MouseModes.NeswResize : MouseModes.ColResize;
    this.chart.zoomIn(expandMode);
    this.expandMode = expandMode;
  }

  changeRulerMode(rulerMode: RulerModeType) {
    this.currentPlugin = Plugins.Ruler;
    this.chart.togglePlugin(this.currentPlugin);
    this.chart.toggleRulerModeShape(rulerMode);
    this.rulerMode = rulerMode;
  }

  changeTextMode(textMode: TextModeType) {
    this.currentPlugin = Plugins.Text;
    this.chart.togglePlugin(this.currentPlugin);
    this.chart.toggleTextModeShape(textMode);
    this.textMode = textMode;
  }

  enableGestures() {
    this.currentPlugin = this.currentPlugin == Plugins.Gestures ? Plugins.Corsair : Plugins.Gestures;
    this.chart.togglePlugin(this.currentPlugin);
  }

  lockPanel() {
    this.currentPlugin = this.currentPlugin == Plugins.LockPanel ? Plugins.Corsair : Plugins.LockPanel;
    this.chart.togglePlugin(this.currentPlugin);
  }

  hideDrawings() {
    if (this.visibilityMode == VisibilityModes.Hidden) {
      this.currentPlugin = Plugins.Corsair;
      this.visibilityMode = VisibilityModes.Visible;
      this.drawingItems.forEach(drawing => (drawing.isVisible = true));
    } else {
      this.currentPlugin = Plugins.Hide;
      this.visibilityMode = VisibilityModes.Hidden;
      this.drawingItems.forEach(drawing => (drawing.isVisible = false));
    }
    this.chart.togglePlugin(this.currentPlugin);
  }

  clearWork() {
    this.drawingItems.forEach(drawing => (drawing.isDeleted = true));
    this.chartOptions.plugins.zoom.zoom.drag.enabled = false;
    this.resetZoom();
  }

  changeBrushMode(brushMode: BrushModeType) {
    this.currentPlugin = Plugins.Brush;
    this.chart.togglePlugin(this.currentPlugin);
    this.chart.toggleBrushModeShape(brushMode);
    this.brushMode = brushMode;
  }

  changeCursorMode(cursorMode: MouseModeType) {
    this.currentPlugin = Plugins.Corsair;
    this.chart.togglePlugin(this.currentPlugin);
    this.mouseMode = cursorMode;
  }

  resetZoom() {
    this.chart.resetZoom();
  }

  zoomOut() {
    this.chart.zoom(ZoomRatios.ZoomOut);
  }

  zoomIn() {
    this.chart.zoom(ZoomRatios.ZoomIn);
  }

  examine() {
    this.currentPlugin = this.currentPlugin == Plugins.Examine ? Plugins.Corsair : Plugins.Examine;
    this.chart.togglePlugin(this.currentPlugin);
  }
}
