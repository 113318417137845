import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState } from 'src/app/models/state/appState';
import { CompanyService } from 'src/app/services/company.service';
import { AddCompanyAction, AddCompanyActionError, CompanyActionTypes } from '../actions/company.action';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private readonly _companyService: CompanyService,
    private readonly _store: Store<AppState>
  ) {}

  loadCompanies$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(CompanyActionTypes.LOAD_COMPANY),
      withLatestFrom(this._store.select(state => state.companyState.companies)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._companyService.getCompanies().pipe(
          map(response => new AddCompanyAction(response.data!!, null)),
          catchError(errorMessage => of(new AddCompanyActionError(null, errorMessage)))
        );
      })
    )
  );
}
