import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetLogRequestDto } from 'src/app/dtos/log-request/getLogRequestDto';
import { LogRequestStatusEnum } from 'src/app/enums/logRequestStatus';
import { LogRequestService } from 'src/app/services/log-request.service';

@Component({
  selector: 'app-log-request-preview-dialog',
  templateUrl: './log-request-preview-dialog.component.html',
  styleUrls: ['./log-request-preview-dialog.component.css'],
})
export class LogRequestPreviewDialogComponent implements OnInit {
  logRequest!: GetLogRequestDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: { logRequest: GetLogRequestDto },
    public readonly _logRequestService: LogRequestService
  ) {
    this.logRequest = _data.logRequest;
  }

  getLogRequestStatusByIndex(index: number): string | undefined {
    const enumValues = Object.values(LogRequestStatusEnum);
    return enumValues[index];
  }

  ngOnInit(): void {}
}
