<div #item class="tooltip-box" cdkDrag [style.visibility]="'hidden'">
  <mat-icon style="cursor: grab" cdkDragHandle>drag_indicator</mat-icon>
  <button
    *ngFor="let tooltipMenuItem of tooltipMenu"
    [matTooltip]="tooltipMenuItem.tooltip"
    matTooltipPosition="below"
    class="p-0"
    mat-button
    (click)="tooltipMenuItem.clickEvent()"
    [style.display]="tooltipMenuItem.isVisible() ? 'block' : 'none'">
    <mat-icon>{{ tooltipMenuItem.icon }}</mat-icon>
  </button>
</div>
