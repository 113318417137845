<mat-toolbar class="d-flex p-0 m-sm-0 align-items-center mat-elevation-z2">
  <button class="col-1 d-sm-none" mat-icon-button (click)="menuClick.emit()">
    <mat-icon class="material-icons-round">menu</mat-icon>
  </button>
  <form class="col-11 col-sm-12 col-lg-6 p-1 p-sm-2" style="font-size: 13px">
    <mat-form-field class="col-12 p-0 m-0" appearance="outline" color="accent">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput placeholder="Search in admin panel" />
    </mat-form-field>
  </form>
</mat-toolbar>
