<mat-form-field class="col-12" color="accent" appearance="outline">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let node of checklistSelection.selected" [selectable]="selectable" [removable]="removable" (removed)="remove(node)">
      Simulation {{ node.item.isSimulation ? '' : 'Set' }} {{ node.index }} |
      {{ node.item.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="Select simulation set or simulation..." [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <mat-checkbox style="margin-left: 20px" class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="leafItemSelectionToggle(node)">
          {{ node.item.name }}
        </mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="checklistSelection.isSelected(node)" (change)="itemSelectionToggle(node)">
          {{ node.item.name }}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
    <mat-option style="display: none" disabled> Please select an item from below </mat-option>
  </mat-autocomplete>
</mat-form-field>
