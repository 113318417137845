import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { SimulationRequestService } from 'src/app/services/simulation-request.service';
import { AddSimulationRequestAction, AddSimulationRequestActionError, LoadSimulationRequestDatas, SimulationRequestActionTypes } from '../actions/simulation-request.action';

@Injectable()
export class SimulationRequestEffects {
  constructor(
    private actions$: Actions,
    private readonly _simulationRequestService: SimulationRequestService,
    private readonly _store: Store<AppState>
  ) {}

  loadSimulationRequests$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadSimulationRequestDatas>(SimulationRequestActionTypes.LOAD_SIMULATION_REQUEST),
      withLatestFrom(this._store.select(state => state.simulationRequestState.simulationRequests)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize))),
      switchMap(action => {
        return this._simulationRequestService.getSimulationRequests(true, action[0].pageIndex, action[0].pageSize).pipe(
          map(
            response =>
              new AddSimulationRequestAction(
                response.data!!,
                {
                  totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                } as HttpCommonHeaders,
                action[0].pageSize,
                action[0].pageIndex,
                null
              )
          ),
          catchError(errorMessage => of(new AddSimulationRequestActionError(null, errorMessage)))
        );
      })
    )
  );
}
