import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetSimulationResultDto } from '../dtos/simulation-result/getSimulationResultDto';
import { GetSimulationResultPreviewDto } from '../dtos/simulation-result/getSimulationResultPreviewDto';
import { GetSimulationSetDto } from '../dtos/simulation-set/getSimulationSetDto';
import { EnergyDatas } from '../models/response-models/energyDatas';
import { FeederDatas } from '../models/response-models/feederDatas';
import { FeederSummaryDatas } from '../models/response-models/feederSummaryDatas';
import { GeneralDatas } from '../models/response-models/generalDatas';
import { Jumper } from '../models/response-models/jumper';
import { Operation } from '../models/response-models/operation';
import { PssSummaryDatas } from '../models/response-models/pssSummaryDatas';
import { Train } from '../models/response-models/train';
import { TransformersDatas } from '../models/response-models/transformersDatas';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class SimulationResultService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getSimulationResultsByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<GetSimulationResultDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationResultDto[]>>(`${this._configService.getBaseUrl()}/simulation-results`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationResultDto[]>
        )
      );
  }

  getSimulationResultPreviewDatasBySimulationIds(
    simulationIds: number[],
    pagination: boolean = false,
    pageIndex: number = 0,
    pageSize: number = 25
  ): Observable<ResponseModel<GetSimulationResultPreviewDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationResultPreviewDto[]>>(`${this._configService.getBaseUrl()}/simulation-results`, {
        params: {
          'simulation-id': simulationIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationResultPreviewDto[]>
        )
      );
  }

  getSimulationResultPreviewDatasBySimulationSetIds(
    simulationSetIds: number[],
    pagination: boolean = false,
    pageIndex: number = 0,
    pageSize: number = 25
  ): Observable<ResponseModel<GetSimulationResultPreviewDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationResultPreviewDto[]>>(`${this._configService.getBaseUrl()}/simulation-results/preview`, {
        params: {
          'simulation-set-id': simulationSetIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationResultPreviewDto[]>
        )
      );
  }

  getSimulationResultPreviewDatasByIds(
    simulationResultIds: number[],
    pagination: boolean = false,
    pageIndex: number = 0,
    pageSize: number = 25
  ): Observable<ResponseModel<GetSimulationResultPreviewDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationResultPreviewDto[]>>(`${this._configService.getBaseUrl()}/simulation-results/preview`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationResultPreviewDto[]>
        )
      );
  }

  getSimulationResultGeneralDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<GeneralDatas[]>> {
    return this._httpClient
      .get<ResponseModel<GeneralDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/general`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GeneralDatas[]>
        )
      );
  }

  getSimulationResultEnergyDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<EnergyDatas[]>> {
    return this._httpClient
      .get<ResponseModel<EnergyDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/energy`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<EnergyDatas[]>
        )
      );
  }

  getSimulationResultPssSummaryDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<PssSummaryDatas[]>> {
    return this._httpClient
      .get<ResponseModel<PssSummaryDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/pss-summary`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<PssSummaryDatas[]>
        )
      );
  }

  getSimulationResultTransformerDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<TransformersDatas[]>> {
    return this._httpClient
      .get<ResponseModel<TransformersDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/transformers`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<TransformersDatas[]>
        )
      );
  }

  getSimulationResultFeedersDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<FeederDatas[]>> {
    return this._httpClient
      .get<ResponseModel<FeederDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/feeders`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<FeederDatas[]>
        )
      );
  }

  getSimulationResultFeedersSummaryDatasByIds(
    simulationResultIds: number[],
    pagination: boolean = false,
    pageIndex: number = 0,
    pageSize: number = 25
  ): Observable<ResponseModel<FeederSummaryDatas[]>> {
    return this._httpClient
      .get<ResponseModel<FeederSummaryDatas[]>>(`${this._configService.getBaseUrl()}/simulation-results/feeder-summary`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<FeederSummaryDatas[]>
        )
      );
  }

  getSimulationResultLineProfileData(simulationResultUUId: string, lineUUId: string, type: string): Observable<ResponseModel<GetSimulationSetDto[]>> {
    return this._httpClient.get<ResponseModel<GetSimulationSetDto[]>>(`${this._configService.getBaseUrl()}/simulation-results/${simulationResultUUId}/line-profiles/${lineUUId}`, { params: { type } });
  }

  getSimulationResultJumpersDatasByIds(simulationResultIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<Jumper[]>> {
    return this._httpClient
      .get<ResponseModel<Jumper[]>>(`${this._configService.getBaseUrl()}/simulation-results/jumpers`, {
        params: {
          'simulation-result-id': simulationResultIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<Jumper[]>
        )
      );
  }

  getSimulationResultOperationDatasByIds(simulationIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<Operation[]>> {
    return this._httpClient
      .get<ResponseModel<Operation[]>>(`${this._configService.getBaseUrl()}/simulation-results/operations`, {
        params: {
          'simulation-result-id': simulationIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<Operation[]>
        )
      );
  }

  getSimulationResultTrainsDatasByIds(simulationIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<Train[]>> {
    return this._httpClient
      .get<ResponseModel<Train[]>>(`${this._configService.getBaseUrl()}/simulation-results/trains`, {
        params: {
          'simulation-result-id': simulationIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<Train[]>
        )
      );
  }

  filterSimulationResults(query?: string): Observable<GetSimulationResultDto[]> {
    return this._httpClient.get<GetSimulationResultDto[]>(`${this._configService.getBaseUrl()}/simulation-results/filter?${query}`);
  }
}
