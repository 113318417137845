import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ResponseModel } from '../dtos/http/responseModel';
import { SimulationReportHistory } from '../models/response-models/simulationReportHistory';
import { GetSimulationReportDto } from '../dtos/report/getSimulationReportDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  addReport(lang: string, body: any): Observable<ResponseModel<GetSimulationReportDto[]>> {
    //TODO Add { reportProgress: true, observe: "events" } for listening response and show percentage of process
    return this._httpClient.post<ResponseModel<GetSimulationReportDto[]>>(`${this._configService.getBaseUrl()}/reports`, body, { headers: { 'Accept-Language': lang } });
  }

  getReports(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetSimulationReportDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationReportDto[]>>(`${this._configService.getBaseUrl()}/reports`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationReportDto[]>
        )
      );
  }

  getReportHistory(parentReportId: number): Observable<ResponseModel<SimulationReportHistory>> {
    return this._httpClient.get<ResponseModel<SimulationReportHistory>>(`${this._configService.getBaseUrl()}/reports/${parentReportId}`);
  }

  downloadReport(id: number) {
    return this._httpClient.get<object>(`${this._configService.getBaseUrl()}/reports/${id}/download`, { responseType: 'blob' as 'json' });
  }

  getReportFileParents(): Observable<ResponseModel<GetSimulationReportDto[]>> {
    return this._httpClient.get<ResponseModel<GetSimulationReportDto[]>>(`${this._configService.getBaseUrl()}/reports/parents`);
  }

  removeReport(id: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/reports/${id}`);
  }

  bulkRemoveReport(ids: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/reports`, { params: { 'simulation-report-id': ids } });
  }

  uploadReport(report: FormData): Observable<ResponseModel<GetSimulationReportDto>> {
    return this._httpClient.post<ResponseModel<GetSimulationReportDto>>(`${this._configService.getBaseUrl()}/reports/upload`, report);
  }

  filterReports(query?: string): Observable<GetSimulationReportDto[]> {
    return this._httpClient.get<GetSimulationReportDto[]>(`${this._configService.getBaseUrl()}/reports/filter?${query}`);
  }
}
