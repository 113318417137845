import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { RailService } from 'src/app/services/rail.service';
import { AddRailAction, AddRailActionError, LoadRailDatas, RailActionTypes } from '../actions/rail.action';

@Injectable()
export class RailEffects {
  constructor(
    private actions$: Actions,
    private readonly _railService: RailService,
    private readonly _store: Store<AppState>
  ) {}

  loadRails$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadRailDatas>(RailActionTypes.LOAD_RAIL),
      withLatestFrom(this._store.select(state => state.railState.rails)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize || hasLoaded.query != action.query))),
      switchMap(action => {
        return !action[0].query
          ? this._railService.getRails(true, action[0].pageIndex, action[0].pageSize).pipe(
              map(
                response =>
                  new AddRailAction(
                    response.data!!,
                    {
                      totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                    } as HttpCommonHeaders,
                    action[0].pageSize,
                    action[0].pageIndex,
                    null
                  )
              ),
              catchError(errorMessage => of(new AddRailActionError(null, errorMessage)))
            )
          : this._railService.filterRails(action[0].query).pipe(
              map(response => new AddRailAction(response!!, { totalCount: 10 } as HttpCommonHeaders, action[0].pageSize, action[0].pageIndex, null)),
              catchError(errorMessage => of(new AddRailActionError(null, errorMessage)))
            );
      })
    )
  );
}
