import { AppState, LocationState } from 'src/app/models/state/appState';
import { LocationAction, LocationActionTypes } from '../actions/location.action';

const initialLocationState: LocationState = {
  isLocationsLoaded: false,
};

export function LocationReducer(state: LocationState = initialLocationState, action: LocationAction | any): any {
  switch (action.type) {
    case LocationActionTypes.ADD_LOCATION:
      return {
        ...state,
        locations: action.payload,
        locationError: null,
      };
    case LocationActionTypes.ADD_LOCATION_ERROR:
      return {
        ...state,
        locations: null,
        locationError: action.error,
      };
    case LocationActionTypes.LOAD_LOCATION:
      return {
        ...state,
        isLocationsLoaded: true,
        locationError: null,
      };
    default:
      return state;
  }
}
