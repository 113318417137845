import { SimulationReportState } from 'src/app/models/state/appState';
import { SimulationReportAction, SimulationReportActionTypes } from '../actions/report.action';

const initialSimulationReportState: SimulationReportState = {
  isSimulationReportsLoaded: false,
};

export function SimulationReportReducer(state: SimulationReportState = initialSimulationReportState, action: SimulationReportAction | any): any {
  switch (action.type) {
    case SimulationReportActionTypes.ADD_SIMULATION_REPORT:
      return {
        ...state,
        simulationReports: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        simulationReportError: null,
      };
    case SimulationReportActionTypes.ADD_SIMULATION_REPORT_ERROR:
      return {
        ...state,
        simulationReports: null,
        simulationReportError: action.error,
      };
    case SimulationReportActionTypes.LOAD_SIMULATION_REPORT:
      return {
        ...state,
        isSimulationReportsLoaded: true,
        simulationReportError: null,
      };
    case SimulationReportActionTypes.CLEAR_SIMULATION_REPORT:
      return {
        ...state,
        simulationReports: null,
        simulationReportError: null,
        isSimulationReportsLoaded: false,
      };
    default:
      return state;
  }
}
