import { Action } from '@ngrx/store';
import { Scope } from 'src/app/models/response-models/scope';

export enum DataLogActionTypes {
  ADD_DATA_LOG = '[DataLog] Add DataLog',
  ADD_DATA_LOG_ERROR = '[DataLog] Add DataLog Error',
  LOAD_DATA_LOG = '[DataLog] Load DataLog',
}

export class AddDataLogAction implements Action {
  readonly type = DataLogActionTypes.ADD_DATA_LOG;

  constructor(
    public payload: Scope,
    public error: null
  ) {}
}

export class AddDataLogActionError implements Action {
  readonly type = DataLogActionTypes.ADD_DATA_LOG_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadDataLogDatas implements Action {
  readonly type = DataLogActionTypes.LOAD_DATA_LOG;

  constructor() {}
}

export type DataLogAction = LoadDataLogDatas | AddDataLogAction | AddDataLogActionError;
