import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetTokenDto } from '../dtos/auth/getTokenDto';
import { ResponseModel } from '../dtos/http/responseModel';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  login(data: any): Observable<ResponseModel<GetTokenDto>> {
    return this._httpClient.post<ResponseModel<GetTokenDto>>(`${this._configService.getBaseIdentityUrl()}/auth/login`, data);
  }

  logout(): Observable<ResponseModel> {
    return this._httpClient.get<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/auth/logout`);
  }

  isAuthenticated(): Observable<ResponseModel> {
    return this._httpClient.get<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/auth/is-authenticated`);
  }
}
