import { Action } from '@ngrx/store';
import { GetLogRequestDto } from 'src/app/dtos/log-request/getLogRequestDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum LogRequestActionTypes {
  ADD_LOG_REQUEST = '[LogRequest] Add Log Request',
  ADD_LOG_REQUEST_ERROR = '[LogRequest] Add Log Request Error',
  LOAD_LOG_REQUEST = '[LogRequest] Load Log Request',
  CLEAR_LOG_REQUEST = '[LogRequest] Clear Log Request',
}

export class AddLogRequestAction implements Action {
  readonly type = LogRequestActionTypes.ADD_LOG_REQUEST;

  constructor(
    public payload: GetLogRequestDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddLogRequestActionError implements Action {
  readonly type = LogRequestActionTypes.ADD_LOG_REQUEST_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadLogRequestDatas implements Action {
  readonly type = LogRequestActionTypes.LOAD_LOG_REQUEST;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearLogRequestDatas implements Action {
  readonly type = LogRequestActionTypes.CLEAR_LOG_REQUEST;

  constructor() {}
}

export type LogRequestAction = LoadLogRequestDatas | AddLogRequestAction | AddLogRequestActionError | ClearLogRequestDatas;
