import { Action } from '@ngrx/store';
import { GetCableDto } from 'src/app/dtos/cable/getCableDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum CableActionTypes {
  ADD_CABLE = '[Cable] Add Cable',
  ADD_CABLE_ERROR = '[Cable] Add Cable Error',
  LOAD_CABLE = '[Cable] Load Cable',
  CLEAR_CABLE = '[Cable] Clear Cable',
}

export class AddCableAction implements Action {
  readonly type = CableActionTypes.ADD_CABLE;

  constructor(
    public payload: GetCableDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddCableActionError implements Action {
  readonly type = CableActionTypes.ADD_CABLE_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadCableDatas implements Action {
  readonly type = CableActionTypes.LOAD_CABLE;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0,
    public readonly query: string = ''
  ) {}
}

export class ClearCableDatas implements Action {
  readonly type = CableActionTypes.CLEAR_CABLE;

  constructor() {}
}

export type CableAction = LoadCableDatas | AddCableAction | AddCableActionError | ClearCableDatas;
