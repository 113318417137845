import { MouseEvents, Plugins, VisibilityModes } from '../enums';
import { DrawingItem, VisibilityModeType } from '../types';

/* 
  Text mode types
*/
export type TextModeType = 'text' | 'arrow_mark_left' | 'arrow_mark_right' | 'arrow_mark_upward' | 'arrow_mark_downward';

/* 
  Text modes list
*/
export enum TextModes {
  Text = 'text',
  ArrowMarkLeft = 'arrow_mark_left',
  ArrowMarkRight = 'arrow_mark_right',
  ArrowMarkUpward = 'arrow_mark_upward',
  ArrowMarkDownward = 'arrow_mark_downward',
}

/* 
  Text Arrow constraints
*/
export const textArrowInitialState = {
  backgroundColor: 'rgba(41, 98, 255, 1)',
  borderColor: 'rgba(41, 98, 255, 1)',
  borderWidth: 1,
  size: 20,
  font: 'Arial',
  color: 'rgba(41, 98, 255, 1)',
  text: 'Text',
  pixel: '20px',
};

/* 
  Text inital state
*/
export const textInitialState = {
  id: 1,
  xPoint: 0,
  yPoint: 0,
  font: 'Arial',
  color: 'rgba(41, 98, 255, 1)',
  text: 'Text',
  pixel: '20px',
  isFocused: false,
  type: TextModes.Text,
};

/* 
  Focusing feature constraints
*/
const focusInitialState = {
  strokeStyle: 'blue',
  lineWidth: 1,
  fillStyle: 'white',
  radius: 6,
};

/* 
  Default text options
*/
export const defaultTextOptions = {
  text: {
    enabled: false,
    width: 1,
    color: '#96989F',
    tooltip: {
      htmlELement: null,
      drawingId: null,
    },
  },
};

/* 
  Base Text type
*/
type BaseTextType = DrawingItem & {
  type: TextModeType;
  isItalic: boolean;
  isBold: boolean;
};

/* 
  Text type
*/
type TextType = BaseTextType & {
  xPoint: number;
  yPoint: number;
  font?: string;
  color?: string;
  text?: string;
  pixel?: string;
};

/* 
  Arrow with Text type
*/
type ArrowTextType = BaseTextType & {
  xPoint: number;
  yPoint: number;
  font?: string;
  color?: string;
  text?: string;
  pixel?: string;
};

/* 
  Text plugin
*/
export const textPlugin: any = {
  id: Plugins.Text,
  defaults: {
    ...defaultTextOptions.text,
  },
  afterEvent: (chart: { draw: () => void; config: { options: { plugins: any } } }, args: { event?: any }, opts: { width: any; color: any }) => {
    let options = chart.config.options.plugins[Plugins.Text];
    if (!options.enabled) return;

    const { type, x, y } = args.event;

    let textCreator = new TextCreator(chart, options).createText(options.type);
    textCreator.handlePoint(x, y, type);

    chart.draw();
  },
  afterDraw: (
    chart: {
      ctx?: any;
      update: () => void;
      destroy: () => void;
      config: { options: { plugins: any } };
    },
    args: any,
    opts: { width: any; color: any }
  ) => {
    let options = chart.config.options.plugins[Plugins.Text];

    if (options.history.length === 0) return; //Draw history
    (options.history as BaseTextType[]).forEach(historyPoint => {
      let textCreator = new TextCreator(chart, options).createText(historyPoint.type);

      if (!historyPoint.isVisible || historyPoint.isDeleted || historyPoint.plugin != Plugins.Text) return;

      if (historyPoint.isFocused) {
        textCreator.drawText(historyPoint);
        textCreator.focusShape(historyPoint);
      } else {
        textCreator.drawText(historyPoint);
      }
    });
  },
};

interface IText {
  getLatestPoint<TType>(): TType;
  addPoint(): BaseTextType;
  handlePoint(x: number, y: number, type: MouseEvents): void;
  drawText(point: BaseTextType): void;
  checkPointValidity(historyPoint: BaseTextType, point: BaseTextType): boolean;
  focusShape(point: BaseTextType): void;
  setTooltipVisibility(visibility: VisibilityModeType): void;
  setTooltipDrawing(point: BaseTextType): void;
}

abstract class BaseText implements IText {
  chart: any;
  ctx: any;
  opts: any;

  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.ctx = chart.ctx;
    this.opts = opts;
  }

  getLatestPoint = <TType>(): TType => this.opts.history.slice(-1).pop();

  abstract addPoint(): BaseTextType;

  abstract handlePoint(x: number, y: number, type: MouseEvents): void;

  abstract drawText(point: BaseTextType): void;

  abstract checkPointValidity(historyPoint: BaseTextType, point: BaseTextType): boolean;

  abstract focusShape(point: BaseTextType): void;

  setTooltipVisibility(visibility: VisibilityModeType): void {
    this.opts.tooltip.htmlElement.nativeElement.style.visibility = visibility;
  }

  setTooltipDrawing(point: BaseTextType): void {
    this.opts.tooltip.drawingId = point.id;
  }
}

class Text extends BaseText {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = textInitialState.color;
    this.ctx.strokeStyle = textInitialState.color;
    this.ctx.lineWidth = textInitialState.pixel;
  }

  addPoint = (): TextType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Text,
      type: TextModes.Text,
      isVisible: true,
      isFocused: true,
      font: textInitialState.font,
      color: textInitialState.color,
      text: textInitialState.text,
      pixel: textInitialState.pixel,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseTextType>()?.type != TextModes.Text || !this.getLatestPoint<TextType>()) this.addPoint();

    if (type == MouseEvents.Click) {
      this.addPoint();
      let latestPoint = this.getLatestPoint<TextType>();
      latestPoint.xPoint = x;
      latestPoint.yPoint = y;
      (this.opts.history as TextType[]).forEach(p => (p.isFocused = false));
      latestPoint.isFocused = true;
      (this.opts.history as TextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + point.text?.length! * 10 && y <= point.yPoint + 10 && y >= point.yPoint - 10) {
          if (index === this.opts.history.length - 1) {
            this.setTooltipVisibility(VisibilityModes.Hidden);
          } else {
            this.setTooltipVisibility(VisibilityModes.Visible);
            this.setTooltipDrawing(point);
            point.isFocused = true;
            this.opts.history.pop();
          }
        }
      });
    } else if (type === MouseEvents.MouseMove) {
      (this.opts.history as TextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) point.isFocused = true;
      });
    }
  }

  checkPointValidity(historyPoint: TextType, point: TextType): boolean {
    return true;
  }

  drawText(point: TextType): void {
    let { text, pixel, xPoint, yPoint, isBold, isItalic, color } = point;

    this.ctx.save();
    this.ctx.beginPath();

    let fontString = `${pixel} ${text}`;
    if (isBold) fontString = 'bold ' + fontString;
    if (isItalic) fontString = 'italic ' + fontString;

    this.ctx.font = fontString;
    this.ctx.fillStyle = color;
    this.ctx.fillText(text, xPoint, yPoint);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: TextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(xPoint, yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowMarkLeft extends BaseText {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = textArrowInitialState.backgroundColor;
    this.ctx.strokeStyle = textArrowInitialState.borderColor;
    this.ctx.lineWidth = textArrowInitialState.borderWidth;
  }

  addPoint = (): ArrowTextType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Text,
      type: TextModes.ArrowMarkLeft,
      isVisible: true,
      isFocused: true,
      font: textArrowInitialState.font,
      color: textArrowInitialState.color,
      text: textArrowInitialState.text,
      pixel: textArrowInitialState.pixel,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseTextType>()?.type != TextModes.ArrowMarkLeft || !this.getLatestPoint<ArrowTextType>()) this.addPoint();

    if (type == MouseEvents.Click) {
      this.addPoint();
      let latestPoint = this.getLatestPoint<ArrowTextType>();
      latestPoint.xPoint = x;
      latestPoint.yPoint = y;
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) {
          if (index === this.opts.history.length - 1) {
            this.setTooltipVisibility(VisibilityModes.Hidden);
          } else {
            this.setTooltipVisibility(VisibilityModes.Visible);
            this.setTooltipDrawing(point);
            point.isFocused = true;
            this.opts.history.pop();
          }
        }
      });
    } else if (type === MouseEvents.MouseMove) {
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) point.isFocused = true;
        else point.isFocused = false;
      });
    }
  }

  checkPointValidity(historyPoint: ArrowTextType, point: ArrowTextType): boolean {
    return true;
  }

  drawText(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint + textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + textArrowInitialState.size * 2, yPoint + textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + textArrowInitialState.size * 2, yPoint);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint - textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + textArrowInitialState.size * 2, yPoint - textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + textArrowInitialState.size * 2, yPoint);
    this.ctx.fill();

    this.ctx.font = `${pixel} ${text}`;
    this.ctx.fillText(text, xPoint + 45, yPoint);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(xPoint, yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowMarkRight extends BaseText {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = textArrowInitialState.backgroundColor;
    this.ctx.strokeStyle = textArrowInitialState.borderColor;
    this.ctx.lineWidth = textArrowInitialState.borderWidth;
  }

  addPoint = (): ArrowTextType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Text,
      type: TextModes.ArrowMarkRight,
      isVisible: true,
      isFocused: true,
      font: textArrowInitialState.font,
      color: textArrowInitialState.color,
      text: textArrowInitialState.text,
      pixel: textArrowInitialState.pixel,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseTextType>()?.type != TextModes.ArrowMarkRight || !this.getLatestPoint<ArrowTextType>()) this.addPoint();

    if (type == MouseEvents.Click) {
      this.addPoint();
      let latestPoint = this.getLatestPoint<ArrowTextType>();
      latestPoint.xPoint = x;
      latestPoint.yPoint = y;
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) {
          if (index === this.opts.history.length - 1) {
            this.setTooltipVisibility(VisibilityModes.Hidden);
          } else {
            this.setTooltipVisibility(VisibilityModes.Visible);
            this.setTooltipDrawing(point);
            point.isFocused = true;
            this.opts.history.pop();
          }
        }
      });
    } else if (type === MouseEvents.MouseMove) {
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) point.isFocused = true;
        else point.isFocused = false;
      });
    }
  }

  checkPointValidity(historyPoint: ArrowTextType, point: ArrowTextType): boolean {
    return true;
  }

  drawText(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint + textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - textArrowInitialState.size * 2, yPoint + textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - textArrowInitialState.size * 2, yPoint);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint - textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - textArrowInitialState.size * 2, yPoint - textArrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - textArrowInitialState.size * 2, yPoint);
    this.ctx.fill();

    this.ctx.font = `${pixel} ${text}`;
    this.ctx.fillText(text, xPoint - 80, yPoint);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(xPoint, yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowMarkUpward extends BaseText {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = textArrowInitialState.backgroundColor;
    this.ctx.strokeStyle = textArrowInitialState.borderColor;
    this.ctx.lineWidth = textArrowInitialState.borderWidth;
  }

  addPoint = (): ArrowTextType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Text,
      type: TextModes.ArrowMarkUpward,
      isVisible: true,
      isFocused: true,
      font: textArrowInitialState.font,
      color: textArrowInitialState.color,
      text: textArrowInitialState.text,
      pixel: textArrowInitialState.pixel,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseTextType>()?.type != TextModes.ArrowMarkUpward || !this.getLatestPoint<ArrowTextType>()) this.addPoint();

    if (type == MouseEvents.Click) {
      this.addPoint();
      let latestPoint = this.getLatestPoint<ArrowTextType>();
      latestPoint.xPoint = x;
      latestPoint.yPoint = y;
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) {
          if (index === this.opts.history.length - 1) {
            this.setTooltipVisibility(VisibilityModes.Hidden);
          } else {
            this.setTooltipVisibility(VisibilityModes.Visible);
            this.setTooltipDrawing(point);
            point.isFocused = true;
            this.opts.history.pop();
          }
        }
      });
    } else if (type === MouseEvents.MouseMove) {
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) point.isFocused = true;
        else point.isFocused = false;
      });
    }
  }

  checkPointValidity(historyPoint: ArrowTextType, point: ArrowTextType): boolean {
    return true;
  }

  drawText(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size / 2, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size / 2, yPoint + textArrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint + textArrowInitialState.size * 2);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size / 2, yPoint + textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size / 2, yPoint + textArrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint + textArrowInitialState.size * 2);
    this.ctx.fill();

    this.ctx.font = `${pixel} ${text}`;
    this.ctx.fillText(text, xPoint - 18, point.yPoint + 55);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(xPoint, yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowMarkDownward extends BaseText {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = textArrowInitialState.backgroundColor;
    this.ctx.strokeStyle = textArrowInitialState.borderColor;
    this.ctx.lineWidth = textArrowInitialState.borderWidth;
  }

  addPoint = (): ArrowTextType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Text,
      type: TextModes.ArrowMarkDownward,
      isVisible: true,
      isFocused: true,
      font: textArrowInitialState.font,
      color: textArrowInitialState.color,
      text: textArrowInitialState.text,
      pixel: textArrowInitialState.pixel,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseTextType>()?.type != TextModes.ArrowMarkDownward || !this.getLatestPoint<ArrowTextType>()) this.addPoint();

    if (type == MouseEvents.Click) {
      this.addPoint();
      let latestPoint = this.getLatestPoint<ArrowTextType>();
      latestPoint.xPoint = x;
      latestPoint.yPoint = y;
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) {
          if (index === this.opts.history.length - 1) {
            this.setTooltipVisibility(VisibilityModes.Hidden);
          } else {
            this.setTooltipVisibility(VisibilityModes.Visible);
            this.setTooltipDrawing(point);
            point.isFocused = true;
            this.opts.history.pop();
          }
        }
      });
    } else if (type === MouseEvents.MouseMove) {
      (this.opts.history as ArrowTextType[]).forEach((point, index) => {
        if (x >= point.xPoint && x <= point.xPoint + 40 && y <= point.yPoint + 10 && y >= point.yPoint - 10) point.isFocused = true;
        else point.isFocused = false;
      });
    }
  }

  checkPointValidity(historyPoint: ArrowTextType, point: ArrowTextType): boolean {
    return true;
  }

  drawText(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - textArrowInitialState.size, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size / 2, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint - textArrowInitialState.size / 2, yPoint - textArrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint - textArrowInitialState.size * 2);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + textArrowInitialState.size, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size / 2, yPoint - textArrowInitialState.size);
    this.ctx.lineTo(xPoint + textArrowInitialState.size / 2, yPoint - textArrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint - textArrowInitialState.size * 2);
    this.ctx.fill();

    this.ctx.font = `${pixel} ${text}`;
    this.ctx.fillText(text, xPoint - 20, yPoint - 50);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowTextType): void {
    let { text, pixel, xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(xPoint, yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class TextCreator {
  chart: any;
  opts!: any;
  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.opts = opts;
  }

  createText(textMode: TextModeType): IText {
    if (textMode === TextModes.Text) {
      return new Text(this.chart, this.opts);
    } else if (textMode === TextModes.ArrowMarkLeft) {
      return new ArrowMarkLeft(this.chart, this.opts);
    } else if (textMode === TextModes.ArrowMarkRight) {
      return new ArrowMarkRight(this.chart, this.opts);
    } else if (textMode === TextModes.ArrowMarkUpward) {
      return new ArrowMarkUpward(this.chart, this.opts);
    } else if (textMode === TextModes.ArrowMarkDownward) {
      return new ArrowMarkDownward(this.chart, this.opts);
    } else {
      return new Text(this.chart, this.opts);
    }
  }
}
