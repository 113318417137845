import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformerName',
})
export class TransformerNamePipe implements PipeTransform {
  transform(value: any, transformerValues: any, transformerNames: any): string {
    if (transformerValues) {
      let indexOfValue = transformerValues.indexOf(Math.max(...transformerValues));
      let transformerName = transformerNames[indexOfValue];
      return transformerName;
    }
    return '';
  }
}
