export const Claims = {
  TENANT_MANAGEMENT_WRITE: 'Administrator.TenantManagementWrite',
  TENANT_MANAGEMENT_READ: 'Administrator.TenantManagementRead',
  USER_MANAGEMENT_WRITE: 'Administrator.UserManagementWrite',
  USER_MANAGEMENT_READ: 'Administrator.UserManagementRead',
  ROLE_MANAGEMENT_WRITE: 'Administrator.RoleManagementWrite',
  ROLE_MANAGEMENT_READ: 'Administrator.RoleManagementRead',
  SIMULATION_WRITE: 'HiSimuX.SimulationWrite',
  SIMULATION_RUN: 'HiSimuX.SimulationRun',
  SIMULATION_READ: 'HiSimuX.SimulationRead',
  SIMULATION_RESULT_READ: 'HiSimuX.SimulationResultRead',
  DATABASE_WRITE: 'HiSimuX.DatabaseWrite',
  MENU_DISPLAY_LEVEL_EDIT: 'HiSimuX.MenuDisplayLevelEdit',
};
