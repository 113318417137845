<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ simulationResult?.simulation?.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.SIMULATION_TIME' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.simulationTime | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.TITLE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.simulation?.title!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.ENERGY_WITH_DRAWN' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.totalEnergy!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MIN_TRAIN_VOLTAGE_V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.minTrainVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MIN_RAIL_VOLTAGE_V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.minRailVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_RAIL_VOLTAGE_V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.maxRailVoltage!! | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_RMS_POWER_KW' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.maxRMSPower!! | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_POWER_Kw' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.maxPower!! | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.TOTAL_VEHICLE_Km' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.totalVehicleKilometers!! | convertToKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.ENERGY_PER_VEHICLE_PER_KILOMETER' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.energyPerVehiclePerKilometer!! | convertToKwhVhcKm | getWithTwoFloatingPoints }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_TIME_RAIL_VOLTAGE_OVER_120V' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.railVoltageThresholds!! | calculateThreshold: simulationResult?.maxTimeOverThresholds : 120 }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_TIME_RAIL_VOLTAGE_OVER_150V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.railVoltageThresholds!! | calculateThreshold: simulationResult?.maxTimeOverThresholds : 150 }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MAX_TIME_RAIL_VOLTAGE_OVER_160V' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.railVoltageThresholds!! | calculateThreshold: simulationResult?.maxTimeOverThresholds : 160 }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'GENERAL_PREVIEW.MEAN_USEFUL_VOLTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.meanUsefulVoltage!! | trunc }}
      </mat-card>
    </div>
  </div>
</div>
