import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDate',
})
export class ConvertDatePipe implements PipeTransform {
  transform(value: any): any {
    return new Date(value).toLocaleString('tr-TR');
  }
}
