import { SimulationRequestState } from 'src/app/models/state/appState';
import { SimulationRequestAction, SimulationRequestActionTypes } from '../actions/simulation-request.action';

const initialSimulationRequestState: SimulationRequestState = {
  isSimulationRequestsLoaded: false,
};

export function SimulationRequestReducer(state: SimulationRequestState = initialSimulationRequestState, action: SimulationRequestAction | any): any {
  switch (action.type) {
    case SimulationRequestActionTypes.ADD_SIMULATION_REQUEST:
      return {
        ...state,
        simulationRequests: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        simulationRequestError: null,
      };
    case SimulationRequestActionTypes.ADD_SIMULATION_REQUEST_ERROR:
      return {
        ...state,
        simulationRequests: null,
        simulationRequestError: action.error,
      };
    case SimulationRequestActionTypes.LOAD_SIMULATION_REQUEST:
      return {
        ...state,
        isSimulationRequestsLoaded: true,
        simulationRequestError: null,
      };
    case SimulationRequestActionTypes.CLEAR_SIMULATION_REQUEST:
      return {
        ...initialSimulationRequestState,
      };
    default:
      return state;
  }
}
