import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetLocationDto } from '../dtos/location/getLocationDto';
import { map } from 'rxjs/operators';
import { UpdateLocationDto } from '../dtos/location/updateLocationDto';
import { AddLocationDto } from '../dtos/location/addLocationDto';
import { GetRailwayLineDto } from '../dtos/railway-line/getRailwayLineDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getLocations(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetLocationDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetLocationDto[]>>(`${this._configService.getBaseUrl()}/locations`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetLocationDto[]>
        )
      );
  }

  getRailwayLinesByLocationId(locationId: number, pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetRailwayLineDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetRailwayLineDto[]>>(`${this._configService.getBaseUrl()}/locations/${locationId}/railways`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetRailwayLineDto[]>
        )
      );
  }

  getLocationById(id: number): Observable<ResponseModel<GetLocationDto>> {
    return this._httpClient.get<ResponseModel<GetLocationDto>>(`${this._configService.getBaseUrl()}/locations/${id}`);
  }

  addLocation(location: AddLocationDto): Observable<ResponseModel<GetLocationDto>> {
    return this._httpClient.post<ResponseModel<GetLocationDto>>(`${this._configService.getBaseUrl()}/locations`, location);
  }

  deleteLocation(locationId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/locations/${locationId}`);
  }

  updateLocation(id: number, location: UpdateLocationDto): Observable<ResponseModel<GetLocationDto>> {
    return this._httpClient.put<ResponseModel<GetLocationDto>>(`${this._configService.getBaseUrl()}/locations/${id}`, location);
  }

  filterLocations(query?: string): Observable<GetLocationDto[]> {
    return this._httpClient.get<GetLocationDto[]>(`${this._configService.getBaseUrl()}/locations/filter?${query}`);
  }
}
