import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddUsersDto } from '../dtos/users/addUsersDto';
import { ForgetPasswordDto } from '../dtos/users/forgetPasswordDto';
import { GetUserDto } from '../dtos/users/getUserDto';
import { UpdatePasswordDto } from '../dtos/users/updatePasswordDto';
import { UpdateUserDto } from '../dtos/users/updateUserDto';
import { VerifyMailDto } from '../dtos/users/verifyMailDto';
import { ResponseModel } from '../dtos/http/responseModel';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getProfile(): Observable<ResponseModel<GetUserDto>> {
    return this._httpClient.get<ResponseModel<GetUserDto>>(`${this._configService.getBaseIdentityUrl()}/users/profile`);
  }

  getUser(id: string): Observable<ResponseModel<GetUserDto>> {
    return this._httpClient.get<ResponseModel<GetUserDto>>(`${this._configService.getBaseIdentityUrl()}/users/${id}`);
  }

  getUsers(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetUserDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetUserDto[]>>(`${this._configService.getBaseIdentityUrl()}/users`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetUserDto[]>
        )
      );
  }

  addUsers(addUsersDto: AddUsersDto): Observable<ResponseModel<GetUserDto>> {
    return this._httpClient.post<ResponseModel<GetUserDto>>(`${this._configService.getBaseIdentityUrl()}/users/`, addUsersDto);
  }

  updateProfile(profile: UpdateUserDto): Observable<ResponseModel<GetUserDto>> {
    return this._httpClient.patch<ResponseModel<GetUserDto>>(`${this._configService.getBaseIdentityUrl()}/users/`, profile);
  }

  forgetPassword(email: ForgetPasswordDto): Observable<ResponseModel> {
    return this._httpClient.post<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/users/forget-password`, email);
  }

  updatePassword(updatePassword: UpdatePasswordDto): Observable<ResponseModel> {
    return this._httpClient.put<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/users/password`, updatePassword);
  }

  verifyMail(verifyMailDto: VerifyMailDto) {
    return this._httpClient.post<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/users/mail-verification`, verifyMailDto);
  }

  deleteUser(id: number) {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/users/${id}`);
  }

  bulkDelete(ids: number[]) {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/users`, { params: { ids } });
  }
}
