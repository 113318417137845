import { RailState } from 'src/app/models/state/appState';
import { RailAction, RailActionTypes } from '../actions/rail.action';

const initialRailState: RailState = {
  isRailsLoaded: false,
};

export function RailReducer(state: RailState = initialRailState, action: RailAction | any): any {
  switch (action.type) {
    case RailActionTypes.ADD_RAIL:
      return {
        ...state,
        rails: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        railError: null,
      };
    case RailActionTypes.ADD_RAIL_ERROR:
      return {
        ...state,
        rails: null,
        railError: action.error,
      };
    case RailActionTypes.LOAD_RAIL:
      return {
        ...state,
        isRailsLoaded: true,
        railError: null,
      };
    case RailActionTypes.CLEAR_RAIL:
      return {
        ...initialRailState,
      };
    default:
      return state;
  }
}
