import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleLetter',
})
export class TitleLetterPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return value;

    return value[0].toUpperCase();
  }
}
