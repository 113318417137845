import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, filter, switchMap, map, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { AddSimulationReportTemplateAction, AddSimulationReportTemplateActionError, LoadSimulationReportTemplateDatas, SimulationReportTemplateActionTypes } from '../actions/report-template.action';

@Injectable()
export class SimulationReportTemplateEffects {
  constructor(
    private actions$: Actions,
    private readonly _reportTemplateService: ReportTemplateService,
    private readonly _store: Store<AppState>
  ) {}

  loadReportTemplates$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadSimulationReportTemplateDatas>(SimulationReportTemplateActionTypes.LOAD_SIMULATION_REPORT_TEMPLATE),
      withLatestFrom(this._store.select(state => state.railState.rails)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize))),
      switchMap(action => {
        return this._reportTemplateService.getReportTemplates(true, action[0].pageIndex, action[0].pageSize).pipe(
          map(
            response =>
              new AddSimulationReportTemplateAction(
                response.data!!,
                {
                  totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                } as HttpCommonHeaders,
                action[0].pageSize,
                action[0].pageIndex,
                null
              )
          ),
          catchError(errorMessage => of(new AddSimulationReportTemplateActionError(null, errorMessage)))
        );
      })
    )
  );
}
