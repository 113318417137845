import { LogRequestState } from 'src/app/models/state/appState';
import { LogRequestAction, LogRequestActionTypes } from '../actions/log-request.action';

const initialRailState: LogRequestState = {
  isLogRequestsLoaded: false,
};

export function LogRequestReducer(state: LogRequestState = initialRailState, action: LogRequestAction | any): any {
  switch (action.type) {
    case LogRequestActionTypes.ADD_LOG_REQUEST:
      return {
        ...state,
        logRequests: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        logRequestError: null,
      };
    case LogRequestActionTypes.ADD_LOG_REQUEST_ERROR:
      return {
        ...state,
        logRequests: null,
        logRequestError: action.error,
      };
    case LogRequestActionTypes.LOAD_LOG_REQUEST:
      return {
        ...state,
        isLogRequestsLoaded: true,
        logRequestError: null,
      };
    case LogRequestActionTypes.CLEAR_LOG_REQUEST:
      return {
        ...initialRailState,
      };
    default:
      return state;
  }
}
