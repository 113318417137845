import { MouseEvents, Plugins } from '../enums';
import { DrawingItem, UnitType } from '../types';

/* 
  Ruler mode types
*/
export type RulerModeType = 'x' | 'y' | 'xy';

/* 
  Ruler modes list
*/
export enum RulerModes {
  X = 'x',
  Y = 'y',
  XY = 'xy',
}

/* 
  Default ruler options
*/
export const defaultRulerOptions = {
  ruler: {
    enabled: false,
    width: 1,
    color: '#1092FF',
  },
};

/* 
  XRuler shape constraints
*/
const xRulerInitialState = {
  backgroundColor: 'rgba(41, 98, 255, 0.2)',
  borderColor: 'rgba(41, 98, 255, 1)',
  borderWidth: 2,
  infoBoxWidth: 180,
  infoBoxHeight: 60,
  infoBoxBackgroundColor: 'rgba(41, 98, 255, 1)',
  infoBoxTextColor: '#FFFFFF',
};

/* 
  YRuler shape constraints
*/
const yRulerInitialState = {
  backgroundColor: 'rgba(41, 98, 255, 0.2)',
  borderColor: 'rgba(41, 98, 255, 1)',
  borderWidth: 2,
  infoBoxWidth: 180,
  infoBoxHeight: 60,
  infoBoxBackgroundColor: 'rgba(41, 98, 255, 1)',
  infoBoxTextColor: '#FFFFFF',
};

/* 
  XYRuler shape constraints
*/
const xyRulerInitialState = {
  backgroundColor: 'rgba(41, 98, 255, 0.2)',
  borderColor: 'rgba(41, 98, 255, 1)',
  borderWidth: 2,
  infoBoxWidth: 180,
  infoBoxHeight: 60,
  infoBoxBackgroundColor: 'rgba(41, 98, 255, 1)',
  infoBoxTextColor: '#FFFFFF',
};

/* 
  Data point type
*/
type DataPoint = {
  x: number;
  y: number;
  xUnit: UnitType;
  yUnit: UnitType;
};

/* 
  Base Ruler type
*/
type BaseRulerType = DrawingItem & {
  type: RulerModeType;
  count: number;
  headlen: number;
  infoBoxFirstXPoint?: number;
  infoBoxFirstYPoint?: number;
  infoBoxWidth: number;
  infoBoxHeight: number;
};

/* 
  X Ruler type
*/
type XRulerType = BaseRulerType & {
  firstXPoint?: number;
  secondXPoint?: number;
  yPoint?: number;
  firstDataPoint?: DataPoint;
  secondDataPoint?: DataPoint;
};

/* 
  Y Ruler type
*/
type YRulerType = BaseRulerType & {
  firstYPoint?: number;
  secondYPoint?: number;
  xPoint?: number;
  firstDataPoint?: DataPoint;
  secondDataPoint?: DataPoint;
};

/* 
  XY Ruler type
*/
type XYRulerType = BaseRulerType & {
  firstXPoint?: number;
  firstYPoint?: number;
  secondXPoint?: number;
  secondYPoint?: number;
  firstDataPoint?: DataPoint;
  secondDataPoint?: DataPoint;
};

export const rulerPlugin: any = {
  id: Plugins.Ruler,
  defaults: {
    ...defaultRulerOptions.ruler,
  },
  afterEvent: (
    chart: {
      chartArea: any;
      draw: () => void;
      config: { options: { plugins: any } };
      tooltip: any;
      data: any;
      scales: any;
    },
    args: { event?: any },
    opts: { width: any; color: any }
  ) => {
    let options = chart.config.options.plugins[Plugins.Ruler];
    if (!options.enabled) return;

    const { type, x, y } = args.event;
    let rulerCreator = new RulerCreator(chart, options).createRuler(options.type);
    rulerCreator.handlePoint(x, y, type);

    chart.draw();
  },
  afterDraw: (
    chart: {
      ctx?: any;
      update: () => void;
      destroy: () => void;
      config: { options: { plugins: any } };
    },
    args: any,
    opts: { width: any; color: any }
  ) => {
    let options = chart.config.options.plugins[Plugins.Ruler];

    if (!options.ruler) return;

    let rulerCreator = new RulerCreator(chart, options).createRuler(options.type);
    rulerCreator.drawRuler();
  },
};

interface IRuler {
  addRuler(): BaseRulerType;
  getRulerPoint<TType>(): TType;
  handlePoint(x: number, y: number, type: MouseEvents): void;
  drawRuler(): void;
}

abstract class BaseRuler implements IRuler {
  chart: any;
  ctx: any;
  opts: any;

  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.ctx = chart.ctx;
    this.opts = opts;
  }

  getPointDataset(x: number, y: number): DataPoint {
    var yTop = this.chart.chartArea.top;
    var yBottom = this.chart.chartArea.bottom;
    var yMin = this.chart.scales['Train Voltage'].min;
    var yMax = this.chart.scales['Train Voltage'].max;
    var newY = 0;
    var showStuff = 0;
    if (y <= yBottom && y >= yTop) {
      newY = Math.abs((y - yTop) / (yBottom - yTop));
      newY = (newY - 1) * -1;
      newY = newY * Math.abs(yMax - yMin) + yMin;
      showStuff = 1;
    }
    var xTop = this.chart.chartArea.left;
    var xBottom = this.chart.chartArea.right;
    var xMin = this.chart.scales['Time'].min;
    var xMax = this.chart.scales['Time'].max;
    var newX = 0;
    if (x <= xBottom && x >= xTop && showStuff == 1) {
      newX = Math.abs((x - xTop) / (xBottom - xTop));
      newX = newX * Math.abs(xMax - xMin) + xMin;
    }
    return {
      x: newX,
      xUnit: 'sec',
      y: newY,
      yUnit: 'V',
    };
  }

  abstract addRuler(): BaseRulerType;

  getRulerPoint = <TType>(): TType => this.opts.ruler;

  abstract handlePoint(x: number, y: number, type: MouseEvents): void;

  abstract drawRuler(): void;
}

class XRuler extends BaseRuler {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = xRulerInitialState.backgroundColor;
    this.ctx.strokeStyle = xRulerInitialState.borderColor;
    this.ctx.lineWidth = xRulerInitialState.borderWidth;
  }

  addRuler = (): XRulerType =>
    (this.opts.ruler = {
      id: 1,
      plugin: Plugins.Ruler,
      type: RulerModes.X,
      count: 0,
      isVisible: true,
      isDeleted: false,
      isFocused: true,
      headlen: 10,
      infoBoxWidth: xRulerInitialState.infoBoxWidth,
      infoBoxHeight: xRulerInitialState.infoBoxHeight,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getRulerPoint<XRulerType>()?.type != RulerModes.X || !this.getRulerPoint()) this.addRuler();

    let ruler = this.getRulerPoint<XRulerType>();

    if (type == MouseEvents.Click && (ruler.count === 0 || ruler.count === 2)) {
      ruler.firstDataPoint = this.getPointDataset(x, y);
      ruler.firstXPoint = x;
      ruler.secondXPoint = x;
      ruler.yPoint = y;
      ruler.count = 1;
    } else if (type == MouseEvents.MouseMove && ruler.count === 1) {
      ruler.infoBoxFirstXPoint = (x + ruler.firstXPoint!) / 2 - xRulerInitialState.infoBoxWidth / 2;
      ruler.infoBoxFirstYPoint = ruler.yPoint! - (xRulerInitialState.infoBoxHeight + 20);
      ruler.secondXPoint = x;
      ruler.secondDataPoint = this.getPointDataset(x, y);
      //for x ruler, change second data point y same with first data point
      ruler.secondDataPoint.y = ruler.firstDataPoint?.y!;
    } else if (type == MouseEvents.Click && ruler.count === 1) {
      ruler.secondXPoint = x;
      ruler.count += 1;
    }
  }

  drawRuler(): void {
    let { firstXPoint, secondXPoint, yPoint, headlen, infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxHeight, infoBoxWidth, firstDataPoint, secondDataPoint } = this.getRulerPoint<XRulerType>();

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(firstXPoint, yPoint);
    this.ctx.lineTo(secondXPoint, yPoint);

    //This variable necessary to determine second clicked points arrow direction by negative or positive value
    let primaryXCoordinate = secondXPoint! > firstXPoint! ? secondXPoint! - headlen : secondXPoint! + headlen;
    this.ctx.lineTo(primaryXCoordinate, yPoint! + headlen);
    this.ctx.moveTo(secondXPoint, yPoint);
    this.ctx.lineTo(primaryXCoordinate, yPoint! - headlen);

    //This variable necessary to determine first clicked points arrow direction by negative or positive value
    let secondaryXCoordinate = secondXPoint! > firstXPoint! ? firstXPoint! + headlen : firstXPoint! - headlen;
    this.ctx.moveTo(firstXPoint, yPoint);
    this.ctx.lineTo(secondaryXCoordinate, yPoint! + headlen);
    this.ctx.moveTo(firstXPoint, yPoint);
    this.ctx.lineTo(secondaryXCoordinate, yPoint! - headlen);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.fillStyle = xRulerInitialState.infoBoxBackgroundColor;
    this.ctx.moveTo(infoBoxFirstXPoint, infoBoxFirstYPoint);
    this.ctx.rect(infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxWidth, infoBoxHeight);
    this.ctx.fill();

    this.ctx.fillStyle = xRulerInitialState.infoBoxTextColor;
    let text1 = `From: ${firstDataPoint?.x!.toFixed(2)} ${firstDataPoint?.xUnit}, ${yPoint!} ${firstDataPoint?.yUnit}`;
    let text2 = `To\t\t\t\t\t: ${secondDataPoint?.x!.toFixed(2)} ${secondDataPoint?.xUnit}, ${yPoint!} ${secondDataPoint?.yUnit}`;
    this.ctx.fillText(text1, infoBoxFirstXPoint! + 25, infoBoxFirstYPoint! + 20);
    this.ctx.fillText(text2, infoBoxFirstXPoint! + 25, infoBoxFirstYPoint! + 40);

    this.ctx.restore();
  }
}

class YRuler extends BaseRuler {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = yRulerInitialState.backgroundColor;
    this.ctx.strokeStyle = yRulerInitialState.borderColor;
    this.ctx.lineWidth = yRulerInitialState.borderWidth;
  }

  addRuler = (): YRulerType =>
    (this.opts.ruler = {
      id: 1,
      plugin: Plugins.Ruler,
      type: RulerModes.Y,
      count: 0,
      isVisible: true,
      isDeleted: false,
      isFocused: true,
      headlen: 10,
      infoBoxWidth: yRulerInitialState.infoBoxWidth,
      infoBoxHeight: yRulerInitialState.infoBoxHeight,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getRulerPoint<YRulerType>()?.type != RulerModes.Y || !this.getRulerPoint()) this.addRuler();

    let ruler = this.getRulerPoint<YRulerType>();

    if (type == MouseEvents.Click && (ruler.count === 0 || ruler.count === 2)) {
      ruler.firstDataPoint = this.getPointDataset(x, y);
      ruler.firstYPoint = y;
      ruler.secondYPoint = y;
      ruler.xPoint = x;
      ruler.count = 1;
    } else if (type == MouseEvents.MouseMove && ruler.count === 1) {
      ruler.secondDataPoint = this.getPointDataset(x, y);
      //for x ruler, change second data point y same with first data point
      ruler.secondDataPoint.x = ruler.firstDataPoint?.x!;
      ruler.secondYPoint = y;
      ruler.infoBoxFirstXPoint = ruler.xPoint! + 20;
      ruler.infoBoxFirstYPoint = (ruler.secondYPoint! + ruler.firstYPoint!) / 2 - yRulerInitialState.infoBoxHeight / 2;
    } else if (type == MouseEvents.Click && ruler.count === 1) {
      ruler.secondYPoint = y;
      ruler.count += 1;
    }
  }

  drawRuler(): void {
    let { firstYPoint, secondYPoint, xPoint, headlen, infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxWidth, infoBoxHeight, firstDataPoint, secondDataPoint } = this.getRulerPoint<YRulerType>();

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, firstYPoint);
    this.ctx.lineTo(xPoint, secondYPoint);
    //This variable necessary to determine first clicked arrow direction by negative or positive value
    let primaryYCoordinate = secondYPoint! > firstYPoint! ? secondYPoint! - headlen : secondYPoint! + headlen;
    this.ctx.lineTo(xPoint! - headlen, primaryYCoordinate);
    this.ctx.moveTo(xPoint, secondYPoint);
    this.ctx.lineTo(xPoint! + headlen, primaryYCoordinate);
    //This variable necessary to determine first clicked arrow direction by negative or positive value
    let secondaryYCoordinate = secondYPoint! > firstYPoint! ? firstYPoint! + headlen : firstYPoint! - headlen;
    this.ctx.moveTo(xPoint, firstYPoint);
    this.ctx.lineTo(xPoint! - headlen, secondaryYCoordinate);
    this.ctx.moveTo(xPoint, firstYPoint);
    this.ctx.lineTo(xPoint! + headlen, secondaryYCoordinate);
    this.ctx.stroke();

    this.ctx.fillStyle = yRulerInitialState.infoBoxBackgroundColor;
    this.ctx.beginPath();
    this.ctx.moveTo(infoBoxFirstXPoint, infoBoxFirstYPoint);
    this.ctx.rect(infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxWidth, infoBoxHeight);
    this.ctx.fill();

    this.ctx.fillStyle = yRulerInitialState.infoBoxTextColor;
    let text1 = `From: ${firstDataPoint?.y!.toFixed(2)} ${firstDataPoint?.yUnit}, ${xPoint!} ${firstDataPoint?.xUnit}`;
    let text2 = `To\t\t\t\t\t: ${secondDataPoint?.y!.toFixed(2)} ${secondDataPoint?.yUnit}, ${xPoint!} ${secondDataPoint?.xUnit}`;
    this.ctx.fillText(text1, infoBoxFirstXPoint! + 20, infoBoxFirstYPoint! + 20);
    this.ctx.fillText(text2, infoBoxFirstXPoint! + 20, infoBoxFirstYPoint! + 40);

    this.ctx.restore();
  }
}

class XYRuler extends BaseRuler {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = xyRulerInitialState.backgroundColor;
    this.ctx.strokeStyle = xyRulerInitialState.borderColor;
    this.ctx.lineWidth = xyRulerInitialState.borderWidth;
  }

  addRuler = (): XYRulerType =>
    (this.opts.ruler = {
      id: 1,
      plugin: Plugins.Ruler,
      type: RulerModes.XY,
      count: 0,
      isVisible: true,
      isDeleted: false,
      isFocused: true,
      headlen: 10,
      infoBoxWidth: xyRulerInitialState.infoBoxWidth,
      infoBoxHeight: xyRulerInitialState.infoBoxHeight,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getRulerPoint<XYRulerType>()?.type != RulerModes.XY || !this.getRulerPoint()) this.addRuler();

    let ruler = this.getRulerPoint<XYRulerType>();

    if (type == MouseEvents.Click && (ruler.count === 0 || ruler.count === 2)) {
      ruler.firstDataPoint = this.getPointDataset(x, y);
      ruler.firstXPoint = x;
      ruler.firstYPoint = y;
      ruler.secondXPoint = x;
      ruler.secondYPoint = y;
      ruler.count = 1;
    } else if (type == MouseEvents.MouseMove && ruler.count === 1) {
      ruler.secondDataPoint = this.getPointDataset(x, y);
      ruler.secondXPoint = x;
      ruler.secondYPoint = y;
      ruler.infoBoxFirstXPoint = (ruler.secondXPoint! + ruler.firstXPoint!) / 2 - xyRulerInitialState.infoBoxWidth / 2;
      ruler.infoBoxFirstYPoint = ruler.secondYPoint! - xyRulerInitialState.infoBoxHeight - 20;
    } else if (type == MouseEvents.Click && ruler.count === 1) {
      ruler.secondXPoint = x;
      ruler.secondYPoint = y;
      ruler.count += 1;
    }
  }

  drawRuler(): void {
    let { firstXPoint, firstYPoint, secondXPoint, secondYPoint, headlen, infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxWidth, infoBoxHeight, firstDataPoint, secondDataPoint } =
      this.getRulerPoint<XYRulerType>();

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(firstXPoint, firstYPoint);
    this.ctx.rect(firstXPoint, firstYPoint, secondXPoint! - firstXPoint!, secondYPoint! - firstYPoint!);
    this.ctx.fill();

    this.ctx.beginPath();
    this.ctx.moveTo((firstXPoint! + secondXPoint!) / 2, firstYPoint);
    this.ctx.lineTo((firstXPoint! + secondXPoint!) / 2, secondYPoint);
    //This variable necessary to determine first clicked arrow direction by negative or positive value
    let primaryYCoordinate = secondYPoint! > firstYPoint! ? secondYPoint! - headlen : secondYPoint! + headlen;
    this.ctx.lineTo((firstXPoint! + secondXPoint!) / 2! - headlen, primaryYCoordinate);
    this.ctx.moveTo((firstXPoint! + secondXPoint!) / 2!, secondYPoint!);
    this.ctx.lineTo((firstXPoint! + secondXPoint!) / 2! + headlen, primaryYCoordinate);
    //This variable necessary to determine second clicked arrow direction by negative or positive value
    let secondaryYCoordinate = secondYPoint! > firstYPoint! ? firstYPoint! + headlen : firstYPoint! - headlen;
    this.ctx.moveTo((firstXPoint! + secondXPoint!) / 2!, firstYPoint!);
    this.ctx.lineTo((firstXPoint! + secondXPoint!) / 2! - headlen, secondaryYCoordinate);
    this.ctx.moveTo((firstXPoint! + secondXPoint!) / 2!, firstYPoint!);
    this.ctx.lineTo((firstXPoint! + secondXPoint!) / 2! + headlen, secondaryYCoordinate);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.moveTo(firstXPoint, (secondYPoint! + firstYPoint!) / 2);
    this.ctx.lineTo(secondXPoint, (secondYPoint! + firstYPoint!) / 2);
    //This variable necessary to determine first clciked arrow direction by negative or positive value
    let primaryXCoordinate = secondXPoint! > firstXPoint! ? secondXPoint! - headlen : secondXPoint! + headlen;
    this.ctx.lineTo(primaryXCoordinate, (secondYPoint! + firstYPoint!) / 2 + headlen);
    this.ctx.moveTo(secondXPoint!, (secondYPoint! + firstYPoint!) / 2);
    this.ctx.lineTo(primaryXCoordinate, (secondYPoint! + firstYPoint!) / 2 - headlen);
    //This variable necessary to determine first clciked arrow direction by negative or positive value
    let secondaryXCoordinate = secondXPoint! > firstXPoint! ? firstXPoint! + headlen : firstXPoint! - headlen;
    this.ctx.moveTo(firstXPoint!, (secondYPoint! + firstYPoint!) / 2);
    this.ctx.lineTo(secondaryXCoordinate, (secondYPoint! + firstYPoint!) / 2 + headlen);
    this.ctx.moveTo(firstXPoint!, (secondYPoint! + firstYPoint!) / 2);
    this.ctx.lineTo(secondaryXCoordinate, (secondYPoint! + firstYPoint!) / 2 - headlen);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.fillStyle = xyRulerInitialState.infoBoxBackgroundColor;
    this.ctx.moveTo(infoBoxFirstXPoint, infoBoxFirstYPoint);
    this.ctx.rect(infoBoxFirstXPoint, infoBoxFirstYPoint, infoBoxWidth, infoBoxHeight);
    this.ctx.fill();

    this.ctx.fillStyle = xyRulerInitialState.infoBoxTextColor;
    let text1 = `From: ${firstDataPoint?.x!.toFixed(2)} ${firstDataPoint?.xUnit}, ${firstDataPoint?.y!.toFixed(2)} ${firstDataPoint?.yUnit}`;
    let text2 = `To\t\t\t\t\t: ${secondDataPoint?.x!.toFixed(2)} ${secondDataPoint?.xUnit}, ${secondDataPoint?.y!.toFixed(2)} ${secondDataPoint?.yUnit}`;
    this.ctx.fillText(text1, infoBoxFirstXPoint! + 10, infoBoxFirstYPoint! + 20);
    this.ctx.fillText(text2, infoBoxFirstXPoint! + 10, infoBoxFirstYPoint! + 40);

    this.ctx.restore();
  }
}

class RulerCreator {
  chart: any;
  opts!: any;
  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.opts = opts;
  }

  createRuler(rulerMode: RulerModeType): IRuler {
    if (rulerMode === RulerModes.X) {
      return new XRuler(this.chart, this.opts);
    } else if (rulerMode === RulerModes.Y) {
      return new YRuler(this.chart, this.opts);
    } else if (rulerMode === RulerModes.XY) {
      return new XYRuler(this.chart, this.opts);
    } else {
      return new XYRuler(this.chart, this.opts);
    }
  }
}
