import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from 'src/app/animations';

@Component({
  selector: 'app-upgrade-license',
  templateUrl: './upgrade-license.component.html',
  styleUrls: ['./upgrade-license.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' },
})
export class UpgradeLicenseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  upgradePlan() {
    window.open('https://www.hisim.com.tr/contact/', '_blank');
  }
}
