import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/state/appState';
import { Train } from 'src/app/models/response-models/train';

@Component({
  selector: 'app-train-preview-dialog',
  templateUrl: './train-preview-dialog.component.html',
  styleUrls: ['./train-preview-dialog.component.css'],
})
export class TrainPreviewDialogComponent implements OnInit {
  train!: Train;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly _data: { trainUUId: string },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.simulationResultState.trains)
      .subscribe(simulationResults => {
        this.train = simulationResults!.find(sim => sim.trainUUId === _data.trainUUId)!;
      });
  }
  ngOnInit(): void {}
}
