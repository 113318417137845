import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_SUCCESS_STYLES, SNACK_BAR_ERROR_STYLES, SNACK_BAR_COMMON_STYLES } from '../constants/styles';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SharedUIService {
  constructor(
    public readonly _snackBar: MatSnackBar,
    private readonly _translate: TranslateService
  ) {}

  showSuccessSnackBar(
    successMessage: string = this._translate.instant(SNACK_BAR_SUCCESS_STYLES.SNACK_BAR_SUCCESS),
    buttonText: string = this._translate.instant(SNACK_BAR_COMMON_STYLES.SNACK_BAR_BTN_TEXT),
    duration: number = SNACK_BAR_SUCCESS_STYLES.SNACK_BAR_DURATION,
    horizontalPosition: string = SNACK_BAR_SUCCESS_STYLES.SNACK_BAR_HORIZONTAL_POSITION,
    verticalPosition: string = SNACK_BAR_SUCCESS_STYLES.SNACK_BAR_VERTICAL_POSITION,
    snackbarClass: string = SNACK_BAR_SUCCESS_STYLES.SNACK_BAR_PANEL_CLASS
  ) {
    this._snackBar.open('✅ ' + successMessage, buttonText, {
      horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
      verticalPosition: verticalPosition as MatSnackBarVerticalPosition,
      duration: duration,
      panelClass: snackbarClass,
    });
  }

  showErrorSnackBar(
    failMessage: string = this._translate.instant(SNACK_BAR_ERROR_STYLES.SNACK_BAR_ERROR),
    buttonText: string = this._translate.instant(SNACK_BAR_COMMON_STYLES.SNACK_BAR_BTN_TEXT),
    duration: number = SNACK_BAR_ERROR_STYLES.SNACK_BAR_DURATION,
    horizontalPosition: string = SNACK_BAR_ERROR_STYLES.SNACK_BAR_HORIZONTAL_POSITION,
    verticalPosition: string = SNACK_BAR_ERROR_STYLES.SNACK_BAR_VERTICAL_POSITION,
    snackbarClass: string = SNACK_BAR_ERROR_STYLES.SNACK_BAR_PANEL_CLASS
  ) {
    this._snackBar.open('❌ ' + failMessage, buttonText, {
      horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
      verticalPosition: verticalPosition as MatSnackBarVerticalPosition,
      duration: duration,
      panelClass: snackbarClass,
    });
  }
}
