import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, filter, switchMap, map, catchError } from 'rxjs/operators';
import { FeederDatas } from 'src/app/models/response-models/feederDatas';
import { FeederSummaryDatas } from 'src/app/models/response-models/feederSummaryDatas';
import { Jumper } from 'src/app/models/response-models/jumper';
import { Operation } from 'src/app/models/response-models/operation';
import { Train } from 'src/app/models/response-models/train';
import { TransformersDatas } from 'src/app/models/response-models/transformersDatas';
import { AppState } from 'src/app/models/state/appState';
import { SharedService } from 'src/app/services/shared.service';
import { SimulationResultService } from 'src/app/services/simulation-result.service';
import odataQuery from 'odata-fluent-query';
import { GetSimulationResultDto } from 'src/app/dtos/simulation-result/getSimulationResultDto';
import {
  AddSimulationResultEnergyDatasAction,
  AddSimulationResultEnergyDatasActionError,
  AddSimulationResultFeederDatasAction,
  AddSimulationResultFeederDatasActionError,
  AddSimulationResultFeederSummaryDatasAction,
  AddSimulationResultFeederSummaryDatasActionError,
  AddSimulationResultGeneralDatasAction,
  AddSimulationResultGeneralDatasActionError,
  AddSimulationResultJumperAction,
  AddSimulationResultJumperActionError,
  AddSimulationResultOperationDatasAction,
  AddSimulationResultOperationDatasActionError,
  AddSimulationResultPreviewDatasAction,
  AddSimulationResultPreviewDatasActionError,
  AddSimulationResultPssSummaryDatasAction,
  AddSimulationResultPssSummaryDatasActionError,
  AddSimulationResultTrainAction,
  AddSimulationResultTrainActionError,
  AddSimulationResultTransformersDatasAction,
  AddSimulationResultTransformersDatasActionError,
  SimulationResultActionTypes,
} from '../actions/simulation-result.action';
import { PssSummaryDatas } from 'src/app/models/response-models/pssSummaryDatas';
import { GetSimulationResultPreviewDto } from 'src/app/dtos/simulation-result/getSimulationResultPreviewDto';

@Injectable()
export class SimulationResultEffects {
  constructor(
    private actions$: Actions,
    private readonly _simulationResultService: SimulationResultService,
    private readonly _sharedService: SharedService,
    private readonly _store: Store<AppState>
  ) {}

  loadSimulationResultsPreview$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PREVIEW_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.preview)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        /* return this._simulationResultService;
         .filterSimulationResults(
            odataQuery<GetSimulationResultDto>()
              .filter(g => g.id.in(this._sharedService.getSimulationResultIds()))
              .select(
                s => s.id,
                s => s.simulationResultUUId,
                s => s.simulationUUId,
                s => s.simulationTimeFraction,
                s => s.simulationTime
              )
              .expand(
                s => s.simulation,
                q =>
                  q.select(
                    s => s.id,
                    s => s.title,
                    s => s.shortName,
                    s => s.description,
                    s => s.simulationSetId,
                    s => s.lineNames,
                    s => s.lineTags,
                    s => s.transformerShortNames,
                    s => s.transformerPowers,
                    s => s.transformerNames,
                    s => s.trainTypeNames,
                    s => s.displayOrder,
                    s => s.updatedAt,
                    s => s.updatedBy,
                    s => s.createdAt,
                    s => s.createdBy
                  )
              )
              .expand(
                s => s.simulationRequest,
                q =>
                  q.select(
                    s => s.completionPercentage,
                    s => s.status
                  )
              )
              .toString()
          ) */
        return this._simulationResultService.getSimulationResultPreviewDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultPreviewDatasAction(response.data as GetSimulationResultPreviewDto[], null)),
          catchError(errorMessage => of(new AddSimulationResultPreviewDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultGeneral$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_GENERAL_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.general)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        const query = odataQuery<GetSimulationResultDto>()
          .filter(g => g.id.in(this._sharedService.getSimulationResultIds()))
          .select(
            s => s.id,
            s => s.simulationTime,
            s => s.simulationResultUUId,
            s => s.minTrainVoltage,
            s => s.minRailVoltage,
            s => s.maxRailVoltage,
            s => s.maxRMSPower,
            s => s.maxPower,
            s => s.totalVehicleKilometers,
            s => s.energyPerVehiclePerKilometer,
            s => s.totalEnergy,
            s => s.railVoltageThresholds,
            s => s.maxTimeOverThresholds,
            s => s.meanUsefulVoltage
          )
          .expand(
            s => s.simulation,
            q =>
              q.select(
                s => s.id,
                s => s.title
              )
          )
          .toString();
        return this._simulationResultService.filterSimulationResults(query).pipe(
          map(response => new AddSimulationResultGeneralDatasAction(response, null)),
          catchError(errorMessage => of(new AddSimulationResultGeneralDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultEnergy$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_ENERGY_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.energy)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        const query = odataQuery<GetSimulationResultDto>()
          .filter(g => g.id.in(this._sharedService.getSimulationResultIds()))
          .select(
            s => s.id,
            s => s.simulationTime,
            s => s.simulationResultUUId,
            s => s.totalEnergy,
            s => s.energyRegenerated,
            s => s.regeneratedEnergyToSystem,
            s => s.regeneratedEnergyToAuxilary,
            s => s.energyPerVehiclePerKilometer,
            s => s.energyBurnt,
            s => s.lineLoss,
            s => s.receptivityRate,
            s => s.recuperationRatio
          )
          .expand(
            s => s.simulation,
            q =>
              q.select(
                s => s.id,
                s => s.title
              )
          )
          .toString();
        return this._simulationResultService.filterSimulationResults(query).pipe(
          map(response => new AddSimulationResultEnergyDatasAction(response, null)),
          catchError(errorMessage => of(new AddSimulationResultEnergyDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultPssSummary$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PSS_SUMMARY_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.pssSummary)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        /*  return this._simulationResultService
          .filterSimulationResults(
            odataQuery<GetSimulationResultDto>()
              .filter(g => g.id.in(this._sharedService.getSimulationResultIds()))
              .select(
                s => s.id,
                s => s.simulationTime,
                s => s.simulationResultUUId,
                s => s.transformerMaxPowerskW,
                s => s.transformerMaxRMSPowerskW,
                s => s.transformerMax1MinRMSPowerskW,
                s => s.transformerMax1HrRMSPowerskW
              )
              .expand(
                s => s.simulation,
                q =>
                  q.select(
                    s => s.id,
                    s => s.title,
                    s => s.transformerShortNames,
                    s => s.transformerNames,
                    s => s.transformerPowers
                  )
              )
              .expand(
                s => s.simulationRequest,
                q =>
                  q.select(
                    s => s.completionPercentage,
                    s => s.status
                  )
              )
              .toString()
          ) */
        return this._simulationResultService.getSimulationResultPssSummaryDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultPssSummaryDatasAction(response.data as PssSummaryDatas[], null)),
          catchError(errorMessage => of(new AddSimulationResultPssSummaryDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultTransformer$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRANSFORMERS_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.transformers)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultTransformerDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultTransformersDatasAction(response.data as TransformersDatas[], null)),
          catchError(errorMessage => of(new AddSimulationResultTransformersDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultFeeders$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.feeders)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultFeedersDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultFeederDatasAction(response.data as FeederDatas[], null)),
          catchError(errorMessage => of(new AddSimulationResultFeederDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultFeedersSummary$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.feedersSummary)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultFeedersSummaryDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultFeederSummaryDatasAction(response.data as FeederSummaryDatas[], null)),
          catchError(errorMessage => of(new AddSimulationResultFeederSummaryDatasActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultJumpers$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_JUMPERS_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.jumpers)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultJumpersDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultJumperAction(response.data as Jumper[], null)),
          catchError(errorMessage => of(new AddSimulationResultJumperActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultTrains$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRAINS_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.trains)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultTrainsDatasByIds(this._sharedService.getSimulationResultIds(), true).pipe(
          map(response => new AddSimulationResultTrainAction(response.data as Train[], null)),
          catchError(errorMessage => of(new AddSimulationResultTrainActionError(null, errorMessage)))
        );
      })
    )
  );

  loadSimulationResultOperation$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(SimulationResultActionTypes.LOAD_SIMULATION_RESULT_OPERATION_DATAS),
      withLatestFrom(this._store.select(state => state.simulationResultState.operation)),
      filter(([action, hasLoaded]) => !hasLoaded && this._sharedService.isSimulationResultIdsValid()),
      switchMap(() => {
        return this._simulationResultService.getSimulationResultOperationDatasByIds(this._sharedService.getSimulationResultIds()).pipe(
          map(response => new AddSimulationResultOperationDatasAction(response.data as Operation[], null)),
          catchError(errorMessage => of(new AddSimulationResultOperationDatasActionError(null, errorMessage)))
        );
      })
    )
  );
}
