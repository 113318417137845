import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformerPercentage',
})
export class TransformerPercentagePipe implements PipeTransform {
  transform(value: any, index: number, transformerValues: any, transformerMaxValues: any): number {
    return ((transformerValues[index] / 1000) * 100) / transformerMaxValues[index];
  }
}
