import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/models/component-models/tableColumn';
import { SharedUIService } from 'src/app/services/shared-ui.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-table-settings-menu',
  templateUrl: './table-settings-menu.component.html',
  styleUrls: ['./table-settings-menu.component.css'],
})
export class TableSettingsMenuComponent implements OnInit {
  @Input()
  columnShowHideList!: TableColumn[];

  @Input()
  displayedColumns!: string[];

  @Input()
  tableName!: string;

  constructor(
    private readonly _sharedService: SharedService,
    private readonly _sharedUIService: SharedUIService
  ) {}

  ngOnInit(): void {}

  toggleColumn(column: TableColumn) {
    if (column.isActive) {
      if (column.position > this.displayedColumns.length - 1) {
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, column.name);
      } else {
        this.displayedColumns.splice(column.position, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }

  filterColumn(event: any) {
    const filterValue = event.target.value;
    this.columnShowHideList = this.columnShowHideList.map(element => ({
      ...element,
      filter: element.name.trim().toLowerCase().includes(filterValue.trim().toLowerCase()),
    }));
  }

  saveColumns() {
    this._sharedService.setTableDisplayColumns(this.tableName, this.displayedColumns);
    this._sharedUIService.showSuccessSnackBar();
  }
}
