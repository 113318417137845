import { Action } from '@ngrx/store';
import { GetSimulationReportDto } from 'src/app/dtos/report/getSimulationReportDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum SimulationReportActionTypes {
  ADD_SIMULATION_REPORT = '[SimulationReport] Add SimulationReport',
  ADD_SIMULATION_REPORT_ERROR = '[SimulationReport] Add SimulationReport Error',
  LOAD_SIMULATION_REPORT = '[SimulationReport] Load SimulationReport',
  CLEAR_SIMULATION_REPORT = '[SimulationReport] Clear SimulationReport',
}

export class AddSimulationReportAction implements Action {
  readonly type = SimulationReportActionTypes.ADD_SIMULATION_REPORT;

  constructor(
    public payload: GetSimulationReportDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddSimulationReportActionError implements Action {
  readonly type = SimulationReportActionTypes.ADD_SIMULATION_REPORT_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationReportDatas implements Action {
  readonly type = SimulationReportActionTypes.LOAD_SIMULATION_REPORT;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearSimulationReportDatas implements Action {
  readonly type = SimulationReportActionTypes.CLEAR_SIMULATION_REPORT;

  constructor() {}
}

export type SimulationReportAction = LoadSimulationReportDatas | AddSimulationReportAction | AddSimulationReportActionError | ClearSimulationReportDatas;
