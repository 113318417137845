import { Action } from '@ngrx/store';
import { PlayerDatas } from 'src/app/models/response-models/playerDatas';
import { Simulation } from 'src/app/models/response-models/simulation';

export enum SimulationActionTypes {
  ADD_SIMULATION = '[Simulation] Add Simulation',
  ADD_SIMULATION_ERROR = '[Simulation] Add Simulation Error',
  ADD_PLAYER = '[Simulation] Add Player',
  ADD_PLAYER_ERROR = '[Simulation] Add Player Error',
  LOAD_SIMULATION_SCOPE_DATAS = '[Simulation] Load Simulation Scope Datas',
  LOAD_SIMULATION_PLAYER_DATAS = '[Simulation] Load Simulation Player Datas',
  REMOVE_ALL_SIMULATION_DATAS = '[Simulation] Remove All Simulation Datas',
}

export class AddSimulationAction implements Action {
  readonly type = SimulationActionTypes.ADD_SIMULATION;

  constructor(
    public payload: Simulation[],
    public error: null
  ) {}
}

export class AddSimulationActionError implements Action {
  readonly type = SimulationActionTypes.ADD_SIMULATION_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class AddPlayerAction implements Action {
  readonly type = SimulationActionTypes.ADD_PLAYER;

  constructor(
    public payload: PlayerDatas[],
    public error: null
  ) {}
}

export class AddPlayerActionError implements Action {
  readonly type = SimulationActionTypes.ADD_PLAYER_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationScopeDatas implements Action {
  readonly type = SimulationActionTypes.LOAD_SIMULATION_SCOPE_DATAS;

  constructor() {}
}

export class LoadSimulationPlayerDatas implements Action {
  readonly type = SimulationActionTypes.LOAD_SIMULATION_PLAYER_DATAS;

  constructor() {}
}

export class RemoveAllSimulationDatas implements Action {
  readonly type = SimulationActionTypes.REMOVE_ALL_SIMULATION_DATAS;

  constructor() {}
}

export type SimulationAction =
  | AddSimulationAction
  | AddSimulationActionError
  | AddPlayerAction
  | AddPlayerActionError
  | LoadSimulationScopeDatas
  | LoadSimulationPlayerDatas
  | RemoveAllSimulationDatas;
