import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../services/shared.service';
import decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token!: string;
  //tenantId!: string;

  constructor(private sharedService: SharedService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.sharedService.getUserToken();
    //TODO Change here!! Add cookie and don't check expration time continually
    if (this.token) {
      let tokenPayload: any = decode(this.token);
      //this.tenantId = tokenPayload.tenantIdentifier;
      let expirationDate = new Date(tokenPayload.exp * 1000);
      if (new Date() > expirationDate) {
        this.sharedService.removeUserToken();
      }
    }

    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
