<div class="d-flex justify-content-between">
  <h1 mat-dialog-title>{{ feederData.transformerName }}</h1>
  <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
</div>
<div mat-dialog-content class="row d-flex flex-row">
  <mat-card class="my-1 col-12 col-md-6">
    <strong class="font-weight-bold"> PSS </strong>
  </mat-card>
  <mat-card class="my-1 col-12 col-md-6">
    {{ feederData.transformerName }}
  </mat-card>
  <div class="col-12 my-1 text-center font-weight-bold">Feeder Max. Currents</div>
  <ng-container *ngFor="let feederVal of feederData.feederMaxCurrents?.iw!; let i = index">
    <mat-card class="col-12 col-md-6">
      <strong class="font-weight-bold"> Iw{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ (feederData.feederMaxCurrents?.iw)![i] | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ie{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ (feederData.feederMaxCurrents?.ie)![i] | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ir{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ (feederData.feederMaxCurrents?.ir)![i] | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ feederData.feederMaxCurrents?.totalCurrent! | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Negative Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ feederData.feederMaxCurrents?.totalNegativeCurrent! | trunc }}</mat-card>
  </ng-container>
  <mat-divider></mat-divider>
  <div class="col-12 my-1 text-center font-weight-bold">Feeder Max. RMS Currents</div>
  <ng-container *ngFor="let feederVal of feederData.feederMaxRMSCurrents?.iw!; let i = index">
    <mat-card class="col-12 col-md-6">
      <strong class="font-weight-bold"> Iw{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ (feederData.feederMaxRMSCurrents?.iw)![i] | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ie{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ (feederData.feederMaxRMSCurrents?.ie)![i] | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ir{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ (feederData.feederMaxRMSCurrents?.ir)![i] | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ feederData.feederMaxRMSCurrents?.totalCurrent! | trunc }}</mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Negative Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6"> {{ feederData.feederMaxRMSCurrents?.totalNegativeCurrent! | trunc }}</mat-card>
  </ng-container>
  <mat-divider></mat-divider>
  <div class="my-1 col-12 text-center font-weight-bold">Feeder Max. Reverse Currents</div>
  <ng-container *ngFor="let feederVal of feederData.feederMaxReverseCurrents?.iw!!; let i = index">
    <mat-card class="col-12 col-md-6">
      <strong class="font-weight-bold"> Iw{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ (feederData.feederMaxReverseCurrents?.iw)![i] | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ie{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ (feederData.feederMaxReverseCurrents?.ie)![i] | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Ir{{ i + 1 }} </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ (feederData.feederMaxReverseCurrents?.ir)![i] | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ feederData.feederMaxReverseCurrents?.totalCurrent! | trunc }}
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      <strong class="font-weight-bold"> Total Negative Current </strong>
    </mat-card>
    <mat-card class="my-1 col-12 col-md-6">
      {{ feederData.feederMaxReverseCurrents?.totalNegativeCurrent! | trunc }}
    </mat-card>
  </ng-container>
  <mat-divider></mat-divider>
</div>
