import { DataLogState } from 'src/app/models/state/appState';
import { DataLogAction, DataLogActionTypes } from '../actions/data-log.action';

const initialDataLogState: DataLogState = {
  isDataLogLoaded: false,
};

export function DataLogReducer(state: DataLogState = initialDataLogState, action: DataLogAction | any): any {
  switch (action.type) {
    case DataLogActionTypes.ADD_DATA_LOG:
      return {
        ...state,
        dataLog: action.payload,
        dataLogError: null,
      };
    case DataLogActionTypes.ADD_DATA_LOG_ERROR:
      return {
        ...state,
        dataLog: null,
        dataLogError: action.error,
      };
    case DataLogActionTypes.LOAD_DATA_LOG:
      return {
        ...state,
        isDataLogLoaded: true,
        dataLogError: null,
      };
    default:
      return state;
  }
}
