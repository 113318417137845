<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ transformerDatas.transformerName }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.PSS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerName!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_POWERS_KW' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas.transformerMaxPowerskW | findMax | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_POWER_PERCENTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMaxPowerskW | calculateTransformerPowerPercentage: transformerDatas.transformerMaxPowerskW : transformerDatas.transformerPower | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_RMS_POWERS_Kw' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas.transformerMaxRMSPowerskW | findMax | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_RMS_POWERS_Kw_PERCENTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMaxRMSPowerskW | calculateTransformerPowerPercentage: transformerDatas.transformerMaxRMSPowerskW : transformerDatas.transformerPower | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_1_MIN_RMS_POWERS_Kw' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas.transformerMax1MinRMSPowerskW | findMax | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_1_MIN_RMS_POWERS_Kw_PERCENTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMax1MinRMSPowerskW | calculateTransformerPowerPercentage: transformerDatas.transformerMax1MinRMSPowerskW : transformerDatas.transformerPower | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_1_Hr_RMS_POWERS_Kw' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas.transformerMax1HrRMSPowerskW | findMax | convertToKw | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_1_Hr_RMS_POWERS_Kw_PERCENTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMax1HrRMSPowerskW | calculateTransformerPowerPercentage: transformerDatas.transformerMax1HrRMSPowerskW : transformerDatas.transformerPower | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_CURRENT' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMaxCurrents!! | findMax | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_1_MIN_CURRENT' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMaxAvg1MinCurrents!! | findMax | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_MAX_5_MIN_CURRENT' | translate }} </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerMaxAvg5MinCurrents!! | findMax | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_SUPPLIED_ENERGY_Kw' | translate }} </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerSuppliedEnergies!! | findMax | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'TRANSFORMER_PREVIEW.TRANSFORMER_REGENERATED_ENERGY_Kw' | translate }} </strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerDatas?.transformerRegeneratedEnergies!! | findMax | trunc }}
      </mat-card>
    </div>
  </div>
</div>
