import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetGeneralStatisticDto } from '../dtos/statistic/generalStatisticDto';
import { ResponseModel } from '../dtos/http/responseModel';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getGeneralStatistic(): Observable<ResponseModel<GetGeneralStatisticDto>> {
    return this._httpClient.get<ResponseModel<GetGeneralStatisticDto>>(`${this._configService.getBaseUrl()}/statistics`);
  }
}
