import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cropDate',
})
export class CropDatePipe implements PipeTransform {
  transform(date: string): string {
    return date.split(' ')[1];
  }
}
