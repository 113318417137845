import { Action } from '@ngrx/store';
import { GetVehicleDto } from 'src/app/dtos/vehicle/getVehicleDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum VehicleActionTypes {
  ADD_VEHICLE = '[Vehicle] Add Vehicle',
  ADD_VEHICLE_ERROR = '[Vehicle] Add Vehicle Error',
  LOAD_VEHICLE = '[Vehicle] Load Vehicle',
  CLEAR_VEHICLE = '[Vehicle] Clear Vehicle',
}

export class AddVehicleAction implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE;

  constructor(
    public payload: GetVehicleDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddVehicleActionError implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadVehicleDatas implements Action {
  readonly type = VehicleActionTypes.LOAD_VEHICLE;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearVehicleDatas implements Action {
  readonly type = VehicleActionTypes.CLEAR_VEHICLE;

  constructor() {}
}

export type VehicleAction = LoadVehicleDatas | AddVehicleAction | AddVehicleActionError | ClearVehicleDatas;
