import { Action } from '@ngrx/store';
import { GetRailDto } from 'src/app/dtos/rail/getRailDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum RailActionTypes {
  ADD_RAIL = '[Rail] Add Rail',
  ADD_RAIL_ERROR = '[Rail] Add Rail Error',
  LOAD_RAIL = '[Rail] Load Rail',
  CLEAR_RAIL = '[Rail] Clear Rail',
}

export class AddRailAction implements Action {
  readonly type = RailActionTypes.ADD_RAIL;

  constructor(
    public readonly payload: GetRailDto[],
    public readonly headers: HttpCommonHeaders,
    public readonly pageSize: number = 0,
    public readonly pageIndex: number = 0,
    public readonly error: null
  ) {}
}

export class AddRailActionError implements Action {
  readonly type = RailActionTypes.ADD_RAIL_ERROR;

  constructor(
    public readonly payload: null,
    public readonly error: string
  ) {}
}

export class LoadRailDatas implements Action {
  readonly type = RailActionTypes.LOAD_RAIL;

  constructor(
    public readonly pageSize: number = 25,
    public readonly pageIndex: number = 0,
    public readonly query: string = ''
  ) {}
}

export class ClearRailDatas implements Action {
  readonly type = RailActionTypes.CLEAR_RAIL;

  constructor() {}
}

export type RailAction = LoadRailDatas | AddRailAction | AddRailActionError | ClearRailDatas;
