import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from '../dtos/http/responseModel';
import { Scope } from '../models/response-models/scope';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class DataLogService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getDataLog(simulationResultUUID: string, logObjectUUID: string, logDataUUID: string): Observable<ResponseModel<Scope>> {
    return this._httpClient.get<ResponseModel<Scope>>(`${this._configService.getBaseUrl()}/data-logs`, {
      params: {
        'simulation-result-uuid': simulationResultUUID,
        'log-data-uuid': logDataUUID,
        'log-object-uuid': logObjectUUID,
      },
    });
  }
}
