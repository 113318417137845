import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Claims } from 'src/app/constants/claims';
import { SideNavItem } from 'src/app/models/component-models/sideNavItem';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'in',
        style({
          overflow: 'hidden',
          width: '256px',
        })
      ),
      state(
        'out',
        style({
          // opacity: '0',
          overflow: 'hidden',
          width: '68px',
        })
      ),
      transition('in => out', animate('100ms ease-in-out')),
      transition('out => in', animate('100ms ease-in-out')),
    ]),
  ],
})
export class SideNavComponent implements OnInit, OnDestroy {
  items: SideNavItem[] = [
    {
      title: 'HOME',
      icon: 'home',
      tooltip: 'HOME',
      isOpened: false,
      hasChild: false,
      route: '/admin',
    },
    {
      title: 'PROJECTS',
      icon: 'bookmark_border',
      tooltip: 'PROJECTS',
      isOpened: false,
      hasChild: false,
      route: '/admin/projects',
    },
    {
      title: 'TRACK_EDITOR.TRACK_EDITOR',
      icon: 'query_stats',
      tooltip: 'TRACK_EDITOR.TRACK_EDITOR',
      isOpened: false,
      hasChild: false,
      route: '/admin/track-editor',
    },
    {
      title: 'RUN_SIMULATION.RUN_SIMULATION',
      icon: 'double_arrow',
      tooltip: 'RUN_SIMULATION.RUN_SIMULATION',
      isOpened: false,
      hasChild: false,
      route: '/admin/run-simulation',
    },
    {
      title: 'SIMULATION_RESULTS',
      icon: 'trending_up',
      tooltip: 'SIMULATION_RESULTS',
      isOpened: false,
      hasChild: false,
      route: '/admin/simulation-results',
    },
    {
      title: 'SIMULATION_REPORT.SIMULATION_REPORT',
      icon: 'folder_open',
      tooltip: 'SIMULATION_REPORT.SIMULATION_REPORT',
      isOpened: false,
      hasChild: true,
      childs: [
        {
          title: 'SIMULATION_REPORT.SIMULATION_REPORT_HISTORY',
          route: '/admin/reports',
          isOpened: false,
          hasChild: false,
        },
        {
          title: 'SIMULATION_REPORT.SIMULATION_REPORT_TEMPLATES',
          route: '/admin/report-templates',
          isOpened: false,
          hasChild: false,
        },
      ],
    },
    {
      title: 'LIBRARY_TYPES.TYPES',
      icon: 'ac_unit',
      tooltip: 'LIBRARY_TYPES.TYPES',
      isOpened: false,
      hasChild: true,
      childs: [
        {
          title: 'LIBRARY_TYPES.VEHICLE_TYPES',
          route: '/admin/types/vehicles',
          isOpened: false,
          hasChild: false,
        },
        {
          title: 'LIBRARY_TYPES.RAIL_TYPES',
          route: '/admin/types/rails',
          isOpened: false,
          hasChild: false,
        },
        {
          title: 'LIBRARY_TYPES.CABLE_TYPES',
          route: '/admin/types/cables',
          isOpened: false,
          hasChild: false,
        },
      ],
    },
    {
      title: 'REQUESTS',
      icon: 'hive',
      tooltip: 'REQUESTS',
      isOpened: false,
      hasChild: true,
      childs: [
        {
          title: 'SIMULATION_REQUESTS',
          isOpened: false,
          hasChild: false,
          route: '/admin/simulation-requests',
        },
        {
          title: 'LOG_REQUESTS',
          isOpened: false,
          hasChild: false,
          route: '/admin/log-requests',
        },
      ],
    },
    {
      title: 'SETTINGS',
      icon: 'settings',
      tooltip: 'SETTINGS',
      isOpened: false,
      hasChild: true,
      canAccessClaims: [Claims.USER_MANAGEMENT_READ, Claims.USER_MANAGEMENT_WRITE, Claims.ROLE_MANAGEMENT_READ, Claims.ROLE_MANAGEMENT_WRITE],
      childs: [
        {
          title: 'USER_MANAGEMENT',
          icon: 'person_add',
          isOpened: false,
          hasChild: false,
          route: '/admin/users',
          canAccessClaims: [Claims.USER_MANAGEMENT_READ],
        },
        {
          title: 'ROLE_MANAGEMENT',
          icon: 'health_and_safety',
          isOpened: false,
          hasChild: false,
          route: '/admin/roles',
          canAccessClaims: [Claims.ROLE_MANAGEMENT_READ],
        },
      ],
    },
  ];

  currentUser: any;

  @ViewChildren('expansionGroup')
  expansionGroups!: QueryList<MatExpansionPanel>;

  @Output() closeSidenav = new EventEmitter<void>();

  isHandset = true;

  mobileQuery!: Subscription;

  isExpanded = true;

  animationState: 'in' | 'out' = 'in';

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _router: Router,
    private readonly _sharedService: SharedService,
    private readonly _authService: AuthService,
    private readonly _breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.mobileQuery = this._breakpointObserver.observe(['(max-width: 600px)']).subscribe(state => {
      if (state.matches) {
        this.isExpanded = true;
      }
      this.isHandset = state.matches;
    });
    this.currentUser = this._sharedService.getUserInformations();
  }

  ngOnDestroy(): void {
    this.mobileQuery.unsubscribe();
  }

  checkIfAnySubItemSelected(group: any) {
    return null; //group.items.some(e => this.currentRoute === e.route)
  }

  public changeLanguage(language: string) {
    this._translateService.use(language);
  }

  get currentRoute() {
    return this._router.url;
  }

  get width() {
    return this.isExpanded ? 256 : 68;
  }

  get toggleButtonTransformExp() {
    return this.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)';
  }

  toggle() {
    this.expansionGroups.forEach(g => g.close());
    this.animationState = this.animationState == 'in' ? 'out' : 'in';
    this.isExpanded = !this.isExpanded;
  }

  onExpandCollapseClick() {
    if (this.isHandset) this.closeSidenav.emit();
    else this.toggle();
  }

  onRouterLink(row: any) {
    if (this.currentRoute === row.route) return;

    if (this.isHandset) {
      this.closeSidenav.emit();
    }
  }

  logout() {
    this._authService.logout().subscribe(response => {
      if (response.success) {
        this._sharedService.removeUserToken();
        this._router.navigate(['/login']);
      }
    });
  }
}
