import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateTransformerPowerPercentage',
})
export class CalculateTransformerPowerPercentagePipe implements PipeTransform {
  transform(value: any, transformerMaxValues: any, transformerValues: any): number {
    let indexOfValue = transformerMaxValues.indexOf(Math.max(...transformerMaxValues));
    let transformerValue = null;
    if (transformerValues?.length > 0) {
      transformerValue = transformerValues[indexOfValue];
    } else {
      transformerValue = transformerValues;
    }
    return ((Math.max(...transformerMaxValues) / 1000) * 100) / transformerValue;
  }
}
