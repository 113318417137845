import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.css'],
})
export class ThemeSwitcherComponent implements OnInit {
  private static readonly DARK_THEME_CLASS = 'dark-theme';
  private static readonly LIGHT_MODE_ICON = 'light_mode';
  private static readonly DARK_MODE_ICON = 'dark_mode';
  currentIcon = ThemeSwitcherComponent.DARK_MODE_ICON;
  currentIconColor = ThemeSwitcherComponent.LIGHT_MODE_ICON ? '#000000' : '#ffffff';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public switchTheme() {
    var isDarkThemeValid = this.document.documentElement.classList.contains(ThemeSwitcherComponent.DARK_THEME_CLASS);
    if (isDarkThemeValid) {
      this.document.documentElement.classList.remove(ThemeSwitcherComponent.DARK_THEME_CLASS);
      this.currentIcon = ThemeSwitcherComponent.DARK_MODE_ICON;
      this.currentIconColor = '#000000';
    } else {
      this.document.documentElement.classList.add(ThemeSwitcherComponent.DARK_THEME_CLASS);
      this.currentIcon = ThemeSwitcherComponent.LIGHT_MODE_ICON;
      this.currentIconColor = '#ffffff';
    }
  }

  ngOnInit(): void {}
}
