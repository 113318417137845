<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ simulationResult?.simulation?.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.SIMULATION_TIME' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.simulationTime | convertDate | cropDate }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.TITLE' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.simulation?.title!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.ENERGY_WITH_DRAWN' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.totalEnergy!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.ENERGY_REGENERATED_KWh' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.energyRegenerated!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.REGENERATED_ENERGY_TO_SYSTEM_KWh' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.regeneratedEnergyToSystem!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.REGENERATED_ENERGY_TO_AUXILARY_KWh' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.regeneratedEnergyToAuxilary!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6">
        <strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.ENERGY_PER_VEHICLE_PER_KILOMETER' | translate }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.energyPerVehiclePerKilometer!! | convertToKwhVhcKm | getWithTwoFloatingPoints }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.ENERGY_BURNT_KWh' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.energyBurnt!! | convertToKwhVhcKm | trunc }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.LINE_LOSS_KWh' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">{{ simulationResult?.lineLoss!! | convertToKwhVhcKm | trunc }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.RECEPTIVITY_RATE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">{{ simulationResult?.receptivityRate!! | convertRatio }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'ENERGY_PREVIEW.RECUPERATION_RATIO' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationResult?.recuperationRatio!! | convertRatio }}</mat-card>
    </div>
  </div>
</div>
