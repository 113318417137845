<div>
  <ng-container *ngIf="station != null">
    <strong>{{ station.name }} Station</strong>
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">Name</th>
          <td>{{ station.name }}</td>
        </tr>
        <tr>
          <th scope="row">Short Name</th>
          <td>{{ station.shortName }}</td>
        </tr>
        <tr>
          <th scope="row">Length</th>
          <td>{{ station.length }}</td>
        </tr>
        <tr>
          <th scope="row">Position</th>
          <td>{{ station.position }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="trackTransformer != null">
    <strong>{{ trackTransformer.name }} Transformer</strong>
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">Name</th>
          <td>{{ trackTransformer.name }}</td>
        </tr>
        <tr>
          <th scope="row">Short Name</th>
          <td>{{ trackTransformer.shortName }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="feederCable != null">
    <strong>{{ feederCable.name }} Feeder Cable</strong>
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">Line No</th>
          <td>{{ feederCable.lineNo }}</td>
        </tr>
        <tr>
          <th scope="row">Resistance</th>
          <td>{{ feederCable.resistance }}</td>
        </tr>
        <tr>
          <th scope="row">Location</th>
          <td>{{ feederCable.location }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <button mat-raised-button (click)="closeSnackBar()">Close</button>
</div>
