/* 
  Mouse modes list
*/
export enum MouseModes {
  Auto = 'auto',
  Move = 'move',
  Cross = 'cross',
  Crosshair = 'crosshair',
  ColResize = 'col-resize',
  RowResize = 'row-resize',
  NeswResize = 'nesw-resize',
  Pointer = 'pointer',
  Default = 'default',
  Cell = 'cell',
}

export enum MouseEvents {
  Click = 'click',
  MouseMove = 'mousemove',
}

/* 
  Zoom raito values
*/
export enum ZoomRatios {
  ZoomOut = 0.9,
  ZoomIn = 1.1,
}

/* 
  Zoom axis values
*/
export enum ZoomModes {
  X = 'x',
  Y = 'y',
  Both = 'xy',
}

/* 
  Visibility values
*/
export enum VisibilityModes {
  Visible = 'visible',
  Hidden = 'hidden',
}

/* 
  Plugin values
*/
export enum Plugins {
  Brush = 'brush',
  Corsair = 'corsair',
  Ruler = 'ruler',
  Text = 'text',
  Expand = 'expand',
  ZoomIn = 'zoom_in',
  ZoomOut = 'zoom_out',
  Examine = 'tooltip', //tooltip
  Gestures = 'gestures',
  LockPanel = 'lock',
  Hide = 'hide',
  Background = 'background',
}

/* 
  Export options
*/
export enum ExportModes {
  JPEG = 'jpeg',
  PNG = 'png',
}
