import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { ReportService } from 'src/app/services/report.service';
import { AddSimulationReportAction, AddSimulationReportActionError, LoadSimulationReportDatas, SimulationReportActionTypes } from '../actions/report.action';

@Injectable()
export class SimulationReportEffects {
  constructor(
    private actions$: Actions,
    private readonly _reportService: ReportService,
    private readonly _store: Store<AppState>
  ) {}

  loadReports$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadSimulationReportDatas>(SimulationReportActionTypes.LOAD_SIMULATION_REPORT),
      withLatestFrom(this._store.select(state => state.railState.rails)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize))),
      switchMap(action => {
        return this._reportService.getReports(true, action[0].pageIndex, action[0].pageSize).pipe(
          map(
            response =>
              new AddSimulationReportAction(
                response.data!!,
                {
                  totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                } as HttpCommonHeaders,
                action[0].pageSize,
                action[0].pageIndex,
                null
              )
          ),
          catchError(errorMessage => of(new AddSimulationReportActionError(null, errorMessage)))
        );
      })
    )
  );
}
