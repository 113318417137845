<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ simulationResult?.title }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.TITLE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.title!! }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.MAX_POWER' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6" [ngSwitch]="filterStyle">
        <ng-container *ngSwitchCase="'asKw'">
          {{ simulationResult?.transformerMaxPowerskW | findMax | convertToKw | trunc }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ simulationResult?.transformerMaxPowerskW | calculateTransformerPowerPercentage: simulationResult.transformerMaxPowerskW : simulationResult?.transformerPowers | trunc }}
        </ng-container>
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.PSS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.transformerMaxPowerskW | transformerName: simulationResult.transformerMaxPowerskW : simulationResult?.transformerNames }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.TRANSFORMER_MAX_RMS_POWERS_1_MIN' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6" [ngSwitch]="filterStyle">
        <ng-container *ngSwitchCase="'asKw'">
          {{ simulationResult?.transformerMaxRMSPowerskW | findMax | convertToKw | trunc }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ simulationResult?.transformerMaxRMSPowerskW | calculateTransformerPowerPercentage: simulationResult.transformerMaxRMSPowerskW : simulationResult?.transformerPowers | trunc }}
        </ng-container>
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.PSS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult?.transformerMaxRMSPowerskW | transformerName: simulationResult.transformerMaxPowerskW : simulationResult?.transformerNames }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.TRANSFORMER_MAX_1_MIN_RMS_POWERS_1_HOUR' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6" [ngSwitch]="filterStyle">
        <ng-container *ngSwitchCase="'asKw'">
          {{ simulationResult?.transformerMax1MinRMSPowerskW | findMax | convertToKw | trunc }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{
            simulationResult?.transformerMax1MinRMSPowerskW
              | calculateTransformerPowerPercentage: simulationResult.transformerMax1MinRMSPowerskW : simulationResult?.transformerPowers
              | trunc
          }}
        </ng-container>
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.PSS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult.transformerMax1MinRMSPowerskW | transformerName: simulationResult.transformerMaxPowerskW : simulationResult?.transformerNames }}
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.TRANSFORMER_MAX_1_HOUR_RMS_POWERS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6" [ngSwitch]="filterStyle">
        <ng-container *ngSwitchCase="'asKw'">
          {{ simulationResult?.transformerMax1HrRMSPowerskW | findMax | convertToKw | trunc }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{
            simulationResult?.transformerMax1HrRMSPowerskW | calculateTransformerPowerPercentage: simulationResult.transformerMax1HrRMSPowerskW : simulationResult?.transformerPowers | trunc
          }}
        </ng-container>
      </mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'PSS_SUMMARY_PREVIEW.PSS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationResult.transformerMax1HrRMSPowerskW | transformerName: simulationResult.transformerMaxPowerskW : simulationResult?.transformerNames }}
      </mat-card>
    </div>
    <mat-divider style="height: 5px" class="my-3 divider"></mat-divider>
    <p class="text-center">
      <strong>Transformer Max Powers (kW)</strong>
    </p>
    <div class="d-flex flex-row row" *ngFor="let transformerName of simulationResult?.transformerNames; let i = index">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ transformerName }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerName | transformerPercentage: i : simulationResult.transformerMaxPowerskW : simulationResult?.transformerPowers | trunc }}%
      </mat-card>
    </div>
    <mat-divider style="height: 5px" class="my-3 divider"></mat-divider>
    <p class="text-center">
      <strong>Transformer Max RMS Powers (kW)</strong>
    </p>
    <div class="d-flex flex-row row" *ngFor="let transformerName of simulationResult?.transformerNames; let i = index">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ transformerName }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerName | transformerPercentage: i : simulationResult.transformerMaxRMSPowerskW : simulationResult?.transformerPowers | trunc }}%
      </mat-card>
    </div>
    <mat-divider style="height: 5px" class="my-3 divider"></mat-divider>
    <p class="text-center">
      <strong>Transformer Max. 1 Min RMS Powers(kW)</strong>
    </p>
    <div class="d-flex flex-row row" *ngFor="let transformerName of simulationResult?.transformerNames; let i = index">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ transformerName }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerName | transformerPercentage: i : simulationResult.transformerMax1MinRMSPowerskW : simulationResult?.transformerPowers | trunc }}%
      </mat-card>
    </div>
    <mat-divider style="height: 5px" class="my-3 divider"></mat-divider>
    <p class="text-center">
      <strong>Transformer Max. 1 Hr RMS Powers(kW)</strong>
    </p>
    <div class="d-flex flex-row row" *ngFor="let transformerName of simulationResult?.transformerNames; let i = index">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ transformerName }}</strong>
      </mat-card>
      <mat-card class="my-1 col-12 col-md-6">
        {{ transformerName | transformerPercentage: i : simulationResult.transformerMax1HrRMSPowerskW : simulationResult?.transformerPowers | trunc }}%
      </mat-card>
    </div>
  </div>
</div>
