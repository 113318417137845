import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCompanyDto } from '../dtos/company/getCompanyDto';
import { ResponseModel } from '../dtos/http/responseModel';
import { AddCompanyDto } from '../dtos/company/addCompanyDto';
import { UpdateCompanyDto } from '../dtos/company/updateCompanyDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getCompanies(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetCompanyDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetCompanyDto[]>>(`${this._configService.getBaseUrl()}/companies`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetCompanyDto[]>
        )
      );
  }

  getCompany(id: number): Observable<ResponseModel<GetCompanyDto>> {
    return this._httpClient.get<ResponseModel<GetCompanyDto>>(`${this._configService.getBaseUrl()}/companies/${id}`);
  }

  addCompany(company: AddCompanyDto): Observable<ResponseModel<GetCompanyDto>> {
    return this._httpClient.post<ResponseModel<GetCompanyDto>>(`${this._configService.getBaseUrl()}/companies`, company);
  }

  updateCompany(companyId: number, company: UpdateCompanyDto): Observable<ResponseModel<GetCompanyDto>> {
    return this._httpClient.put<ResponseModel<GetCompanyDto>>(`${this._configService.getBaseUrl()}/companies/${companyId}`, company);
  }

  deleteCompany(id: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/companies/${id}`);
  }

  filterCompanies(query?: string): Observable<GetCompanyDto[]> {
    return this._httpClient.get<GetCompanyDto[]>(`${this._configService.getBaseUrl()}/companies/filter?${query}`);
  }
}
