import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToKm',
})
export class ConvertToKmPipe implements PipeTransform {
  transform(meter: number): number {
    return meter / 1000;
  }
}
