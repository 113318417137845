import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetCableDto } from '../dtos/cable/getCableDto';
import { UpdateCableDto } from '../dtos/cable/updateCableDto';
import { AddCableDto } from '../dtos/cable/addCableDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class CableService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getCable(id: number): Observable<ResponseModel<GetCableDto>> {
    return this._httpClient.get<ResponseModel<GetCableDto>>(`${this._configService.getBaseUrl()}/cables/${id}`);
  }

  getCables(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetCableDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetCableDto[]>>(`${this._configService.getBaseUrl()}/cables`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetCableDto[]>
        )
      );
  }

  addCable(cable: AddCableDto): Observable<ResponseModel<GetCableDto>> {
    return this._httpClient.post<ResponseModel<GetCableDto>>(`${this._configService.getBaseUrl()}/cables`, cable);
  }

  updateCable(id: number, cable: UpdateCableDto): Observable<ResponseModel<GetCableDto>> {
    return this._httpClient.put<ResponseModel<GetCableDto>>(`${this._configService.getBaseUrl()}/cables/${id}`, cable);
  }

  deleteCable(cableId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/cables/${cableId}`);
  }

  deleteCables(cableIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/cables`, { params: { 'cable-id': cableIds } });
  }

  filterCables(query?: string): Observable<GetCableDto[]> {
    return this._httpClient.get<GetCableDto[]>(`${this._configService.getBaseUrl()}/cables/filter?${query}`);
  }
}
