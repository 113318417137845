import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScopeTooltipItem } from 'src/app/models/component-models/scopeModel';
import { Plugins, VisibilityModes } from '../scope-tab/chart/enums';
import { DrawingItem, PluginType } from '../scope-tab/chart/types';

@Component({
  selector: 'app-scope-tooltip',
  templateUrl: './scope-tooltip.component.html',
  styleUrls: ['./scope-tooltip.component.css'],
})
export class ScopeTooltipComponent implements OnInit {
  @ViewChild('item')
  item!: ElementRef;

  @Input()
  drawingItems!: DrawingItem[];

  @Input()
  chartOptions!: any;

  @Input()
  currentPlugin!: PluginType;

  @Output()
  onTooltipVisibilityChanged = new EventEmitter();

  //line color
  //line background
  //text color
  //line width
  //settings
  //lock
  //remove
  //more

  //text color
  //text size
  //settings
  //lock
  //remove
  //more

  tooltipMenu: ScopeTooltipItem[] = [
    {
      icon: 'edit',
      tooltip: 'Drawing color',
      isVisible: () => this.currentPlugin == Plugins.Text,
      clickEvent: () => console.log('open tooltip'),
    },
    {
      icon: 'format_color_fill',
      tooltip: 'Drawing background color',
      isVisible: () => this.currentPlugin == Plugins.Brush,
      clickEvent: () => console.log('open tooltip'),
    },
    {
      icon: 'title',
      tooltip: 'Drawing text',
      isVisible: () => this.currentPlugin == Plugins.Text,
      clickEvent: () => console.log('open tooltip'),
    },
    {
      icon: 'settings',
      tooltip: 'Settings',
      isVisible: () => this.currentPlugin == Plugins.Text || this.currentPlugin == Plugins.Brush,
      clickEvent: () => this.openSettings(),
    },
    {
      icon: 'lock',
      tooltip: 'Lock',
      isVisible: () => this.currentPlugin == Plugins.Text || this.currentPlugin == Plugins.Brush,
      clickEvent: () => console.log('open tooltip'),
    },
    {
      icon: 'delete',
      tooltip: 'Delete drawing',
      isVisible: () => this.currentPlugin == Plugins.Text || this.currentPlugin == Plugins.Brush,
      clickEvent: () => this.tooltipDeleteDrawing(),
    },
    {
      icon: 'more_horiz',
      tooltip: 'More',
      isVisible: () => this.currentPlugin == Plugins.Text || this.currentPlugin == Plugins.Brush,
      clickEvent: () => console.log('open tooltip'),
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  tooltipDeleteDrawing() {
    this.drawingItems.find(item => item.id === this.chartOptions.plugins.text.tooltip.drawingId)!.isDeleted = true;
    this.makeTooltipInvisible();
  }

  makeTooltipInvisible() {
    this.item.nativeElement.style.visibility = VisibilityModes.Hidden;
    this.onTooltipVisibilityChanged.emit(VisibilityModes.Hidden);
  }

  makeTooltipVisible() {
    this.item.nativeElement.style.visibility = VisibilityModes.Visible;
    this.onTooltipVisibilityChanged.emit(VisibilityModes.Hidden);
  }

  openSettings() {
    this.makeTooltipInvisible();
    this.onTooltipVisibilityChanged.emit(VisibilityModes.Visible);
  }
}
