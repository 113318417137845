import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { Scope } from 'src/app/models/response-models/scope';
import { AppState } from 'src/app/models/state/appState';
import { DataLogService } from 'src/app/services/data-log.service';
import { AddDataLogAction, AddDataLogActionError, DataLogActionTypes } from '../actions/data-log.action';

@Injectable()
export class DataLogEffects {
  constructor(
    private actions$: Actions,
    private readonly _dataLogService: DataLogService,
    private readonly _store: Store<AppState>
  ) {}

  loadDataLog$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(DataLogActionTypes.LOAD_DATA_LOG),
      withLatestFrom(this._store.select(state => state.dataLogState.dataLog)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._dataLogService.getDataLog('465b7247-1859-47a0-a81f-1caaf52109c9', 'e8fb3b1f-631b-4869-8911-6d3a04bc655d', '6d14f482-f9ca-45fb-af42-483c828dd958').pipe(
          map(response => new AddDataLogAction(response.data as Scope, null)),
          catchError(errorMessage => of(new AddDataLogActionError(null, errorMessage)))
        );
      })
    )
  );
}
