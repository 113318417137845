import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { AppState, HttpCommonHeaders } from 'src/app/models/state/appState';
import { CableService } from 'src/app/services/cable.service';
import { AddCableAction, AddCableActionError, LoadCableDatas, CableActionTypes } from '../actions/cable.action';

@Injectable()
export class CableEffects {
  constructor(
    private actions$: Actions,
    private readonly _cableService: CableService,
    private readonly _store: Store<AppState>
  ) {}

  loadCables$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType<LoadCableDatas>(CableActionTypes.LOAD_CABLE),
      withLatestFrom(this._store.select(state => state.cableState.cables)),
      filter(([action, hasLoaded]) => !hasLoaded || (hasLoaded && (hasLoaded.pageIndex != action.pageIndex || hasLoaded.pageSize != action.pageSize || hasLoaded.query != action.query))),
      switchMap(action => {
        return !action[0].query
          ? this._cableService.getCables(true, action[0].pageIndex, action[0].pageSize).pipe(
              map(
                response =>
                  new AddCableAction(
                    response.data!!,
                    {
                      totalCount: parseInt(response.headers?.get('X-Total-Count')!!),
                    } as HttpCommonHeaders,
                    action[0].pageSize,
                    action[0].pageIndex,
                    null
                  )
              ),
              catchError(errorMessage => of(new AddCableActionError(null, errorMessage)))
            )
          : this._cableService.filterCables(action[0].query).pipe(
              map(response => new AddCableAction(response!!, { totalCount: 10 } as HttpCommonHeaders, action[0].pageSize, action[0].pageIndex, null)),
              catchError(errorMessage => of(new AddCableActionError(null, errorMessage)))
            );
      })
    )
  );
}
