import { Action } from '@ngrx/store';
import { GetCompanyDto } from 'src/app/dtos/company/getCompanyDto';

export enum CompanyActionTypes {
  ADD_COMPANY = '[Company] Add Company',
  ADD_COMPANY_ERROR = '[Company] Add Company Error',
  LOAD_COMPANY = '[Company] Load Company',
}

export class AddCompanyAction implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY;

  constructor(
    public payload: GetCompanyDto[],
    public error: null
  ) {}
}

export class AddCompanyActionError implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadCompanyDatas implements Action {
  readonly type = CompanyActionTypes.LOAD_COMPANY;

  constructor() {}
}

export type CompanyAction = LoadCompanyDatas | AddCompanyAction | AddCompanyActionError;
