import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlayerDatas } from '../models/response-models/playerDatas';
import { ResponseModel } from '../dtos/http/responseModel';
import { ScopePreview } from '../models/response-models/scopePreview';
import { GetSimulationRequestDto } from '../dtos/simulation-request/getSimulationRequestDto';
import { GetSimulationDto } from '../dtos/simulation/getSimulationDto';
import { ConfigService } from './config.service';
import { UpdateSimulationDto } from '../dtos/simulation/updateSimulationDto';

@Injectable({
  providedIn: 'root',
})
export class SimulationService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  getSimulationsBySimulationSetIds(simulationSetIds: number[]): Observable<ResponseModel<GetSimulationDto[]>> {
    return this._httpClient.get<ResponseModel<GetSimulationDto[]>>(`${this._configService.getBaseUrl()}/simulations`, { params: { 'simulation-set-id': simulationSetIds } });
  }

  updateSimulation(id: number, simulation: UpdateSimulationDto): Observable<ResponseModel<GetSimulationDto>> {
    return this._httpClient.put<ResponseModel<GetSimulationDto>>(`${this._configService.getBaseUrl()}/simulations/${id}`, simulation);
  }

  getSimulationScopePreviewDatasBySimulationId(simulationId: number, itemType: string): Observable<ResponseModel<ScopePreview[]>> {
    return this._httpClient.get<ResponseModel<ScopePreview[]>>(`${this._configService.getBaseUrl()}/simulations/${simulationId}/scope-previews`, { params: { 'item-type': itemType } });
  }

  getSimulationPlayerDatas(simulationIds: number[], pagination: boolean = false, pageIndex: number = 0, pageSize: number = 25): Observable<ResponseModel<PlayerDatas[]>> {
    return this._httpClient
      .get<ResponseModel<PlayerDatas[]>>(`${this._configService.getBaseUrl()}/simulations/players`, {
        params: {
          'simulation-id': simulationIds,
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<PlayerDatas[]>
        )
      );
  }

  cancelBySimulationIds(simulationIds: number[]): Observable<ResponseModel<GetSimulationRequestDto>> {
    return this._httpClient.put<ResponseModel<GetSimulationRequestDto>>(`${this._configService.getBaseUrl()}/simulations/cancel-request`, {}, { params: { 'simulation-id': simulationIds } });
  }

  filterSimulations(query?: string): Observable<GetSimulationDto[]> {
    return this._httpClient.get<GetSimulationDto[]>(`${this._configService.getBaseUrl()}/simulations/filter?${query}`);
  }
}
