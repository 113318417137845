import { CableState } from 'src/app/models/state/appState';
import { CableAction, CableActionTypes } from '../actions/cable.action';

const initialCableState: CableState = {
  isCablesLoaded: false,
};

export function CableReducer(state: CableState = initialCableState, action: CableAction | any): any {
  switch (action.type) {
    case CableActionTypes.ADD_CABLE:
      return {
        ...state,
        cables: {
          data: action.payload,
          pageSize: action.pageSize,
          pageIndex: action.pageIndex,
          totalCount: action.headers.totalCount,
        },
        cableError: null,
      };
    case CableActionTypes.ADD_CABLE_ERROR:
      return {
        ...state,
        cables: null,
        cableError: action.error,
      };
    case CableActionTypes.LOAD_CABLE:
      return {
        ...state,
        isCablesLoaded: true,
        cableError: null,
      };
    case CableActionTypes.CLEAR_CABLE:
      return {
        ...initialCableState,
      };
    default:
      return state;
  }
}
