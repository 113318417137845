export interface IMimeType {
  name: string;
  type: string;
  extension: string;
  icon: string;
}

export const MimeTypes: IMimeType[] = [
  {
    name: 'PDF',
    type: 'application/pdf',
    extension: '.pdf',
    icon: 'assets/images/pdf.svg',
  },
  {
    name: 'Excel',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.pdf',
    icon: 'assets/images/pdf.svgf',
  },
  {
    name: 'Word',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
    icon: 'assets/images/word.svg',
  },
  {
    name: 'Zip',
    type: 'application/octet-stream',
    extension: '.zip',
    icon: 'assets/images/zip.svg',
  },
];
