export enum SimulationStatusEnum {
  Requested = 'Requested',
  Handling = 'Handling',
  InQueue = 'InQueue',
  Loading = 'Loading',
  Configuring = 'Configuring',
  Running = 'Running',
  Terminating = 'Terminating',
  Finished = 'Finished',
  Uploading = 'Uploading',
  Downloading = 'Downloading',
  FinishedWithError = 'FinishedWithError',
  FinishedByServer = 'FinishedByServer',
  Cancelling = 'Cancelling',
  CancelledByUser = 'CancelledByUser',
  Aborted = 'Aborted',
}
