import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OutputEnum } from 'src/app/enums/outputEnum';
import { OutputExtensionEnum } from 'src/app/enums/outputExtensionEnum';
import { OutputExtensionType } from 'src/app/types/outputExtensionType';
import { OutputType } from 'src/app/types/outputType';

@Component({
  selector: 'app-table-top-menu',
  templateUrl: './table-top-menu.component.html',
  styleUrls: ['./table-top-menu.component.css'],
})
export class TableTopMenuComponent implements OnInit {
  OutputEnum = OutputEnum;
  OutputExtensionEnum = OutputExtensionEnum;

  @Output() applyFilter = new EventEmitter<string>();
  @Output() export = new EventEmitter<{
    outputType: OutputType;
    outputExtension: OutputExtensionType;
  }>();

  constructor() {}

  ngOnInit(): void {}

  applyFilterEvent(event: any) {
    const filterValue = event.target.value;
    this.applyFilter.emit(filterValue.trim().toLowerCase());
  }

  exportEvent(outputType: OutputType, outputExtension: OutputExtensionType) {
    this.export.emit({ outputType, outputExtension });
  }
}
