import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/state/appState';
import { PssSummaryDatas } from 'src/app/models/response-models/pssSummaryDatas';

@Component({
  selector: 'app-pss-summary-preview-dialog',
  templateUrl: './pss-summary-preview-dialog.component.html',
  styleUrls: ['./pss-summary-preview-dialog.component.css'],
})
export class PssSummaryPreviewDialogComponent implements OnInit {
  simulationResult!: PssSummaryDatas;
  filterStyle!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      simulationResultId: number;
      filterStyle: string;
    },
    private readonly store: Store<AppState>
  ) {
    this.filterStyle = data.filterStyle;
    this.store
      .select(store => store.simulationResultState.pssSummary)
      .subscribe(simulationResults => {
        this.simulationResult = simulationResults!.find(sim => sim.id === data.simulationResultId)!;
      });
  }

  ngOnInit(): void {}
}
