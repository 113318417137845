import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetLogRequestDto } from '../dtos/log-request/getLogRequestDto';
import { AddLogRequestDto } from '../dtos/log-request/addLogRequestDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class LogRequestService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  addLogRequest(addLogRequestDto: AddLogRequestDto): Observable<ResponseModel<GetLogRequestDto>> {
    return this._httpClient.post<ResponseModel<GetLogRequestDto>>(`${this._configService.getBaseUrl()}/log-requests`, addLogRequestDto);
  }

  getLogRequest(id: number): Observable<ResponseModel<GetLogRequestDto>> {
    return this._httpClient.get<ResponseModel<GetLogRequestDto>>(`${this._configService.getBaseUrl()}/log-requests/${id}`);
  }

  getLogRequests(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetLogRequestDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetLogRequestDto[]>>(`${this._configService.getBaseUrl()}/log-requests`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetLogRequestDto[]>
        )
      );
  }

  cancelByLogRequestId(logRequestId: number): Observable<ResponseModel<GetLogRequestDto>> {
    return this._httpClient.post<ResponseModel<GetLogRequestDto>>(`${this._configService.getBaseUrl()}/log-requests/${logRequestId}/cancel`, null);
  }

  cancelByLogRequestIds(logRequestIds: number[]): Observable<ResponseModel<GetLogRequestDto[]>> {
    return this._httpClient.post<ResponseModel<GetLogRequestDto[]>>(`${this._configService.getBaseUrl()}/log-requests/cancel`, { params: { 'log-request-id': logRequestIds } });
  }

  deleteLogRequest(logRequestId: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/log-requests/${logRequestId}`);
  }

  deleteLogRequests(logRequestIds: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/log-requests`, { params: { 'log-request-id': logRequestIds } });
  }

  filterLogRequests(query?: string): Observable<GetLogRequestDto[]> {
    return this._httpClient.get<GetLogRequestDto[]>(`${this._configService.getBaseUrl()}/log-requests/filter?${query}`);
  }
}
