import { SimulationState } from 'src/app/models/state/appState';
import { SimulationAction, SimulationActionTypes } from '../actions/simulation.actions';

const initialSimulationState: SimulationState = {
  isSimulationsLoaded: false,
  isPlayerLoaded: false,
};

export function SimulationReducer(state: SimulationState = initialSimulationState, action: SimulationAction | any): any {
  switch (action.type) {
    case SimulationActionTypes.ADD_SIMULATION:
      return {
        ...state,
        simulations: action.payload,
        simulationError: null,
      };
    case SimulationActionTypes.ADD_SIMULATION_ERROR:
      return {
        ...state,
        simulations: null,
        simulationError: action.error,
      };
    case SimulationActionTypes.ADD_PLAYER:
      return {
        ...state,
        player: action.payload,
        playerError: null,
      };
    case SimulationActionTypes.ADD_PLAYER_ERROR:
      return {
        ...state,
        player: null,
        playerError: action.error,
      };
    case SimulationActionTypes.LOAD_SIMULATION_PLAYER_DATAS:
      return {
        ...state,
        isPlayerLoaded: true,
        playerError: null,
      };
    case SimulationActionTypes.REMOVE_ALL_SIMULATION_DATAS:
      return {
        ...initialSimulationState,
      };
    default:
      return state;
  }
}
