import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../dtos/http/responseModel';
import { GetSimulationReportTemplateDto } from '../dtos/report/getSimulationReportTemplateDto';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplateService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService
  ) {}

  updateReportTemplate(id: number, reportBody: any): Observable<ResponseModel<GetSimulationReportTemplateDto>> {
    return this._httpClient.put<ResponseModel<GetSimulationReportTemplateDto>>(`${this._configService.getBaseUrl()}/report-templates/${id}`, reportBody);
  }

  getReportTemplateById(id: number): Observable<ResponseModel<GetSimulationReportTemplateDto>> {
    return this._httpClient.get<ResponseModel<GetSimulationReportTemplateDto>>(`${this._configService.getBaseUrl()}/report-templates/${id}`);
  }

  getReportTemplates(pagination = false, pageIndex = 0, pageSize = 25): Observable<ResponseModel<GetSimulationReportTemplateDto[]>> {
    return this._httpClient
      .get<ResponseModel<GetSimulationReportTemplateDto[]>>(`${this._configService.getBaseUrl()}/report-templates`, {
        params: {
          pagination,
          'page-index': pageIndex,
          'page-size': pageSize,
        },
        observe: 'response',
      })
      .pipe(
        map(
          response =>
            ({
              ...response.body,
              headers: response.headers,
            }) as ResponseModel<GetSimulationReportTemplateDto[]>
        )
      );
  }

  downloadReportTemplate(id: number): Observable<object> {
    return this._httpClient.get<object>(`${this._configService.getBaseUrl()}/report-templates/${id}/download`, { responseType: 'blob' as 'json' });
  }

  removeReportTemplate(id: number): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/report-templates/${id}`);
  }

  bulkRemoveReportTemplate(ids: number[]): Observable<ResponseModel> {
    return this._httpClient.delete<ResponseModel>(`${this._configService.getBaseUrl()}/report-templates`, { params: { 'simulation-report-template-id': ids } });
  }

  uploadReportTemplate(reportTemplate: FormData): Observable<ResponseModel<GetSimulationReportTemplateDto>> {
    return this._httpClient.post<ResponseModel<GetSimulationReportTemplateDto>>(`${this._configService.getBaseUrl()}/report-templates`, reportTemplate);
  }

  filterReportTemplates(query?: string): Observable<GetSimulationReportTemplateDto[]> {
    return this._httpClient.get<GetSimulationReportTemplateDto[]>(`${this._configService.getBaseUrl()}/report-templates/filter?${query}`);
  }
}
