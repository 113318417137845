<label class="col-12 h-100 d-flex flex-row p-0" style="cursor: pointer" htmlFor="customFile">
  <ng-container *ngIf="file; else noFile">
    <mat-list class="col-12 p-0">
      <mat-list-item class="list-item border rounded">
        <img mat-list-icon [src]="selectedMimeType.icon" style="width: 50px !important; height: 50px !important; border-radius: 0px" />
        <div mat-line>{{ file.name }}</div>
        <div mat-line class="text-muted">{{ file.size / (1024 * 1024) | number: '1.2-2' }} MB / {{ file.size / (1024 * 1024) | number: '1.2-2' }} MB</div>
        <button mat-icon-button color="accent" (click)="deleteFile()">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </ng-container>
  <ng-template #noFile>
    <div style="border-style: 'dashed'" class="d-flex justify-content-center align-items-center flex-column col-12">
      <mat-icon class="material-icons-outlined">backup</mat-icon>
      <strong className="text-center">{{ 'UPLOAD_FILE' | translate: { inputTitle: inputTitle } }}</strong>
      <strong className="text-center">{{ 'MAX_UPLOAD_SIZE' | translate }}</strong>
      <p className="text-center">Supported file types: PDF, DOCX, ZIP, EXCEL</p>
      <input (change)="onFileChange($event)" class="ng-hide ml-2" id="input-file-id" type="file" style="display: none" [accept]="acceptFile" />
    </div>
  </ng-template>
</label>
