import { Action } from '@ngrx/store';
import { GetSimulationResultDto } from 'src/app/dtos/simulation-result/getSimulationResultDto';
import { FeederDatas } from 'src/app/models/response-models/feederDatas';
import { FeederSummaryDatas } from 'src/app/models/response-models/feederSummaryDatas';
import { Jumper } from 'src/app/models/response-models/jumper';
import { Operation } from 'src/app/models/response-models/operation';
import { Train } from 'src/app/models/response-models/train';
import { TransformersDatas } from 'src/app/models/response-models/transformersDatas';

export enum SimulationResultActionTypes {
  ADD_SIMULATION_RESULT_PREVIEW_DATAS = '[SimulationResult] Add Simulation Result Preview Datas',
  ADD_SIMULATION_RESULT_PREVIEW_DATAS_ERROR = '[SimulationResult] Add Simulation Result Preview Datas Error',
  LOAD_SIMULATION_RESULT_PREVIEW_DATAS = '[SimulationResult] Load Simulation Result Preview Datas',

  ADD_SIMULATION_RESULT_GENERAL_DATAS = '[SimulationResult] Add Simulation Result General Datas',
  ADD_SIMULATION_RESULT_GENERAL_DATAS_ERROR = '[SimulationResult] Add Simulation Result General Datas Error',
  LOAD_SIMULATION_RESULT_GENERAL_DATAS = '[SimulationResult] Load Simulation Result General Datas',

  ADD_SIMULATION_RESULT_ENERGY_DATAS = '[SimulationResult] Add Simulation Result Energy Datas',
  ADD_SIMULATION_RESULT_ENERGY_DATAS_ERROR = '[SimulationResult] Add Simulation Result Energy Datas Error',
  LOAD_SIMULATION_RESULT_ENERGY_DATAS = '[SimulationResult] Load Simulation Result Energy Datas',

  ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS = '[SimulationResult] Add Simulation Result PSS Summary Datas',
  ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS_ERROR = '[SimulationResult] Add Simulation Result PSS Summary Datas Error',
  LOAD_SIMULATION_RESULT_PSS_SUMMARY_DATAS = '[SimulationResult] Load Simulation Result PSS Summary Datas',

  ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS = '[SimulationResult] Add Simulation Result Transformers Datas',
  ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS_ERROR = '[SimulationResult] Add Simulation Result Transformers Datas Error',
  LOAD_SIMULATION_RESULT_TRANSFORMERS_DATAS = '[SimulationResult] Load Simulation Result Transformers Datas',

  ADD_SIMULATION_RESULT_FEEDERS_DATAS = '[SimulationResult] Add Simulation Result Feeder Datas',
  ADD_SIMULATION_RESULT_FEEDERS_DATAS_ERROR = '[SimulationResult] Add Simulation Result Feeder Datas Error',
  LOAD_SIMULATION_RESULT_FEEDERS_DATAS = '[SimulationResult] Load Simulation Result Feeders Datas',

  ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS = '[SimulationResult] Add Simulation Result Feeder Summary Datas',
  ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS_ERROR = '[SimulationResult] Add Simulation Result Feeder Summary Datas Error',
  LOAD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS = '[SimulationResult] Load Simulation Result Feeders Summary Datas',

  ADD_SIMULATION_RESULT_OPERATION_DATAS = '[SimulationResult] Add Simulation Result Operation Datas',
  ADD_SIMULATION_RESULT_OPERATION_DATAS_ERROR = '[SimulationResult] Add Simulation Result Operation Datas Error',
  LOAD_SIMULATION_RESULT_OPERATION_DATAS = '[SimulationResult] Load Simulation Result Operation Datas',

  ADD_SIMULATION_RESULT_JUMPER = '[SimulationResult] Add Simulation Result Jumper',
  ADD_SIMULATION_RESULT_JUMPER_ERROR = '[SimulationResult] Add Simulation Result Jumper Error',
  LOAD_SIMULATION_RESULT_JUMPERS_DATAS = '[SimulationResult] Load Simulation Result Jumpers Datas',

  ADD_SIMULATION_RESULT_TRAIN = '[SimulationResult] Add Simulation Result Train',
  ADD_SIMULATION_RESULT_TRAIN_ERROR = '[SimulationResult] Add Simulation Result Train Error',
  LOAD_SIMULATION_RESULT_TRAINS_DATAS = '[SimulationResult] Load Simulation Result Trains Datas',

  REMOVE_ALL_SIMULATION_RESULT_DATAS = '[SimulationResult] Remove All Simulation Result Datas',
}

export class AddSimulationResultPreviewDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_PREVIEW_DATAS;

  constructor(
    public payload: GetSimulationResultDto[],
    public error: null
  ) {}
}

export class AddSimulationResultPreviewDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_PREVIEW_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultPreviewDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PREVIEW_DATAS;

  constructor() {}
}

export class AddSimulationResultGeneralDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_GENERAL_DATAS;

  constructor(
    public payload: GetSimulationResultDto[],
    public error: null
  ) {}
}

export class AddSimulationResultGeneralDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_GENERAL_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultGeneralDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_GENERAL_DATAS;

  constructor() {}
}

export class AddSimulationResultEnergyDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_ENERGY_DATAS;

  constructor(
    public payload: GetSimulationResultDto[],
    public error: null
  ) {}
}

export class AddSimulationResultEnergyDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_ENERGY_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultEnergyDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_ENERGY_DATAS;

  constructor() {}
}

export class AddSimulationResultPssSummaryDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS;

  constructor(
    public payload: GetSimulationResultDto[],
    public error: null
  ) {}
}

export class AddSimulationResultPssSummaryDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_PSS_SUMMARY_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultPssSummaryDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_PSS_SUMMARY_DATAS;

  constructor() {}
}

export class AddSimulationResultTransformersDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS;

  constructor(
    public payload: TransformersDatas[],
    public error: null
  ) {}
}

export class AddSimulationResultTransformersDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRANSFORMERS_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultTransformersDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRANSFORMERS_DATAS;

  constructor() {}
}

export class AddSimulationResultFeederDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_DATAS;

  constructor(
    public payload: FeederDatas[],
    public error: null
  ) {}
}

export class AddSimulationResultFeederDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultFeedersDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_DATAS;

  constructor() {}
}

export class AddSimulationResultFeederSummaryDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS;

  constructor(
    public payload: FeederSummaryDatas[],
    public error: null
  ) {}
}

export class AddSimulationResultFeederSummaryDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultFeedersSummaryDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_FEEDERS_SUMMARY_DATAS;

  constructor() {}
}

export class AddSimulationResultJumperAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_JUMPER;

  constructor(
    public payload: Jumper[],
    public error: null
  ) {}
}

export class AddSimulationResultJumperActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_JUMPER_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultJumpersDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_JUMPERS_DATAS;

  constructor() {}
}

export class AddSimulationResultTrainAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRAIN;

  constructor(
    public payload: Train[],
    public error: null
  ) {}
}

export class AddSimulationResultTrainActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_TRAIN_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationResultTrainsDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_TRAINS_DATAS;

  constructor() {}
}

export class AddSimulationResultOperationDatasAction implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_OPERATION_DATAS;

  constructor(
    public payload: Operation[],
    public error: null
  ) {}
}

export class AddSimulationResultOperationDatasActionError implements Action {
  readonly type = SimulationResultActionTypes.ADD_SIMULATION_RESULT_OPERATION_DATAS_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationOperationsDatasAction implements Action {
  readonly type = SimulationResultActionTypes.LOAD_SIMULATION_RESULT_OPERATION_DATAS;

  constructor() {}
}

export class RemoveAllSimulationResultDatasAction implements Action {
  readonly type = SimulationResultActionTypes.REMOVE_ALL_SIMULATION_RESULT_DATAS;

  constructor() {}
}

export type SimulationResultAction =
  | AddSimulationResultPreviewDatasAction
  | AddSimulationResultPreviewDatasActionError
  | LoadSimulationResultPreviewDatasAction
  | AddSimulationResultGeneralDatasAction
  | AddSimulationResultGeneralDatasActionError
  | LoadSimulationResultGeneralDatasAction
  | AddSimulationResultEnergyDatasAction
  | AddSimulationResultEnergyDatasActionError
  | LoadSimulationResultEnergyDatasAction
  | AddSimulationResultPssSummaryDatasAction
  | AddSimulationResultPssSummaryDatasActionError
  | LoadSimulationResultPssSummaryDatasAction
  | AddSimulationResultTransformersDatasAction
  | AddSimulationResultTransformersDatasActionError
  | LoadSimulationResultTransformersDatasAction
  | AddSimulationResultFeederDatasAction
  | AddSimulationResultFeederDatasActionError
  | LoadSimulationResultFeedersDatasAction
  | AddSimulationResultFeederSummaryDatasAction
  | AddSimulationResultFeederSummaryDatasActionError
  | LoadSimulationResultFeedersSummaryDatasAction
  | AddSimulationResultJumperAction
  | AddSimulationResultJumperActionError
  | LoadSimulationResultJumpersDatasAction
  | AddSimulationResultTrainAction
  | AddSimulationResultTrainActionError
  | LoadSimulationResultTrainsDatasAction
  | AddSimulationResultOperationDatasAction
  | AddSimulationResultOperationDatasActionError
  | LoadSimulationOperationsDatasAction
  | RemoveAllSimulationResultDatasAction;
