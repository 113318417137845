import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GetLogRequestDto } from 'src/app/dtos/log-request/getLogRequestDto';
import { AppState } from 'src/app/models/state/appState';

@Component({
  selector: 'app-log-request-preview-dialog',
  templateUrl: './log-request-preview-dialog.component.html',
  styleUrls: ['./log-request-preview-dialog.component.css'],
})
export class LogRequestPreviewDialogComponent implements OnInit {
  logRequest!: GetLogRequestDto;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: { logRequestId: number },
    private readonly _store: Store<AppState>
  ) {
    this._store
      .select(store => store.logRequestState.logRequests)
      .subscribe(logRequests => {
        this.logRequest = logRequests?.data!.find(simReq => simReq.id === _data.logRequestId)!;
      });
  }

  ngOnInit(): void {}
}
