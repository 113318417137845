//Modules
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

//Components
import { FooterComponent } from 'src/app/components/common/footer/footer.component';
import { NoDataToListComponent } from '../../components/common/no-data-to-list/no-data-to-list.component';
import { TransformerPreviewDialogComponent } from '../../components/simulation-results/transformer-preview-dialog/transformer-preview-dialog.component';
import { SelectResultsPreviewDialogComponent } from '../../components/simulation-results/select-results-preview-dialog/select-results-preview-dialog.component';
import { FeederSummaryPreviewDialogComponent } from '../../components/simulation-results/feeder-summary-preview-dialog/feeder-summary-preview-dialog.component';
import { PssSummaryPreviewDialogComponent } from 'src/app/components/simulation-results/pss-summary-preview-dialog/pss-summary-preview-dialog.component';
import { TrainPreviewDialogComponent } from 'src/app/components/simulation-results/train-preview-dialog/train-preview-dialog.component';
import { GeneralPreviewDialogComponent } from 'src/app/components/simulation-results/general-preview-dialog/general-preview-dialog.component';
import { EnergyPreviewDialogComponent } from 'src/app/components/simulation-results/energy-preview-dialog/energy-preview-dialog.component';
import { PlayerInfoSnackBarComponent } from '../../components/simulation-results/player-info-snack-bar/player-info-snack-bar.component';
import { BreadcrumbComponent } from 'src/app/components/common/breadcrumb/breadcrumb.component';
import { UploadFileComponent } from 'src/app/components/common/upload-file/upload-file.component';
import { SideNavComponent } from 'src/app/components/common/side-nav/side-nav.component';
import { ConfirmationDialogComponent } from 'src/app/components/common/confirmation-dialog/confirmation-dialog.component';
import { ScopeSelectionDialogComponent } from 'src/app/components/simulation-results/scope/scope-selection-dialog/scope-selection-dialog.component';
import { SimulationReportPreviewComponent } from 'src/app/components/simulation-results/simulation-report-preview/simulation-report-preview.component';
import { ThemeSwitcherComponent } from 'src/app/components/common/theme-switcher/theme-switcher.component';
import { ToolbarComponent } from 'src/app/components/common/toolbar/toolbar.component';
import { OperationPreviewDialogComponent } from 'src/app/components/simulation-results/operation-preview-dialog/operation-preview-dialog.component';
import { TableSettingsMenuComponent } from 'src/app/components/common/table-settings-menu/table-settings-menu.component';
import { FeederPreviewDialogComponent } from 'src/app/components/simulation-results/feeder-preview-dialog/feeder-preview-dialog.component';
import { UpgradeLicenseComponent } from 'src/app/components/common/upgrade-license/upgrade-license.component';
import { SystemErrorComponent } from 'src/app/components/common/system-error/system-error.component';
import { ScopeSubTooltipComponent } from 'src/app/components/simulation-results/scope/scope-sub-tooltip/scope-sub-tooltip.component';
import { ScopeTooltipComponent } from 'src/app/components/simulation-results/scope/scope-tooltip/scope-tooltip.component';
import { ScopeSideMenuComponent } from 'src/app/components/simulation-results/scope/scope-side-menu/scope-side-menu.component';
import { JumpersPreviewDialogComponent } from 'src/app/components/simulation-results/jumpers-preview-dialog/jumpers-preview-dialog.component';
import { SimulationRequestPreviewDialogComponent } from 'src/app/components/request-results/simulation-request-preview-dialog/simulation-request-preview-dialog.component';
import { LogRequestPreviewDialogComponent } from 'src/app/components/request-results/log-request-preview-dialog/log-request-preview-dialog.component';
import { SimComboSelectionTreeComponent } from 'src/app/components/report/sim-combo-selection-tree/sim-combo-selection-tree.component';

//Pipes
import { ConvertDatePipe } from 'src/app/pipes/convert-date.pipe';
import { CropDatePipe } from 'src/app/pipes/crop-date.pipe';
import { ConvertToKwPipe } from 'src/app/pipes/convert-to-kw.pipe';
import { TruncPipe } from 'src/app/pipes/trunc.pipe';
import { FindMaxPipe } from 'src/app/pipes/find-max.pipe';
import { TransformerNamePipe } from 'src/app/pipes/transformer-name.pipe';
import { TransformerPercentagePipe } from 'src/app/pipes/transformer-percentage.pipe';
import { ConvertToKmPipe } from 'src/app/pipes/convert-to-km.pipe';
import { ConvertToKwhVhcKmPipe } from 'src/app/pipes/convert-to-kwh-vhc-km.pipe';
import { GetWithTwoFloatingPointsPipe } from 'src/app/pipes/get-with-two-floating-points.pipe';
import { ConvertRatioPipe } from 'src/app/pipes/convert-ratio.pipe';
import { CalculateTransformerPowerPercentagePipe } from 'src/app/pipes/calculate-transformer-power-percentage.pipe';
import { ConvertDoubleToDatePipe } from '../../pipes/convert-double-to-date.pipe';
import { CalculateThresholdPipe } from 'src/app/pipes/calculate-threshold.pipe';
import { PascalToSnakePipe } from 'src/app/pipes/pascal-to-snake.pipe';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { AppendPrefixPipe } from 'src/app/pipes/append-prefix.pipe';
import { TitleLetterPipe } from 'src/app/pipes/title-letter.pipe';

//Directives
import { ForClaimDirective } from 'src/app/directives/for-claim.directive';
import { TableSelectionActionBarComponent } from 'src/app/components/common/table-selection-action-bar/table-selection-action-bar.component';
import { TableTopMenuComponent } from 'src/app/components/common/table-top-menu/table-top-menu.component';

@NgModule({
  declarations: [
    //Directives
    ForClaimDirective,

    //Pipes
    TruncPipe,
    FindMaxPipe,
    SafePipe,
    TransformerNamePipe,
    TransformerPercentagePipe,
    ConvertDatePipe,
    CropDatePipe,
    ConvertToKwPipe,
    ConvertToKmPipe,
    ConvertDoubleToDatePipe,
    CalculateThresholdPipe,
    ConvertToKwhVhcKmPipe,
    GetWithTwoFloatingPointsPipe,
    ConvertRatioPipe,
    PascalToSnakePipe,
    AppendPrefixPipe,
    TitleLetterPipe,
    CalculateTransformerPowerPercentagePipe,

    //Components
    TransformerPreviewDialogComponent,
    UploadFileComponent,
    PssSummaryPreviewDialogComponent,
    SimComboSelectionTreeComponent,
    FooterComponent,
    UpgradeLicenseComponent,
    BreadcrumbComponent,
    NoDataToListComponent,
    TransformerPreviewDialogComponent,
    ToolbarComponent,
    TableSelectionActionBarComponent,
    TableTopMenuComponent,
    TrainPreviewDialogComponent,
    FeederPreviewDialogComponent,
    SimulationReportPreviewComponent,
    SystemErrorComponent,
    LogRequestPreviewDialogComponent,
    ScopeSelectionDialogComponent,
    SideNavComponent,
    GeneralPreviewDialogComponent,
    JumpersPreviewDialogComponent,
    ThemeSwitcherComponent,
    ConfirmationDialogComponent,
    TableSettingsMenuComponent,
    OperationPreviewDialogComponent,
    EnergyPreviewDialogComponent,
    SelectResultsPreviewDialogComponent,
    FeederSummaryPreviewDialogComponent,
    PlayerInfoSnackBarComponent,
    ScopeSubTooltipComponent,
    ScopeTooltipComponent,
    ScopeSideMenuComponent,
    SimulationRequestPreviewDialogComponent,
  ],
  imports: [
    //Modules
    CommonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSidenavModule,
    RouterModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatMenuModule,
    MatRippleModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatListModule,
    NgChartsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    HttpClientModule,
    MatCheckboxModule,
    MatStepperModule,
    TranslateModule,
    DragDropModule,
    MatTooltipModule,
    MatBadgeModule,
    MatButtonToggleModule,
  ],
  exports: [
    //Modules
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    DragDropModule,
    MatSlideToggleModule,
    RouterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDividerModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatRippleModule,
    MatListModule,
    MatTabsModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgChartsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatDatepickerModule,
    MatTreeModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCardModule,
    TranslateModule,
    MatBadgeModule,
    MatButtonToggleModule,

    //Components
    FooterComponent,
    ThemeSwitcherComponent,
    ToolbarComponent,
    SideNavComponent,
    FeederPreviewDialogComponent,
    SimulationReportPreviewComponent,
    ConfirmationDialogComponent,
    TableSelectionActionBarComponent,
    TableTopMenuComponent,
    UpgradeLicenseComponent,
    ScopeSelectionDialogComponent,
    UploadFileComponent,
    SimComboSelectionTreeComponent,
    TableSettingsMenuComponent,
    LogRequestPreviewDialogComponent,
    SystemErrorComponent,
    BreadcrumbComponent,
    OperationPreviewDialogComponent,
    JumpersPreviewDialogComponent,
    NoDataToListComponent,
    ScopeSubTooltipComponent,
    ScopeTooltipComponent,
    ScopeSideMenuComponent,
    SimulationRequestPreviewDialogComponent,

    //Pipes
    TruncPipe,
    FindMaxPipe,
    TransformerNamePipe,
    SafePipe,
    TransformerPercentagePipe,
    ConvertDatePipe,
    CropDatePipe,
    ConvertToKwPipe,
    TitleLetterPipe,
    ConvertToKmPipe,
    ConvertToKwhVhcKmPipe,
    GetWithTwoFloatingPointsPipe,
    ConvertRatioPipe,
    CalculateTransformerPowerPercentagePipe,
    ConvertDoubleToDatePipe,
    CalculateThresholdPipe,
    PascalToSnakePipe,
    AppendPrefixPipe,

    //Directives
    ForClaimDirective,
  ],
})
export class SharedModule {}
