import { Action } from '@ngrx/store';
import { GetRoleDto } from 'src/app/dtos/role/getRoleDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum RoleActionTypes {
  ADD_ROLE = '[Role] Add Role',
  ADD_ROLE_ERROR = '[Role] Add Role Error',
  LOAD_ROLE = '[Role] Load Role',
  CLEAR_ROLE = '[Role] Clear Role',
}

export class AddRoleAction implements Action {
  readonly type = RoleActionTypes.ADD_ROLE;

  constructor(
    public payload: GetRoleDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddRoleActionError implements Action {
  readonly type = RoleActionTypes.ADD_ROLE_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadRoleDatas implements Action {
  readonly type = RoleActionTypes.LOAD_ROLE;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearRoleDatas implements Action {
  readonly type = RoleActionTypes.CLEAR_ROLE;

  constructor() {}
}

export type RoleAction = LoadRoleDatas | AddRoleAction | AddRoleActionError | ClearRoleDatas;
