import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getWithTwoFloatingPoints',
})
export class GetWithTwoFloatingPointsPipe implements PipeTransform {
  transform(value: number): string {
    return value.toFixed(2);
  }
}
