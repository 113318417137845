import { MouseEvents, Plugins, VisibilityModes } from '../enums';
import { DrawingItem, VisibilityModeType } from '../types';

/* 
  Brush mode types
*/
export type BrushModeType = 'brush' | 'ellipse' | 'rectangle' | 'circle' | 'path' | 'arrow_left' | 'arrow_right' | 'arrow_upward' | 'arrow_downward';

/* 
  Brush modes list
*/
export enum BrushModes {
  Brush = 'brush',
  Circle = 'circle',
  Rectangle = 'rectangle',
  Path = 'path',
  Ellipse = 'ellipse',
  ArrowLeft = 'arrow_left',
  ArrowRight = 'arrow_right',
  ArrowUpward = 'arrow_upward',
  ArrowDownward = 'arrow_downward',
}

/* 
  Default brush options
*/
export const defaultBrushOptions = {
  brush: {
    enabled: false,
    shape: BrushModes.Brush,
    width: 1,
    color: '#96989F',
    tooltip: {
      htmlELement: null,
      drawingId: null,
    },
  },
};

/* 
  Base Brush type
*/
type BaseBrushType = DrawingItem & {
  shape: BrushModeType;
};

/* 
  Brush type
*/
type BrushType = BaseBrushType & {
  firstXPoint: number;
  firstYPoint: number;
  movingXPoint: number;
  movingYPoint: number;
  waypoints: { x: number; y: number }[];
  count: number;
};

/* 
  Circle type
*/
type CircleType = BaseBrushType & {
  centerX: number;
  centerY: number;
  radius: number;
  count: number;
};

/* 
  Rectangle type
*/
type RectangleType = BaseBrushType & {
  leftBottomX: number;
  leftBottomY: number;
  rightTopX: number;
  rightTopY: number;
  count: number;
};

/* 
  Ellipse type
*/
type EllipseType = BaseBrushType & {
  firstXPoint: number;
  firstYPoint: number;
  movingXPoint: number;
  movingYPoint: number;
  count: number;
};

/* 
  Path type
*/
type PathType = BaseBrushType & {
  firstXPoint: number;
  firstYPoint: number;
  movingXPoint: number;
  movingYPoint: number;
  count: number;
};

/* 
  Arrow type
*/
type ArrowType = BaseBrushType & {
  xPoint: number;
  yPoint: number;
};

/* 
  Brush constraints
*/
const brushInitialState = {
  backgroundColor: 'rgba(0, 150, 0, 0.2)',
  borderColor: 'rgba(0, 150, 0, 0.5)',
  borderWidth: 3,
};

/* 
  Arrow constraints
*/
const arrowInitialState = {
  backgroundColor: 'rgba(0, 150, 0, 0.2)',
  borderColor: 'rgba(0, 150, 0, 0.5)',
  borderWidth: 3,
  size: 20,
};

/* 
  Path constraints
*/
const pathInitialState = {
  backgroundColor: 'rgba(0, 150, 0, 0.2)',
  borderColor: 'rgba(0, 150, 0, 0.5)',
  borderWidth: 3,
};

/* 
  Rectangle shape constraints
*/
const rectangleInitialState = {
  backgroundColor: 'rgba(156, 39, 176, 0.2)',
  borderColor: 'rgba(156, 39, 176, 0.5)',
  borderWidth: 3,
};

/* 
  Circle shape constraints
*/
const circleInitialState = {
  backgroundColor: 'rgba(150, 0, 0, 0.2)',
  borderColor: 'rgba(150, 0, 0, 0.5)',
  borderWidth: 3,
};

/* 
  Ellipse shape constraints
*/
const ellipseInitialState = {
  backgroundColor: 'rgba(0, 255, 255, 0.2)',
  borderColor: 'rgba(0, 255, 255, 0.5)',
  borderWidth: 3,
};

/* 
  Focusing feature constraints
*/
const focusInitialState = {
  strokeStyle: 'blue',
  lineWidth: 1,
  fillStyle: 'white',
  radius: 6,
};

/* 
  Brush plugin
*/
export const brushPlugin: any = {
  id: Plugins.Brush,
  defaults: {
    ...defaultBrushOptions.brush,
  },
  afterEvent: (chart: { draw: () => void; config: { options: { plugins: any } } }, args: { event?: any }, opts: { width: any; color: any; shape: any }) => {
    let options = chart.config.options.plugins[Plugins.Brush];
    if (!options.enabled) return;

    const { type, x, y } = args.event;

    let shapeCreator = new ShapeCreator(chart, options).createShape(options.shape);
    shapeCreator.handlePoint(x, y, type);

    chart.draw();
  },
  afterDraw: (
    chart: {
      chartArea?: any;
      ctx?: any;
      update: () => void;
      destroy: () => void;
      config: { options: { plugins: any } };
    },
    args: any,
    opts: { width: any; color: any; shape: any }
  ) => {
    let options = chart.config.options.plugins[Plugins.Brush];

    if (options.history.length === 0) return;

    let latestPoint = options.history.slice(-1).pop()!;

    //draw history
    (options.history as BaseBrushType[]).forEach((historyPoint, index) => {
      let shapeCreator = new ShapeCreator(chart, options).createShape(historyPoint.shape);
      let isPointValid = shapeCreator.checkPointValidity(historyPoint, latestPoint);

      if (!historyPoint.isVisible || historyPoint.isDeleted || historyPoint.plugin != Plugins.Brush) return;

      if (isPointValid) {
        historyPoint.isFocused = true;
        shapeCreator.drawShape(historyPoint);
        shapeCreator.focusShape(historyPoint);
        shapeCreator.setTooltipVisibility(VisibilityModes.Visible);
      } else {
        historyPoint.isFocused = false;
        shapeCreator.drawShape(historyPoint);
        shapeCreator.setTooltipVisibility(VisibilityModes.Hidden);
      }
    });
  },
};

interface IShape {
  getLatestPoint<TType>(): TType;
  addPoint(): BaseBrushType;
  handlePoint(x: number, y: number, type: MouseEvents): void;
  drawShape(point: BaseBrushType): void;
  checkPointValidity(historyPoint: BaseBrushType, point: BaseBrushType): boolean;
  focusShape(point: BaseBrushType): void;
  setTooltipVisibility(visibility: VisibilityModeType): void;
}

abstract class BaseBrush implements IShape {
  chart: any;
  ctx: any;
  opts: any;

  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.ctx = chart.ctx;
    this.opts = opts;
  }

  getLatestPoint = <TType>(): TType => this.opts.history.slice(-1).pop();

  abstract addPoint(): BaseBrushType;

  abstract handlePoint(x: number, y: number, type: MouseEvents): void;

  abstract drawShape(point: BaseBrushType): void;

  abstract checkPointValidity(historyPoint: BaseBrushType, point: BaseBrushType): boolean;

  abstract focusShape(point: BaseBrushType): void;

  setTooltipVisibility(visibility: VisibilityModeType): void {
    this.opts.tooltip.htmlElement.nativeElement.style.visibility = visibility;
  }
}

class Brush extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = brushInitialState.backgroundColor;
    this.ctx.strokeStyle = brushInitialState.borderColor;
    this.ctx.lineWidth = brushInitialState.borderWidth;
  }

  addPoint = (): BrushType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.Brush,
      isVisible: true,
      isFocused: true,
      count: 0,
      waypoints: [],
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseBrushType>()?.shape != BrushModes.Brush || !this.getLatestPoint<BrushType>()) this.addPoint();

    let latestPoint = this.getLatestPoint<BrushType>();
    if (latestPoint.count === 0 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.firstXPoint = x;
      latestPoint.firstYPoint = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.MouseMove) {
      latestPoint.waypoints.push({ x, y });
    } else if (latestPoint.count === 1 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.movingXPoint = x;
      latestPoint.movingYPoint = y;
      this.addPoint();
    }
  }

  checkPointValidity(historyPoint: BrushType, point: BrushType): boolean {
    return true;
  }

  drawShape(point: BrushType): void {
    let { firstXPoint, firstYPoint, waypoints, movingXPoint, movingYPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(firstXPoint, firstYPoint);
    waypoints.forEach(val => {
      this.ctx.lineTo(val.x, val.y);
    });
    this.ctx.lineTo(movingXPoint, movingYPoint);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: BrushType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.firstXPoint, point.firstYPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.movingXPoint, point.movingYPoint);
    this.ctx.arc(point.movingXPoint, point.movingYPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class Circle extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = circleInitialState.backgroundColor;
    this.ctx.strokeStyle = circleInitialState.borderColor;
    this.ctx.lineWidth = circleInitialState.borderWidth;
  }

  addPoint = (): CircleType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.Circle,
      isVisible: true,
      isFocused: true,
      count: 0,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseBrushType>()?.shape != BrushModes.Circle || !this.getLatestPoint<CircleType>()) this.addPoint();

    let latestPoint = this.getLatestPoint<CircleType>();
    if (latestPoint.count === 0 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.centerX = x;
      latestPoint.centerY = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.MouseMove) {
      latestPoint.radius = Math.max(Math.abs(y - latestPoint.centerY), Math.abs(x - latestPoint.centerX));
    } else if (latestPoint.count === 1 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      this.addPoint();
    }
  }

  checkPointValidity(historyPoint: CircleType, point: CircleType): boolean {
    return historyPoint.centerX === point.centerX && historyPoint.centerY === point.centerY;
  }

  drawShape(circle: CircleType): void {
    let { radius, centerX, centerY } = circle;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: CircleType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.centerX, point.centerY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.centerX + point.radius, point.centerY);
    this.ctx.arc(point.centerX + point.radius, point.centerY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class Rectangle extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = rectangleInitialState.backgroundColor;
    this.ctx.strokeStyle = rectangleInitialState.borderColor;
    this.ctx.lineWidth = rectangleInitialState.borderWidth;
  }

  addPoint = (): RectangleType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.Rectangle,
      isVisible: true,
      isFocused: true,
      count: 0,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseBrushType>()?.shape != BrushModes.Rectangle || !this.getLatestPoint<RectangleType>()) this.addPoint();

    let latestPoint = this.getLatestPoint<RectangleType>();
    if (latestPoint.count === 0 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.leftBottomX = x;
      latestPoint.leftBottomY = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.MouseMove) {
      latestPoint.rightTopX = x;
      latestPoint.rightTopY = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      this.addPoint();
    }
  }

  checkPointValidity(historyPoint: RectangleType, point: RectangleType): boolean {
    let width = Math.abs(historyPoint.leftBottomX - historyPoint.rightTopX);
    let height = Math.abs(historyPoint.leftBottomY - historyPoint.rightTopY);
    let leftVertical = Array.from({ length: height }, (val, index) => ({
      x: historyPoint.leftBottomX,
      y: historyPoint.leftBottomY + index,
    }));
    let rightVertical = Array.from({ length: height }, (val, index) => ({
      x: historyPoint.rightTopX,
      y: historyPoint.rightTopY + index,
    }));
    let bottomHorizontal = Array.from({ length: width }, (val, index) => ({
      x: historyPoint.leftBottomX + index,
      y: historyPoint.leftBottomY,
    }));
    let topHorizontal = Array.from({ length: width }, (val, index) => ({
      x: historyPoint.rightTopX + index,
      y: historyPoint.rightTopY,
    }));
    let allPoints = leftVertical.concat(rightVertical, bottomHorizontal, topHorizontal);
    return allPoints.filter(val => val.x === point.leftBottomX && val.y === point.leftBottomY).length > 0;
  }

  drawShape(point: RectangleType): void {
    let { leftBottomX, leftBottomY, rightTopX, rightTopY } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.rect(leftBottomX, leftBottomY, rightTopX - leftBottomX, rightTopY - leftBottomY);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: RectangleType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.leftBottomX, point.leftBottomY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.rightTopX, point.leftBottomY);
    this.ctx.arc(point.rightTopX, point.leftBottomY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.rightTopX, point.rightTopY);
    this.ctx.arc(point.rightTopX, point.rightTopY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.leftBottomX, point.rightTopY);
    this.ctx.arc(point.leftBottomX, point.rightTopY, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class Ellipse extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = ellipseInitialState.backgroundColor;
    this.ctx.strokeStyle = ellipseInitialState.borderColor;
    this.ctx.lineWidth = ellipseInitialState.borderWidth;
  }

  addPoint = (): EllipseType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.Ellipse,
      isVisible: true,
      isFocused: false,
      count: 0,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseBrushType>()?.shape != BrushModes.Ellipse || !this.getLatestPoint<EllipseType>()) this.addPoint();

    let latestPoint = this.getLatestPoint<EllipseType>();
    if (latestPoint.count === 0 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.firstXPoint = x;
      latestPoint.firstYPoint = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.MouseMove) {
      latestPoint.movingXPoint = x;
      latestPoint.movingYPoint = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      this.addPoint();
    }
  }

  checkPointValidity(historyPoint: EllipseType, point: EllipseType): boolean {
    return false;
  }

  drawShape(point: EllipseType): void {
    let { firstXPoint, firstYPoint, movingXPoint, movingYPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.ellipse(firstXPoint, firstYPoint, Math.abs(movingXPoint - firstXPoint) / 2, Math.abs(movingYPoint - firstYPoint) / 2, Math.PI / 4, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: BrushType): void {}
}

class Path extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = pathInitialState.backgroundColor;
    this.ctx.strokeStyle = pathInitialState.borderColor;
    this.ctx.lineWidth = pathInitialState.borderWidth;
  }

  addPoint = (): PathType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.Path,
      isVisible: true,
      isFocused: false,
      count: 0,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (this.getLatestPoint<BaseBrushType>()?.shape != BrushModes.Path || !this.getLatestPoint<PathType>()) this.addPoint();

    let latestPoint = this.getLatestPoint<PathType>();
    if (latestPoint.count === 0 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      latestPoint.firstXPoint = x;
      latestPoint.firstYPoint = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.MouseMove) {
      latestPoint.movingXPoint = x;
      latestPoint.movingYPoint = y;
    } else if (latestPoint.count === 1 && type == MouseEvents.Click) {
      latestPoint.count += 1;
      this.addPoint();
    }
  }

  checkPointValidity(historyPoint: PathType, point: PathType): boolean {
    return true;
  }

  drawShape(point: PathType): void {
    let { firstXPoint, firstYPoint, count, movingXPoint, movingYPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(firstXPoint, firstYPoint);
    this.ctx.lineTo(movingXPoint, movingYPoint);

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: PathType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.firstXPoint, point.firstYPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(point.movingXPoint, point.movingYPoint);
    this.ctx.arc(point.movingXPoint, point.movingYPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowUpward extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = arrowInitialState.backgroundColor;
    this.ctx.strokeStyle = arrowInitialState.borderColor;
    this.ctx.lineWidth = arrowInitialState.borderWidth;
  }

  addPoint = (): ArrowType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.ArrowUpward,
      isVisible: true,
      isFocused: false,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (type == MouseEvents.Click) {
      this.addPoint();
      let point = this.getLatestPoint<ArrowType>();
      point.xPoint = x;
      point.yPoint = y;
    }
  }

  checkPointValidity(historyPoint: ArrowType, point: ArrowType): boolean {
    return true;
  }

  drawShape(point: ArrowType): void {
    let { xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size / 2, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size / 2, yPoint + arrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint + arrowInitialState.size * 2);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size / 2, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size / 2, yPoint + arrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint + arrowInitialState.size * 2);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.xPoint, point.yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowDownward extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = arrowInitialState.backgroundColor;
    this.ctx.strokeStyle = arrowInitialState.borderColor;
    this.ctx.lineWidth = arrowInitialState.borderWidth;
  }

  addPoint = (): ArrowType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.ArrowDownward,
      isVisible: true,
      isFocused: false,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (type == MouseEvents.Click) {
      this.addPoint();
      let point = this.getLatestPoint<ArrowType>();
      point.xPoint = x;
      point.yPoint = y;
    }
  }

  checkPointValidity(historyPoint: ArrowType, point: ArrowType): boolean {
    return true;
  }

  drawShape(point: ArrowType): void {
    let { xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size / 2, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size / 2, yPoint - arrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint - arrowInitialState.size * 2);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size / 2, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size / 2, yPoint - arrowInitialState.size * 2);
    this.ctx.lineTo(xPoint, yPoint - arrowInitialState.size * 2);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.xPoint, point.yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowLeft extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = arrowInitialState.backgroundColor;
    this.ctx.strokeStyle = arrowInitialState.borderColor;
    this.ctx.lineWidth = arrowInitialState.borderWidth;
  }

  addPoint = (): ArrowType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.ArrowLeft,
      isVisible: true,
      isFocused: false,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (type == MouseEvents.Click) {
      this.addPoint();
      let point = this.getLatestPoint<ArrowType>();
      point.xPoint = x;
      point.yPoint = y;
    }
  }

  checkPointValidity(historyPoint: ArrowType, point: ArrowType): boolean {
    return true;
  }

  drawShape(point: ArrowType): void {
    let { xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint + arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + arrowInitialState.size * 2, yPoint + arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + arrowInitialState.size * 2, yPoint);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint + arrowInitialState.size, yPoint - arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + arrowInitialState.size * 2, yPoint - arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint + arrowInitialState.size * 2, yPoint);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.xPoint, point.yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ArrowRight extends BaseBrush {
  constructor(chart: any, opts: any) {
    super(chart, opts);
    this.ctx.fillStyle = arrowInitialState.backgroundColor;
    this.ctx.strokeStyle = arrowInitialState.borderColor;
    this.ctx.lineWidth = arrowInitialState.borderWidth;
  }

  addPoint = (): ArrowType =>
    this.opts.history.push({
      id: this.opts.history.length + 1,
      plugin: Plugins.Brush,
      shape: BrushModes.ArrowRight,
      isVisible: true,
      isFocused: false,
    });

  handlePoint(x: number, y: number, type: MouseEvents): void {
    if (type == MouseEvents.Click) {
      this.addPoint();
      let point = this.getLatestPoint<ArrowType>();
      point.xPoint = x;
      point.yPoint = y;
    }
  }

  checkPointValidity(historyPoint: ArrowType, point: ArrowType): boolean {
    return true;
  }

  drawShape(point: ArrowType): void {
    let { xPoint, yPoint } = point;

    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint + arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint + arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - arrowInitialState.size * 2, yPoint + arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - arrowInitialState.size * 2, yPoint);
    this.ctx.moveTo(xPoint, yPoint);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint - arrowInitialState.size);
    this.ctx.lineTo(xPoint - arrowInitialState.size, yPoint - arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - arrowInitialState.size * 2, yPoint - arrowInitialState.size / 2);
    this.ctx.lineTo(xPoint - arrowInitialState.size * 2, yPoint);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }

  focusShape(point: ArrowType): void {
    this.ctx.save();
    this.ctx.beginPath();

    this.ctx.fillStyle = focusInitialState.fillStyle;
    this.ctx.strokeStyle = focusInitialState.strokeStyle;
    this.ctx.lineWidth = focusInitialState.lineWidth;

    this.ctx.arc(point.xPoint, point.yPoint, focusInitialState.radius, 0, 2 * Math.PI);
    this.ctx.fill();

    this.ctx.stroke();
    this.ctx.restore();
  }
}

class ShapeCreator {
  chart: any;
  opts!: any;
  constructor(chart: any, opts: any) {
    this.chart = chart;
    this.opts = opts;
  }

  createShape(shape: BrushModeType): IShape {
    if (shape === BrushModes.Brush) {
      return new Brush(this.chart, this.opts);
    }
    if (shape === BrushModes.Rectangle) {
      return new Rectangle(this.chart, this.opts);
    } else if (shape === BrushModes.Circle) {
      return new Circle(this.chart, this.opts);
    } else if (shape === BrushModes.Ellipse) {
      return new Ellipse(this.chart, this.opts);
    } else if (shape === BrushModes.Path) {
      return new Path(this.chart, this.opts);
    } else if (shape === BrushModes.ArrowUpward) {
      return new ArrowUpward(this.chart, this.opts);
    } else if (shape === BrushModes.ArrowDownward) {
      return new ArrowDownward(this.chart, this.opts);
    } else if (shape === BrushModes.ArrowLeft) {
      return new ArrowLeft(this.chart, this.opts);
    } else if (shape === BrushModes.ArrowRight) {
      return new ArrowRight(this.chart, this.opts);
    } else {
      return new Brush(this.chart, this.opts);
    }
  }
}
