<div>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>{{ simulationRequest?.simulationTitle }}</h1>
    <mat-icon mat-dialog-close style="cursor: pointer; font-size: 30px">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.SIMULATION_ID' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.simulationId }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.SIMULATION_NAME' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.simulationTitle }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.SIMULATION_RESULT_UUID' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.simulationResultUUId }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.REQUESTING_USER' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6">
        {{ simulationRequest?.user?.firstName }}
        {{ simulationRequest?.user?.lastName }}</mat-card
      >
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.REQUESTING_CLIENT_IP' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.requestingClientIP }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.HANDLING_HOST_ID' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.handlingHostId }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.REQUEST_TIME' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.requestTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.HANDLING_START_TIME' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.handlingStartTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.HANDLING_END_TIME' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.handlingEndTime | convertDate }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.STATUS' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.status }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.COMPLETION_PERCENTAGE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.completionPercentage }}</mat-card>
    </div>
    <div class="d-flex flex-row row">
      <mat-card class="my-1 col-12 col-md-6"
        ><strong class="font-weight-bold">{{ 'SIMULATION_REQUESTS_PAGE.REQUESTING_CLIENT_LICENSE' | translate }}</strong></mat-card
      >
      <mat-card class="my-1 col-12 col-md-6"> {{ simulationRequest?.requestingClientLicense }}</mat-card>
    </div>
  </div>
</div>
