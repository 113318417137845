import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { VisibilityModes } from '../scope-tab/chart/enums';
import { textInitialState } from '../scope-tab/chart/plugins/textPlugin';
import { DrawingItem, PluginType } from '../scope-tab/chart/types';

@Component({
  selector: 'app-scope-sub-tooltip',
  templateUrl: './scope-sub-tooltip.component.html',
  styleUrls: ['./scope-sub-tooltip.component.css'],
})
export class ScopeSubTooltipComponent implements OnInit {
  @ViewChild('item')
  item!: ElementRef;

  @Input()
  drawingItems!: DrawingItem[];

  @Input()
  chartOptions!: any;

  @Input()
  currentPlugin!: PluginType;

  subTooltipForm!: FormGroup;

  focusedItem!: any;

  constructor(private readonly _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.subTooltipForm = this._formBuilder.group({
      text: new FormControl(textInitialState.text, []),
      color: new FormControl(textInitialState.color, []),
      pixel: new FormControl(textInitialState.pixel, []),
      isBold: new FormControl(false, []),
      isItalic: new FormControl(false, []),
    });
  }

  saveSubTooltip() {
    this.focusedItem.text = this.subTooltipForm.get('text')?.value;
    this.focusedItem.color = this.subTooltipForm.get('color')?.value;
    this.focusedItem.pixel = this.subTooltipForm.get('pixel')?.value;
    this.makeSubTooltipInvisible();
  }

  subTooltipMakeBold() {
    this.subTooltipForm.controls['isBold'].setValue(!this.subTooltipForm.get('isBold')?.value);
    this.focusedItem.isBold = this.subTooltipForm.get('isBold')?.value;
  }

  subTooltipMakeItalic() {
    this.subTooltipForm.controls['isItalic'].setValue(!this.subTooltipForm.get('isItalic')?.value);
    this.focusedItem.isItalic = this.subTooltipForm.get('isItalic')?.value;
  }

  makeSubTooltipInvisible() {
    this.item.nativeElement.style.visibility = VisibilityModes.Hidden;
  }

  makeSubTooltipVisible() {
    this.focusedItem = this.drawingItems.find(item => item.id === this.chartOptions.plugins.text.tooltip.drawingId) as any;
    this.subTooltipForm.patchValue({
      text: this.focusedItem.text,
      color: this.focusedItem.color,
      pixel: this.focusedItem.pixel,
      isBold: this.focusedItem.isBold,
      isItalic: this.focusedItem.isItalic,
    });
    this.item.nativeElement.style.visibility = VisibilityModes.Visible;
  }
}
