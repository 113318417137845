import { CompanyState } from 'src/app/models/state/appState';
import { CompanyAction, CompanyActionTypes } from '../actions/company.action';

const initialCompanyState: CompanyState = {
  isCompaniesLoaded: false,
};

export function CompanyReducer(state: CompanyState = initialCompanyState, action: CompanyAction | any): any {
  switch (action.type) {
    case CompanyActionTypes.ADD_COMPANY:
      return {
        ...state,
        companies: action.payload,
        companyError: null,
      };
    case CompanyActionTypes.ADD_COMPANY_ERROR:
      return {
        ...state,
        companies: null,
        companyError: action.error,
      };
    case CompanyActionTypes.LOAD_COMPANY:
      return {
        ...state,
        isCompaniesLoaded: true,
        companyError: null,
      };
    default:
      return state;
  }
}
