import { Action } from '@ngrx/store';
import { GetLocationDto } from 'src/app/dtos/location/getLocationDto';

export enum LocationActionTypes {
  ADD_LOCATION = '[Location] Add Location',
  ADD_LOCATION_ERROR = '[Location] Add Location Error',
  LOAD_LOCATION = '[Location] Load Location',
}

export class AddLocationAction implements Action {
  readonly type = LocationActionTypes.ADD_LOCATION;

  constructor(
    public payload: GetLocationDto[],
    public error: null
  ) {}
}

export class AddLocationActionError implements Action {
  readonly type = LocationActionTypes.ADD_LOCATION_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadLocationDatas implements Action {
  readonly type = LocationActionTypes.LOAD_LOCATION;

  constructor() {}
}

export type LocationAction = LoadLocationDatas | AddLocationAction | AddLocationActionError;
