import { Action } from '@ngrx/store';
import { GetSimulationReportTemplateDto } from 'src/app/dtos/report/getSimulationReportTemplateDto';
import { HttpCommonHeaders } from 'src/app/models/state/appState';

export enum SimulationReportTemplateActionTypes {
  ADD_SIMULATION_REPORT_TEMPLATE = '[SimulationReportTemplate] Add SimulationReportTemplate',
  ADD_SIMULATION_REPORT_TEMPLATE_ERROR = '[SimulationReportTemplate] Add SimulationReportTemplate Error',
  LOAD_SIMULATION_REPORT_TEMPLATE = '[SimulationReportTemplate] Load SimulationReportTemplate',
  CLEAR_SIMULATION_REPORT_TEMPLATE = '[SimulationReportTemplate] Clear SimulationReportTemplate',
}

export class AddSimulationReportTemplateAction implements Action {
  readonly type = SimulationReportTemplateActionTypes.ADD_SIMULATION_REPORT_TEMPLATE;

  constructor(
    public payload: GetSimulationReportTemplateDto[],
    public headers: HttpCommonHeaders,
    public pageSize: number = 0,
    public pageIndex: number = 0,
    public error: null
  ) {}
}

export class AddSimulationReportTemplateActionError implements Action {
  readonly type = SimulationReportTemplateActionTypes.ADD_SIMULATION_REPORT_TEMPLATE_ERROR;

  constructor(
    public payload: null,
    public error: string
  ) {}
}

export class LoadSimulationReportTemplateDatas implements Action {
  readonly type = SimulationReportTemplateActionTypes.LOAD_SIMULATION_REPORT_TEMPLATE;

  constructor(
    public pageSize: number = 25,
    public pageIndex: number = 0
  ) {}
}

export class ClearSimulationReportTemplateDatas implements Action {
  readonly type = SimulationReportTemplateActionTypes.CLEAR_SIMULATION_REPORT_TEMPLATE;

  constructor() {}
}

export type SimulationReportTemplateAction = LoadSimulationReportTemplateDatas | AddSimulationReportTemplateAction | AddSimulationReportTemplateActionError | ClearSimulationReportTemplateDatas;
