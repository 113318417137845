<div>
  <h1 mat-dialog-title>{{ confirmationTitle | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ confirmationContent | translate }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'NO' | translate }}</button>
    <button mat-button (click)="onYesClick()" cdkFocusInitial>
      {{ 'YES' | translate }}
    </button>
  </div>
</div>
